import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Spin, Col, Tag, Popconfirm, message, Checkbox, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form, Select, DatePicker } from 'formik-antd';
import { FaSearch, FaPaperclip, FaUndo, FaTimes, FaPencilAlt } from 'react-icons/fa';
import { parse, format } from 'date-fns';
import { formatPrice } from 'Utils';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DefaultLayout from '~/pages/_layouts/full';
import FileList from '~/pages/FileList';
import Box from '~/components/Box';
import Row from '~/components/Row';
import PageTitle from '~/components/PageTitle';
import { Table, TableActions, TableHeader } from '~/components/Table';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import EntriesEdit from './modal'
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import { TotalValue } from './styles';

export default function Supervisor() {
  const { t } = useTranslation();
  const { Option } = Select;
  const { profile } = useSelector(state => state.user);
  const token = useSelector(state => state.auth.token);
  const cnpj = useSelector(state => state.auth.cnpj);

  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [cnpjCards, setCnpjCards] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [showFileList, setShowFileList] = useState(false);
  const [receipts, setReceipts] = useState([]);
  // eslint-disable-next-line
  const [fields, setFields] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [userActiveCheck, setUserActiveCheck] = useState(true);
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [costCenterId, setCostCenterId] = useState(0);
  const [taskIds, setTaskIds] = useState([]);
  const [expenseStatus, setExpenseStatus] = useState('PENDING');
  const [collaboratorIds, setCollaboratorIds] = useState([]);
  const [filters, setFilters] = useState([]);
  const [meta, setMeta] = useState({});
  const [selectedRecords, setSelectedRecords] = useState(new Map());
  const [selectedRecordsSize, setSelectedRecordsSize] = useState(0);
  const [selectedAll, setSelectedAll] = useState(false);
  const [totalValue, setTotalValue] = useState(0);

  const clear = async () => {
    setLoading(true);
    setTotalValue(0);

    const defaultFilters = [
      {field: 'expenseStatus', value: 'PENDING', restriction: 'EQUAL'},
      {columnJoin: 'cnpjCard', field: 'registrationNumber', value: cnpj, restriction: 'EQUAL'}
    ];

    const defaultTotalFilters = [
      {field: 'expenseStatus', value: 'PENDING', restriction: 'EQUAL'},
      {field: 'cnpj', value: cnpj, restriction: 'EQUAL'}
    ];

    try {
      const expensePaginationDTO = { page: 0, sortBy: "date", filters: defaultFilters };
      const { data } = await api.post('/expenses/paginationForSupervisor', expensePaginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
      };

      setFilters(defaultFilters);
      setSelectedRecords(new Map());
      setSelectedRecordsSize(0);
      setSelectedAll(false);
      setMeta(meta);
      setDataTable(data.content);
    } catch (error) {
      errorHandler(error);
    }
    fetchTotalCost(defaultTotalFilters);
    setLoading(false);
  }

  const fetchRecords = async (filters) => {
    setLoading(true);
    try {
      const expensePaginationDTO = { page: 0, sortBy: "date", filters: filters };
      const { data } = await api.post('/expenses/paginationForSupervisor', expensePaginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
      };

      let content = [];
      content = data.content;
      // eslint-disable-next-line
      content.map((record) => {
        if (selectedRecords.has(record.id) === true || selectedAll === true) {
          record.checked = true;
        }
      });

      setMeta(meta);
      setDataTable(content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  }

  const fetchTotalCost = async (filters) => {
    try {
      const { data } = await api.post('/expenses/paginationForSupervisorTotal', filters);
      setTotalValue(data);
    } catch (error) {
      errorHandler(error);
    }
  }

  const fetchCnpjCards = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/cnpjCards/findAll');
      data.splice(0, 0, { id: 0, registrationNumber: '00.000.000/0000-00', businessName: 'Todos' });
      setCnpjCards(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchCostCenters = async (cnpj) => {
    setLoading(true);
    try {
      const { data } = await api.get('/costCenters/findAllForSupervisor', {
        params: { cnpj }
      });
      data.splice(0, 0, { id: 0, code: '00.0000', clientName: 'Todos' });
      setCostCenters(data);
      setCostCenterId(0);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/tasks/findAll');
      data.splice(0, 0, { id: 0, name: 'Todos' });
      setTasks(data);
      setTaskIds([0]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchCollaborator = async (cnpj, costCenterValue, taskValue, expenseStatusValue, userActiveValue) => {
    let costCenterId = costCenterValue;
    let taskIds = taskValue !== null && taskValue.length > 0 ? taskValue : null;
    const expenseStatus = expenseStatusValue && typeof expenseStatusValue === 'string' ? expenseStatusValue : 'ALL';
    const userActive = userActiveValue !== null ? userActiveValue : userActiveCheck;
    setLoading(true);

    const filters = [
      { field: 'cnpj', value: cnpj, restriction: 'EQUAL' },
      { field: 'costCenterId', value: costCenterId, restriction: 'EQUAL' },
      { field: 'expenseStatus', value: expenseStatus, restriction: 'EQUAL' },
      { field: 'userActive', value: userActive, restriction: 'EQUAL' },
      { field: 'taskIds', value: taskIds, restriction: 'IN' }
    ];

    try {
      const { data } = await api.post('/users/findAllForSupervisor', filters);
      data.splice(0, 0, { id: 0, name: 'Todos' });
      setCollaborators(data);
      setCollaboratorIds([0]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeCnpj = (cnpjValue) => {
    setRegistrationNumber(cnpjValue);
    fetchCostCenters(cnpjValue);
    setExpenseStatus('PENDING');
    fetchCollaborator(cnpjValue, costCenterId, taskIds, expenseStatus, userActiveCheck);
  };

  const handleChangeCostCenter = (costCenterValue) => {
    setCostCenterId(costCenterValue);
    fetchCollaborator(registrationNumber, costCenterValue, taskIds, expenseStatus, userActiveCheck);
  };

  const handleChangeTask = (value) => {
    let taskValues = null;
    if (value.length === 0 || (value.length > 0 && value[value.length - 1] === 0)) {
      taskValues = [0];
    } else if (value.length === 2 && value[1] !== 0 && value[0] === 0) {
      taskValues = [value[1]];
    } else {
      taskValues = value;
    }
    setTaskIds(taskValues);
    fetchCollaborator(registrationNumber, costCenterId, taskValues, expenseStatus, userActiveCheck);
  };

  const handleChangeCollaborator = (value) => {
    let collaboratorValues = null;
    if (value.length === 0 || (value.length > 0 && value[value.length - 1] === 0)) {
      collaboratorValues = [0];
    } else if (value.length === 2 && value[1] !== 0 && value[0] === 0) {
      collaboratorValues = [value[1]];
    } else {
      collaboratorValues = value;
    }
    setCollaboratorIds(collaboratorValues);
  };

  const handleChangeStatus = (expenseStatusValue) => {
    setExpenseStatus(expenseStatusValue);
    fetchCollaborator(registrationNumber, costCenterId, taskIds, expenseStatusValue, userActiveCheck);
  };

  const handleChangePage = async page => {
    setLoading(true);
    try {
      const expensePaginationDTO = { page: page - 1, sortBy: "date", filters: filters };
      const { data } = await api.post('/expenses/paginationForSupervisor', expensePaginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber + 1,
        pageSize: data.pageable.pageSize,
      };

      let content = [];
      content = data.content;
      // eslint-disable-next-line
      content.map((record) => {
        if (selectedRecords.has(record.id) === true || selectedAll === true) {
          record.checked = true;
        }
      });

      setMeta(meta);
      setDataTable(content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const aproveAll = async () => {
    selectedRecords.forEach(record => {
      if (record.expenseStatus === 'PENDING') {
        handleStatus(record, 'APPROVED');
      }
    });

    setDataTable([]);
    fetchRecords(filters);
  };

  const handleSearch = async values => {
    setLoading(true);
    const filter = [];
    const filterTotalCost = [];

    if (values.cnpjCard !== undefined && values.cnpjCard !== null &&
      values.cnpjCard !== '' && values.cnpjCard !== '00.000.000/0000-00') {
      filter.push({ columnJoin: 'cnpjCard', field: 'registrationNumber', value: registrationNumber, restriction: 'EQUAL' });
      filterTotalCost.push({ field: 'cnpj', value: registrationNumber, restriction: 'EQUAL' });
    }

    if (values.costCenter !== undefined && values.costCenter !== null &&
      values.costCenter !== 'Todos' && values.costCenter !== 0) {
      filter.push({ columnJoin: 'costCenter', field: 'id', value: values.costCenter, restriction: 'EQUAL' });
      filterTotalCost.push({ field: 'costCenterId', value: values.costCenter, restriction: 'EQUAL' });
    }

    if (values.taskIds !== undefined && values.taskIds !== null &&
      values.taskIds.length > 0 && values.taskIds[0] !== 0) {
      filter.push({ columnJoin: 'item', columnSecondJoin: 'budgetaryNature', columnThirdJoin: 'task', field: 'id', value: values.taskIds, restriction: 'IN' });
      filterTotalCost.push({ field: 'taskIds', value: values.taskIds, restriction: 'IN' });
    }

    if (values.collaborators !== undefined && values.collaborators !== null &&
      values.collaborators.length > 0 && values.collaborators[0] !== 0) {
      filter.push({columnJoin: 'user', field: 'id', value: values.collaborators, restriction: 'IN'});
      filterTotalCost.push({ field: 'userIds', value: values.collaborators, restriction: 'IN' });
    }

    if (values.init !== undefined && values.init !== null) {
      const start = format(new Date(values.init), 'dd-MM-yyyy');
      filter.push({field: 'date', value: start, restriction: 'GREATER_THAN_OR_EQUAL_TO' });
      filterTotalCost.push({field: 'dateInit', value: start, restriction: 'GREATER_THAN_OR_EQUAL_TO' });
    }

    if (values.end !== undefined && values.end !== null) {
      const final = format(new Date(values.end), 'dd-MM-yyyy');
      filter.push({field: 'date', value: final, restriction: 'LESS_THAN_OR_EQUAL_TO'});
      filterTotalCost.push({field: 'dateEnd', value: final, restriction: 'LESS_THAN_OR_EQUAL_TO'});
    }

    if (values.status !== undefined && values.status !== 'Todos' && values.status !== 'ALL') {
      filter.push({ field: 'expenseStatus', value: values.status, restriction: 'EQUAL'});
      filterTotalCost.push({ field: 'expenseStatus', value: values.status, restriction: 'EQUAL'});
    }

    //userActive
    if (userActiveCheck !== null && userActiveCheck === true) {
      filter.push({ field: 'userStatus', value: 'ACTIVE', restriction: 'EQUAL'});
      filterTotalCost.push({ field: 'userActive', value: userActiveCheck, restriction: 'EQUAL'});
    }

    try {
      setTotalValue(0);
      setFilters(filter);
      fetchRecords(filter);
      fetchTotalCost(filterTotalCost);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleStatus = async (record, action) => {
    record.approvalSupervisor = profile;
    delete record.checked;

    switch (action) {
      case 'APPROVED':
        try {
          record.expenseStatus = record.expenseStatus === 'APPROVED' ? 'APPROVED' : 'APPROVED_RESTRICTION';
          record.approvalDate = format(new Date(), 'dd-MM-yyyy HH:mm:ss');

          await api.put('/expenses/update', record);
          await fetchRecords(filters);
          message.success(
            t('messages:supervisorActions.approved1') + record.id + t('messages:supervisorActions.approved2')
          );
        } catch (error) {
          message.error(t('messages:supervisorActions.approvedError') + record.id);
        }
        break;
      case 'DISAPPROVED':
        try {
          record.expenseStatus = 'DISAPPROVED';
          record.approvalDate = null;
          await api.put('/expenses/update', record);
          await fetchRecords(filters);
          message.success(t('messages:supervisorActions.disapproved'));
        } catch (error) {
          errorHandler(error);
        }
        break;
      case 'UNDO':
        if (record.expenseStatus !== 'PAID') {
          record.expenseStatus = 'PENDING';
          record.approvalDate = null;
          try {
            await api.put('/expenses/update', record);
            await fetchRecords(filters);
          } catch (error) {
            errorHandler(error);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleFeedType = (record) => {

    const feedType = [
      { type: 'BREAKFAST', name: 'Café da manhã' },
      { type: 'LUNCH', name: 'Almoço' },
      { type: 'DINNER', name: 'Janta' },
    ];

    const feed = feedType.filter(item => item.type === record.feedType)

    return feed[0].name
  }

  const handleExtraCollums = record => {

    switch (record.expenseType) {
      case 'MILEAGE_REIMBURSEMENT':
        return (
          <>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <p>KM Inicial: {record.initialKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <p>KM Final: {record.finalKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <p>Placa do veículo: {record.licensePlate}</p>
              </Col>
            </Row>
            {record.observation ? (
              <Row>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.notes')}: {record.observation}
                  </p>
                </Col>
              </Row>
            ) : null}
            {record.supervisorObservation ? (
              <Row>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>
            ) : null}

          </>
        );
      case 'FEED':
        return (
          <>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <p>Número de pessoas: {record.personQuantity === 0 ? '1' : record.personQuantity}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <p>Nome das pessoas: {record.personNames ? record.personNames : record.user.name}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <p>Tipo da refeição: {handleFeedType(record)}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Quantidade de refeições: {record.mealsQuantity} </p>
              </Col>
            </Row>
            {record.observation ? (
              <Row>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.notes')}: {record.observation}
                  </p>
                </Col>
              </Row>
            ) : null}
            {record.supervisorObservation ? (
              <Row>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>

            ) : null}

          </>
        );
      case 'FUEL':
        return (
          <>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <p>KM Final: {record.finalKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <p>Placa do veículo: {record.licensePlate}</p>
              </Col>
            </Row>
            {record.observation ? (

              <Row>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.notes')}: {record.observation}
                  </p>
                </Col>
              </Row>


            ) : null}
            {record.supervisorObservation ? (
              <Row>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>

            ) : null}
          </>
        );
      case 'GENERIC':
        return (
          <>
            {record.observation ? (
              <Row>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.notes')}: {record.observation}
                  </p>
                </Col>
              </Row>
            ) : null}
            {record.supervisorObservation ? (
              <Row>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>
            ) : null}
          </>
        );
      default:
        break;
    }
  };

  const handleSelect = async (record, e) => {
    const currentRecordId = record.id;
    const checked = e.target.checked;
    record.checked = checked;

    if (checked === true) {
      if (selectedRecords.has(currentRecordId) === false) {
        selectedRecords.set(currentRecordId, record);
      }

      if (selectedRecords.size === meta.totalElements) {
        setSelectedAll(true);
      }
    } else {
      if (selectedRecords.has(currentRecordId) === true) {
        selectedRecords.delete(currentRecordId);
      }
      setSelectedAll(false);
    }
    setSelectedRecordsSize(selectedRecords.size);
  };

  const handleSelectAll = async (e) => {
    let checked = e.target.checked;
    let selectedList = new Map();

    if (checked) {
      try {
        const { data } = await api.post('/expenses/findAllForSupervisor', filters);
        // eslint-disable-next-line
        data.map(record => {
          record.checked = true;
          selectedList.set(record.id, record);
        });
      } catch (error) {
        errorHandler(error);
      }

      setSelectedAll(true);
      setSelectedRecordsSize(meta.totalElements);
      // eslint-disable-next-line
      dataTable.map(record => {
        record.checked = checked;
      });
    } else {
      setSelectedAll(false);
      setSelectedRecordsSize(0);
      // eslint-disable-next-line
      dataTable.map(record => {
        record.checked = checked;
      });
    }
    setSelectedRecords(selectedList);

    const checkboxLength = document.getElementsByClassName('checkBoxTable').length;
    for (let i = 0; i < checkboxLength; i++) {
      document.getElementsByClassName('checkBoxTable')[i].checked = checked;
    }
  };

  const handleDownloadAll = async () => {
    setLoading(true);
    var zip = new JSZip();
    var photoZip = zip.folder('comprovantes');
    var numberOfReceipts = 0;
    var contador = 0;
    var count = 0;
    var countFileError = 0;

    let selectedEntries = [];
    selectedRecords.forEach((value) => {
      selectedEntries.push(value);
    });
    // eslint-disable-next-line
    selectedEntries.map(entire => {
      numberOfReceipts = numberOfReceipts + entire.expenseReceipts.length;
    });

    selectedEntries.map(async entire => {
      await entire.expenseReceipts.map(async receipt => {
        await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${receipt.name}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        }).then(response => {
          count++;
          photoZip.folder('comprovantes').file('[' + entire.id + ']' + receipt.originalName, response.data);
          contador++;
          if (numberOfReceipts === contador) {
            photoZip.generateAsync({ type: 'blob' }).then(function (blob) {
              saveAs(blob, 'comprovantes.zip');
            });
            setLoading(false);
          }
        }).catch(error => {
          count++;
          if (error.response && error.response.status === 404) {
            countFileError++;
            if (numberOfReceipts === count) {
              let msg = countFileError > 1 ? t('messages:downloadReceiptsError') + " " + countFileError.toString() + " " + t('messages:receiptsNotFoundError')
              : t('messages:downloadReceiptError') + " " + countFileError.toString() + " " + t('messages:receiptNotFoundError');
              message.error(msg);
              setLoading(false);
            }
          }
        });
      });
    });
  };

  const handleEdit = (id) => {
    setSelectedRecord(id);
    setShowForm(true);
  }

  const handleDownload = async () => {
    setLoading(true);
    let selectedEntries = [];
    selectedRecords.forEach((value) => {
      let record = value;
      delete record.checked;
      selectedEntries.push(record);
    });

    try {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/expenses/approvals`,
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, },
        responseType: 'blob',
        data: selectedEntries,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.rel = 'noreferrer noopener';
        link.setAttribute('download', 'aprovacoes.pdf');
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setSelectedRecords(new Map());
    setSelectedRecordsSize(0);
    setSelectedAll(false);
    setRegistrationNumber(cnpj);
    fetchCnpjCards();
    fetchCostCenters(cnpj);
    fetchTasks();
    setExpenseStatus('PENDING');
    fetchCollaborator(cnpj, costCenterId, taskIds, expenseStatus, userActiveCheck);

    const filterList = [
      {field: 'expenseStatus', value: 'PENDING', restriction: 'EQUAL'},
      {columnJoin: 'cnpjCard', field: 'registrationNumber', value: cnpj, restriction: 'EQUAL' }
    ];
    const defaultTotalFilters = [
      {field: 'expenseStatus', value: 'PENDING', restriction: 'EQUAL'},
      {field: 'cnpj', value: cnpj, restriction: 'EQUAL'}
    ];
    setFilters(filterList);
    fetchRecords(filterList);
    fetchTotalCost(defaultTotalFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpj]);

  function getStatus(record) {
    switch (record.expenseStatus) {
      case 'PENDING':
        return (
          <Tag style={{ margin: 0 }} color="orange">
            <Tooltip placement="topLeft"
            title={
              <>
                <span><b>{'Supervisor: '}</b>{record.approvalSupervisor !== null ? record.approvalSupervisor.name : '-'}</span><br/>
                <span><b>{'Financeiro: '}</b>{record.approvalFinancial !== null ? record.approvalFinancial.name : '-'}</span>
              </>
            }>
              {t('screens:status.pending')}
            </Tooltip>
          </Tag>
        );
      case 'APPROVED':
        return (
          <Tag style={{ margin: 0 }} color="green">
            <Tooltip placement="topLeft" 
            title={
              <>
                <span><b>{'Supervisor: '}</b>{record.approvalSupervisor !== null ? record.approvalSupervisor.name : '-'}</span><br/>
                <span><b>{'Financeiro: '}</b>{record.approvalFinancial !== null ? record.approvalFinancial.name : '-'}</span>
              </>
            }>
              {t('screens:status.approved')}
            </Tooltip>
          </Tag>
        );
      case 'APPROVED_RESTRICTION':
        return (
          <Tag style={{ margin: 0 }} color="yellow">
            <Tooltip placement="topLeft"
            title={
              <>
                <span><b>{'Supervisor: '}</b>{record.approvalSupervisor !== null ? record.approvalSupervisor.name : '-'}</span><br/>
                <span><b>{'Financeiro: '}</b>{record.approvalFinancial !== null ? record.approvalFinancial.name : '-'}</span>
              </>
            }>
              {t('screens:status.approvedrestriction')}
            </Tooltip>
          </Tag>
        )
      case 'DISAPPROVED':
        return (
          <Tag style={{ margin: 0 }} color="red">
            <Tooltip placement="topLeft"
            title={
              <>
                <span><b>{'Supervisor: '}</b>{record.approvalSupervisor !== null ? record.approvalSupervisor.name : '-'}</span><br/>
                <span><b>{'Financeiro: '}</b>{record.approvalFinancial !== null ? record.approvalFinancial.name : '-'}</span>
              </>
            }>
              {t('screens:status.disapproved')}
            </Tooltip>
          </Tag>
        );
      case 'PAID':
        return (
          <Tag style={{ margin: 0 }} color="blue">
            <Tooltip placement="topLeft"
            title={
              <>
                <span><b>{'Supervisor: '}</b>{record.approvalSupervisor !== null ? record.approvalSupervisor.name : '-'}</span><br/>
                <span><b>{'Financeiro: '}</b>{record.approvalFinancial !== null ? record.approvalFinancial.name : '-'}</span>
              </>
            }>
              {t('screens:status.paid')}
            </Tooltip>
          </Tag>
        );
      default:
        return;
    }
  }

  const status = [
    {
      id: 0,
      code: 'ALL',
      description: t('screens:status.all'),
    },
    {
      id: 1,
      code: 'PENDING',
      description: t('screens:status.pending'),
    },
    {
      id: 2,
      code: 'APPROVED',
      description: t('screens:status.approved'),
    },
    {
      id: 3,
      code: 'PAID',
      description: t('screens:status.paid'),
    },
    {
      id: 4,
      code: 'DISAPPROVED',
      description: t('screens:status.disapproved'),
    }, {
      id: 5,
      code: 'APPROVED_RESTRICTION',
      description: t('screens:status.approvedrestriction')
    }
  ];

  const tableColumns = [
    {
      title: t('screens:entries.data.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('screens:entries.data.date'),
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) =>
        moment(format(parse(a.date, 'dd-MM-yyyy HH:mm:ss', new Date()), 'yyyy-MM-dd HH:mm:ss')).unix() -
        moment(format(parse(b.date, 'dd-MM-yyyy HH:mm:ss', new Date()), 'yyyy-MM-dd HH:mm:ss')).unix(),
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <div style={{ width: '71px' }}>
          {format(parse(record.date, 'dd-MM-yyyy HH:mm:ss', new Date()), 'dd-MM-yyyy')}
        </div>
      ),
    },
    {
      title: t('screens:entries.data.cost-center'),
      dataIndex: 'costCenter.clientName',
      key: 'costCenter.clientName',
    },
    {
      title: t('screens:entries.data.colaborator'),
      dataIndex: 'user.name',
      key: 'user.name',
    },
    {
      title: t('screens:entries.data.task'),
      dataIndex: 'item.budgetaryNature.task.name',
      key: 'item.budgetaryNature.task.name',
    },
    {
      title: t('screens:entries.data.item'),
      dataIndex: 'item.name',
      key: 'item.name',
    },
    {
      title: t('screens:entries.data.cost'),
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record) => formatPrice(record.cost),
    },
    {
      title: t('screens:financial.receipt'),
      key: 'receipt',
      align: 'center',
      dataIndex: 'receipt',
      render: (text, record) => (
        <TableActions>
          <Button
            title={t('messages:attachment')}
            style={{ margin: 'auto', cursor: 'pointer' }}
            onClick={() => {
              setReceipts(record.expenseReceipts);
              setShowFileList(true);
            }}
          >
            <FaPaperclip />
          </Button>
        </TableActions>
      ),
    },
    {
      title: t('screens:entries.data.status'),
      key: 'expenseStatus',
      align: 'center',
      dataIndex: 'expenseStatus',
      render: (text, record) => getStatus(record),
    },
    {
      title: (
        <label
          className="checkBoxContainer"
          style={{
            marginBottom: '3px',
            marginRight: '5px',
          }}
        >
          <input
            type="checkbox"
            id="checkbox"
            onChange={e => {
              handleSelectAll(e);
            }}
            checked={selectedAll}
          />
          <span className="checkmark"></span>
        </label>
      ),
      key: 'checkbox',
      render: (text, record) => (
        <TableActions>
          <label className="checkBoxContainer">
            <input
              type="checkbox"
              className="checkBoxTable"
              onChange={e => {
                handleSelect(record, e);
              }}
              checked={record.checked && record.checked}
            />
            <span className="checkmark"></span>
          </label>
        </TableActions>
      ),
    },
    {
      title: t('screens:entries.data.actions'),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TableActions>
            <Popconfirm
              icon={false}
              disabled={record.expenseStatus === 'DISAPPROVED' ? true : false}
              title={t('messages:reprove')}
              okText={t('messages:yes')}
              onConfirm={() => handleStatus(record, 'DISAPPROVED')}
              cancelText={t('messages:no')}
            >
              <Button
                title={t('messages:reprove')}
                disabled={record.expenseStatus === 'DISAPPROVED' ? true : false}
                style={{ cursor: record.expenseStatus === 'DISAPPROVED' ? 'not-allowed' : 'pointer' }}
              >
                <FaTimes style={{ color: record.expenseStatus === 'DISAPPROVED' ? 'gray' : 'red', fontSize: '18px' }} />
              </Button>
            </Popconfirm>
            <Button
              title={t('messages:edit')}
              onClick={() => handleEdit(record.id)}
            >
              <FaPencilAlt style={{
                color: 'green'
              }} />
            </Button>
            <Popconfirm
              icon={false}
              title={t('messages:undo')}
              okText={t('messages:yes')}
              disabled={record.expenseStatus === 'PENDING' ? true : false}
              onConfirm={() => handleStatus(record, 'UNDO')}
              cancelText={t('messages:no')}
            >
              <Button
                title={t('messages:undo')}
                disabled={record.expenseStatus === 'PENDING' ? true : false}
                style={{ cursor: record.expenseStatus === 'PENDING' ? 'not-allowed' : 'pointer' }}
              >
                <FaUndo style={{ color: record.expenseStatus === 'PENDING' ? 'gray' : 'blue' }} />
              </Button>
            </Popconfirm>
          </TableActions>
        </div>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <Box>
        <PageTitle title="RDV > Aprovação de lançamentos" />
        <Formik
          initialValues={{ cnpjCard: registrationNumber, costCenter: costCenterId, taskIds: taskIds, collaborators: collaboratorIds, status: expenseStatus }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ errors, isSubmitting, submitForm, resetForm }) => (
            <Spin spinning={loading}>
              <Form>
                <Row>
                  <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                    <FormControl
                      label={t('screens:entries.data.cnpj')}
                      field="cnpjCard"
                      error={errors.cnpjCards}
                    >
                      <Select showSearch name="cnpjCard" onChange={registrationNumber => handleChangeCnpj(registrationNumber)}>
                        {cnpjCards.map(cnpjCard => {
                          return (
                            <Option key={cnpjCard.id} value={cnpjCard.registrationNumber}>
                              <Tooltip placement="topLeft" title={cnpjCard.businessName}>
                                <span>[ {cnpjCard.registrationNumber} ] {cnpjCard.businessName}</span>
                              </Tooltip>
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                    <FormControl
                      label={t('screens:entries.data.cost-center')}
                      field="costCenter"
                      error={errors.costCenter}
                    >
                      <Select showSearch name="costCenter" onChange={costCenterId => handleChangeCostCenter(costCenterId)}>
                        {costCenters.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.code} - {item.clientName}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                    <FormControl
                      label={t('screens:entries.data.task')}
                      field="taskIds"
                      error={errors.taskIds}
                    >
                      <Select maxTagCount={1} mode="multiple" showSearch name="taskIds" onChange={handleChangeTask}>
                        {tasks.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={12} md={7} lg={4} xl={4}>
                    <FormControl label={t('screens:entries.data.status')} field="status">
                      <Select showSearch
                        name="status" onChange={status => handleChangeStatus(status)}>
                        {status.map(item => {
                          return (
                            <Option key={item.id} value={item.code}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={10} md={7} lg={5} xl={5}>
                    <FormControl
                      error={errors.collaborators}
                      field="collaborators"
                    >
                      <>
                        <Checkbox name="userActive" checked={userActiveCheck}
                          onChange={(e) => {
                            setUserActiveCheck(e.target.checked);
                            fetchCollaborator(registrationNumber, costCenterId, taskIds, expenseStatus, e.target.checked);
                          }}
                        >
                          {t('screens:financial.collaboratorsActive')}
                        </Checkbox>
                        <Select
                          maxTagCount={1}
                          mode="multiple"
                          showSearch
                          name="collaborators"
                          onChange={handleChangeCollaborator}
                        >
                          {collaborators.map(item => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={14} md={10} lg={8} xl={6}>
                    <ul style={{ margin: '0px' }}>
                      <li style={{ display: 'inline-block', paddingRight: '8px' }}>
                        <FormControl label={t('screens:dateSearch.of')} error={errors.initial} field="initial">
                          <DatePicker style={{ width: '125px' }} format="DD/MM/Y" name="init" placeholder={t('messages:select')} />
                        </FormControl>
                      </li>
                      <li style={{ display: 'inline-block' }}>
                        <FormControl label={t('screens:dateSearch.to')} field="final">
                          <DatePicker style={{ width: '125px' }} format="DD/MM/Y" name="end" placeholder={t('messages:select')} />
                        </FormControl>
                      </li>
                    </ul>
                  </Col>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}
                    onClick={() => {
                      setSelectedRecords(new Map());
                      setSelectedRecordsSize(0);
                      setSelectedAll(false);
                    }}>
                    <FaSearch />
                    {t('screens:dateSearch.button')}
                  </Button>
                  <Button
                    onClick={() => {
                      setRegistrationNumber(cnpj);
                      setCostCenterId(0);
                      setTaskIds([0]);
                      setCollaboratorIds([0]);
                      resetForm();
                      setFields(true);
                      clear();
                    }}
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 25 }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <Table
          rowKey="id"
          rowClassName={record =>
            record.expenseType === 'GENERIC' && (record.observation !== null || record.supervisorObservation !== null) ? 'GENERIC' : record.expenseType !== 'GENERIC' ? record.expenseType : 'GENERICWITHOUTOBSERVATION ' + record.expenseStatus
          }
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={dataTable}
          expandedRowRender={record => handleExtraCollums(record)}
        />
        <TotalValue>Total: {formatPrice(totalValue)}</TotalValue>
        <TableHeader>
          <Button
            style={{ display: selectedRecordsSize < 1 ? 'none' : 'block', marginRight: '10px' }}
            color="default"
            onClick={() => {
              handleDownload();
            }}
          >
            Baixar itens selecionados
          </Button>
          <Button
            style={{ display: selectedRecordsSize < 1 ? 'none' : 'block', marginRight: '10px' }}
            color={'primary'}
            onClick={() => {
              handleDownloadAll();
            }}
          >
            Baixar comprovantes selecionados
          </Button>
          <Button
            style={{ display: selectedRecordsSize < 1 ? 'none' : 'block' }}
            color="success"
            onClick={() => aproveAll()}
          >
            Aprovar selecionados
          </Button>
        </TableHeader>
        <FileList
          receipts={receipts}
          visible={showFileList}
          onClose={() => {
            setShowFileList(false);
            setReceipts([]);
          }}
        />
        <EntriesEdit
          id={selectedRecord}
          visible={showForm}
          onClose={(value) => {
            setShowForm(false);
            if (value === "save") {
              fetchRecords(filters);
            }
          }}
        />
        <Pagination
          onChange={value => {
            handleChangePage(value);
          }}
          meta={meta}
        />
      </Box>
    </DefaultLayout>
  );
}
