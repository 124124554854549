import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Select, Form } from 'formik-antd';
import { Col, Popconfirm, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaPencilAlt, FaPlus, FaRegTrashAlt, FaSearch } from 'react-icons/fa';

import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';

import ItemForm from './form';
import Box from '~/components/Box';
import Row from '~/components/Row';
import PageTitle from '~/components/PageTitle';
import { Table, TableActions } from '~/components/Table';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';

export default function Items() {
  const { Option } = Select;
  const { t } = useTranslation();

  const [recordData, setRecordData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectItem, setSelectItem] = useState([]);
  const [selectBudgetaryNature, setSelectBudgetaryNature] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);

  let selectItemFilter = [];
  let controlItem;

  const fetchRecord = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('items/findAll');
      setRecordData(data);
      selectItemFilter = [...data];
      selectItemFilter.splice(0, 0, {
        id: 0,
        name: 'Todos',
        delete: false,
      });

      setSelectItem(
        // eslint-disable-next-line
        selectItemFilter.filter(item => {
          if (item.name !== controlItem) {
            controlItem = item.name;
            return true;
          }
        })
      );
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchTask = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('tasks/findByFilter', {
        params: {
          itemId: 0,
          budgetaryNatureName: '',
        },
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      data.splice(0, 0, {
        id: 0,
        code: '',
        name: 'Todos',
        deleted: false,
      });
      setTasks(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchBudgetaryNature = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/budgetaryNatures/findByItemIdGroupName', {
        params: { itemId: 0 },
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      data.splice(0, 0, {
        id: 0,
        code: '',
        name: 'Todos',
        deleted: false,
      });
      setSelectBudgetaryNature(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleBudgetaryNatureSelect = async event => {
    setLoading(true);
    try {
      const { data } = await api.get('/budgetaryNatures/findByItemIdGroupName', {
        params: { itemId: event },
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setSelectBudgetaryNature(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleTaskSelect = async event => {
    let params = {
      itemId: 0,
      budgetaryNatureName: '',
    };
    setLoading(true);
    try {
      if (typeof event === 'string' && event !== 'Todos') {
        params.budgetaryNatureName = event;
      }

      if (typeof event === 'number' && event !== 'Todos') {
        params.itemId = event;
      }

      const { data } = await api.get('/tasks/findByFilter', {
        params: params,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setTasks(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSearch = async values => {
    setLoading(true);
    try {
      if (values.name === 'Todos' && values.budgetaryNature === 'Todos' && values.task === 'Todos') {
        fetchRecord();
      }

      let filter = {
        id: 0,
        code: '',
        name: '',
        namebudgetaryNatureId: 0,
        budgetaryNatureName: '',
        taskId: 0,
      };

      if (values.name !== 'Todos' && values.name !== null) {
        filter.id = values.name;
      }

      if (values.budgetaryNature !== 'Todos' && values.budgetaryNature !== null) {
        filter.budgetaryNatureName = values.budgetaryNature;
      }

      if (values.task !== 'Todos' && values.task !== null) {
        filter.taskId = values.task;
      }
      const { data } = await api.get('/items/findByFilter', {
        params: filter,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/items/delete/${id}`);
      message.success(t('messages:itemSuccessDeleted'));
      fetchRecord();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const tableColumns = [
    {
      title: t('screens:item.data.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:item.data.budgetaryNature'),
      dataIndex: 'budgetaryNature.name',
      key: 'budgetaryNature.name',
    },
    {
      title: t('screens:item.data.name'),
      dataIndex: 'budgetaryNature.task.name',
      key: 'budgetaryNature.task.name',
    },
    {
      title: t('screens:item.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button title={t('screens:item.data.editing')} onClick={() => handleEdit(record.id)}>
            <FaPencilAlt color={'green'} />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            okText={t('messages:yes')}
            onConfirm={() => handleDelete(record.id)}
            cancelText={t('messages:no')}
          >
            <Button>
              <FaRegTrashAlt color={'red'} />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  useEffect(() => {
    fetchRecord();
    fetchTask();
    fetchBudgetaryNature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  return (
    <DefaultLayout>
      <Box>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14} style={{ float: 'left' }}>
            <PageTitle
              title={'RDV > Cadastro > ' + t('screens:item.title')}
              style={{ marginRight: 'auto', width: '100%' }}
            />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{ marginLeft: 'auto' }}>
            <Button
              style={{ marginLeft: 'auto' }}
              color="primary"
              onClick={() => {
                setShowForm(true);
                setSelectedRecord(null);
              }}
            >
              <FaPlus />
              {t('screens:item.newBtn')}
            </Button>
          </Col>
        </Row>
        <Formik
          initialValues={{ name: 'Todos', budgetaryNature: 'Todos', task: 'Todos' }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ resetForm, isSubmitting }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <Row>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="name" label={t('screens:item.data.name')}>
                      <Select
                        name="name"
                        onChange={event => {
                          handleBudgetaryNatureSelect(event);
                          handleTaskSelect(event);
                        }}
                      >
                        {selectItem.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="budgetaryNature" label={t('screens:item.data.budgetaryNature')}>
                      <Select name="budgetaryNature" onChange={event => handleTaskSelect(event)}>
                        {selectBudgetaryNature.map(budgetaryNature => {
                          return (
                            <Option key={budgetaryNature.id} value={budgetaryNature.name}>
                              {budgetaryNature.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="task" label={t('screens:item.data.task')}>
                      <Select name="task">
                        {tasks.map(task => {
                          return (
                            <Option key={task.id} value={task.id}>
                              {task.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}>
                    <FaSearch style={{ width: 18 }} />
                    {t('screens:item.data.search')}
                  </Button>
                  <Button
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 25 }}
                    onClick={() => {
                      resetForm();
                      fetchRecord();
                    }}
                  >
                    {t('screens:item.data.clear')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <Table columns={tableColumns} rowKey="id" dataSource={recordData} loading={loading}></Table>
        <ItemForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
      </Box>
    </DefaultLayout>
  );
}
