import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Popconfirm, Col, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'formik-antd';
import { FaPencilAlt, FaPlus, FaRegTrashAlt } from 'react-icons/fa';

import DefaultLayout from '~/pages/_layouts/full';
import api from '~/services/api';

import Box from '~/components/Box';
import Row from '~/components/Row';
import PageTitle from '~/components/PageTitle';
import { Table, TableActions } from '~/components/Table';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import errorHandler from '~/Utils/errorHandler';
import BudgetaryNatureForm from './form';

export default function BudgetaryNature() {
  const [recordData, setRecordData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectBudgetaryNature, setSelectBudgetaryNature] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const { Option } = Select;
  const { t } = useTranslation();

  let selectBudgetaryNatureFilter;
  let control = null;

  const fetchRecords = async () => {
    setLoading(false);
    try {
      const { data } = await api.get('/budgetaryNatures/findAll');
      setRecordData(data);
      selectBudgetaryNatureFilter = [...data];
      selectBudgetaryNatureFilter.splice(0, 0, {
        id: 0,
        code: 'Todos',
        name: 'Todos',
        deleted: false,
      });

      setSelectBudgetaryNature(
        // eslint-disable-next-line
        selectBudgetaryNatureFilter.filter(item => {
          if (item.code !== control) {
            control = item.code;
            return true;
          }
        })
      );
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/tasks/findAll');
      data.splice(0, 0, {
        id: 0,
        code: 'Todos',
        name: 'Todos',
        deleted: false,
      });
      setTasks(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSearch = async values => {
    setLoading(true);

    try {
      if (values.code === 'Todos' && values.name === 'Todos' && values.task === 'Todos') {
        fetchRecords();
      }

      let filter = { id: 0, code: '', name: '', taskId: 0, itemId: 0 };

      if (values.code !== 'Todos' && values.code !== null) {
        filter.code = values.code;
      }
      if (values.name !== 'Todos' && values.name !== null) {
        filter.name = values.name;
      }
      if (values.task !== 'Todos' && values.task !== null) {
        filter.taskId = values.task;
      }

      const { data } = await api.get('/budgetaryNatures/findByFilter', {
        params: filter,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/budgetaryNatures/delete/${id}`);
      fetchRecords();
      message.success(t('messages:budgetarySuccessDeteled'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = async id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  useEffect(() => {
    fetchRecords();
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  const tableColumns = [
    {
      title: t('screens:budgetaryNature.data.code'),
      dataIndex: 'code',
      key: 'code',
      width: '20%',
    },
    {
      title: t('screens:budgetaryNature.data.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:budgetaryNature.data.task'),
      dataIndex: 'task.name',
      key: 'task.name',
    },
    {
      title: t('screens:budgetaryNature.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button title={t('screens:editing')} onClick={() => handleEdit(record.id)}>
            <FaPencilAlt color={'green'} />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            okText={t('messages:yes')}
            onConfirm={() => handleDelete(record.id)}
            cancelText={t('messages:no')}
          >
            <Button>
              <FaRegTrashAlt color={'red'} />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <Box>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14} style={{ float: 'left' }}>
            <PageTitle
              title={'RDV > Cadastro > ' + t('screens:budgetaryNature.title')}
              style={{ marginRight: 'auto', width: '100%' }}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginLeft: 'auto' }}>
            <Button
              style={{ marginLeft: 'auto' }}
              color="primary"
              onClick={() => {
                setShowForm(true);
                setSelectedRecord(null);
              }}
            >
              <FaPlus />
              {t('screens:budgetaryNature.newBtn')}
            </Button>
          </Col>
        </Row>
        <Formik
          initialValues={{ code: 'Todos', name: 'Todos', task: 'Todos' }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ resetForm, isSubmitting }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <Row>
                  <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    <FormControl label={t('screens:budgetaryNature.data.code')} field="code">
                      <Select name="code">
                        {selectBudgetaryNature.map(budgetaryNature => {
                          return (
                            <Option key={budgetaryNature.id} value={budgetaryNature.code}>
                              {budgetaryNature.code}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl label={t('screens:budgetaryNature.data.name')} field="name">
                      <Select name="name">
                        {selectBudgetaryNature.map(budgetaryNature => {
                          return (
                            <Option key={budgetaryNature.id} value={budgetaryNature.name}>
                              {budgetaryNature.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl label={t('screens:budgetaryNature.data.task')} field="task">
                      <Select name="task">
                        {tasks.map(task => {
                          return (
                            <Option key={task.id} value={task.id}>
                              {task.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}>
                    {t('screens:budgetaryNature.data.search')}
                  </Button>
                  <Button
                    color="default"
                    style={{ marginRight: 10, marginLeft: 10, marginTop: 25 }}
                    onClick={() => {
                      resetForm();
                      fetchRecords();
                    }}
                  >
                    {t('screens:budgetaryNature.data.clear')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <Table columns={tableColumns} rowKey="id" dataSource={recordData} loading={loading}></Table>
        <BudgetaryNatureForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
      </Box>
    </DefaultLayout>
  );
}
