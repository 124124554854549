import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Modal, message, Spin } from 'antd';

import api from '~/services/api';
import errorHandler from '../../Utils/errorHandler';

import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

export default function ItemForm({ visible, id, onClose }) {
  const { t } = useTranslation();
  const { Option } = Select;

  const [recordData, setRecordData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState([]);
  const [budgetaryNatures, setBudgetaryNatures] = useState([]);
  const [budgetaryNature, setBudgetaryNature] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      if (!id) {
        setRecordData([]);
      } else {
        const { data } = await api.get('/items/findById/', {
          params: { id: id },
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setRecordData(data);
        setTask(data.budgetaryNature.task);
        setBudgetaryNature(data.budgetaryNature);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('tasks/findAll');
      setTasks(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleBudgateryNature = async id => {
    setLoading(true);
    try {
      const { data } = await api.get('/budgetaryNatures/findByFilter', {
        params: { taskId: id },
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setBudgetaryNatures(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async values => {
    setLoading(true);
    const budgetaryNature = budgetaryNatures.filter(item => item.task.id === values.task);

    const data = {
      id: null,
      code: 'GENERIC',
      name: values.name,
      deleted: false,
      budgetaryNature: budgetaryNature[0],
    };
    try {
      if (!id) {

        await api.post('/items/create', data);
        onClose();
        message.success(t('messages:itemSuccess'));
      } else {
        data.id = id;

        await api.put('/items/update', data);
        onClose();
        message.success(t('messages:itemSuccessEdit'));
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
      fetchTasks();
      if (id) {
        handleBudgateryNature(task.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSave}
      initialValues={
        id
          ? {
              task: task.id,
              budgetaryNature: budgetaryNature.id,
              name: recordData.name,
            }
          : {}
      }
    >
      {({ resetForm, isSubmitting, submitForm }) => (
        <Modal
          title={
            recordData.id ? `${t('screens:item.data.editing')} ${recordData.name}` : t('screens:item.data.newItem')
          }
          onCancel={onClose}
          afterClose={resetForm}
          loading={loading || isSubmitting}
          visible={visible}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading}>
            <Form>
              <Row>
                <FormControl field="task" label={t('screens:item.data.task')} cols={{ xs: 24 }}>
                  <Select name="task" placeholder="Selecione" onChange={event => handleBudgateryNature(event)}>
                    {tasks.map(task => {
                      return (
                        <Option key={task.id} value={task.id}>
                          {task.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl field="budgetaryNature" label={t('screens:item.data.budgetaryNature')} cols={{ xs: 24 }}>
                  <Select name="budgetaryNature" placeholder="Selecione">
                    {budgetaryNatures.map(budgetaryNature => {
                      return (
                        <Option key={budgetaryNature.id} value={budgetaryNature.id}>
                          {budgetaryNature.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl field="name" label={t('screens:item.data.name')} cols={{ xs: 24 }}>
                  <Input name="name" />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
