import styled from 'styled-components';

export const Container = styled.div`
  font-size: ${props => props.size};
  font-style: italic;
  font-weight: bold;
  color: ${props => props.color || props.theme.color1};
  margin: ${props => props.margin};
`;

export const Title = styled.h1`
  color: #ccc;
  border-top: 0;
  padding-top: 0;
  font-size: 18px;
`;
