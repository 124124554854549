import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { store } from '~/store';

import Logo from '~/components/Logo';
import { tokenExpired } from '~/store/modules/auth/actions';

import Menu from '~/pages/_partials/Menu';
import SmallMenu from '~/pages/_partials/SmallMenu';
import Header from '~/pages/_partials/Header';
import Footer from '~/pages/_partials/Footer';
import Warning from '~/components/Warning';
import ModalCnpj from '~/components/ModalCnpj';
import StagingMessage from '~/components/StagingMessage';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';

import { Container } from './styles';

export default function FullLayout({ children }) {
  const { Content } = Layout;
  const [warning, setWarning] = useState(false);
  const [modalCnpj, setModalCnpj] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);
  const dispatch = useDispatch();

  const fetchModalCnpj = () => {
    setModalCnpj(
      <ModalCnpj
        visible={store.getState().auth.signed && store.getState().auth.cnpj === ""}
        onClose={() => {
          fetchModalCnpj();
        }}
      />
    );
  }

  const fetchRecords = async () => {
    try {
      const { data } = await api.get('/users/find');
      const response = await api.get('/notices/findById', {
        params: { id: 1 },
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setWarning(
        <Warning
          user={data}
          visible={!data.noticeViewed && response.data.visible}
          onClose={() => {
            fetchRecords();
          }}
        />
      );
    } catch (error) {
      if (error.message === 'Network Error') {
        dispatch(tokenExpired());
      } else {
        errorHandler(error);
      }
    }
  };

  const sizeOfThings = () => {
    var windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', function() {
    sizeOfThings();
  });

  useEffect(() => {
    fetchRecords();
    fetchModalCnpj();
    sizeOfThings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="layout">
      {window.location.href.indexOf('https://stg.tanksbr.com.br') !== -1 ? <StagingMessage /> : ''}

      <Header>
        {windowWidth < 500 ? <Logo height={40} width={73} /> : <Logo height={80} width={146} />}
        {windowWidth < 500 ? <SmallMenu /> : <Menu />}
      </Header>
      <Content>
        {children}
        {warning}
        {modalCnpj}
      </Content>
      <Footer />
    </Container>
  );
}

FullLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
