import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaPencilAlt, FaRegTrashAlt, FaSearch } from 'react-icons/fa';
import { Popconfirm, Tag, message, Spin, Col, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { parse, format } from 'date-fns';
import { Formik } from 'formik';
import { Form, Select, DatePicker } from 'formik-antd';
import errorHandler from '~/Utils/errorHandler';
import history from '~/services/history';
import api from '~/services/api';
import { formatPrice } from 'Utils';
import axios from 'axios';
import Box from '~/components/Box';
import Row from '~/components/Row';
import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { Table, TableActions, TableHeader } from '~/components/Table';
import EntriesEdit from './formModal';
import FormControl from '~/components/Form/FormControl';
import Pagination from '~/components/Pagination';
import { TotalValue } from './styles';

export default function Entries() {
  const { t } = useTranslation();
  const token = useSelector(state => state.auth.token);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line
  const [recordList, setRecordList] = useState([]);
  const [meta, setMeta] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [costCenters, setCostCenters] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [itens, setItens] = useState([]);
  const [disableItem, setDisableItem] = useState(true);
  const [filters, setFilters] = useState([]);
  const [costCenterId, setCostCenterId] = useState(0);
  const [taskIds, setTaskIds] = useState([]);
  const [expenseStatus, setExpenseStatus] = useState('ALL');
  const [totalValue, setTotalValue] = useState(0);
  const [sortBy, setSortBy] = useState('releaseDate');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [selectedRecords, setSelectedRecords] = useState(new Map());
  const [selectedRecordsSize, setSelectedRecordsSize] = useState(0);
  const [selectedAll, setSelectedAll] = useState(false);

  const { Option } = Select;

  const fetchRecords = async (sortBy, sortDirection, filters) => {
    setLoading(true);
    try {
      const expensePaginationDTO = { page: 0, sortBy: sortBy, sortDirection: sortDirection, filters: filters };
      const { data } = await api.post('/expenses/paginationByLoggedUser', expensePaginationDTO);
      
      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
      };

      let content = [];
      content = data.content;
      // eslint-disable-next-line
      content.map((record) => {
        if (selectedRecords.has(record.id) === true || selectedAll === true) {
          record.checked = true;
        }
      });

      setSelectedRecords(new Map());
      setSelectedRecordsSize(0);
      setSelectedAll(false);
      setMeta(meta);
      setTableData(data.content);
      setRecordList(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchTotalCost = async (filters) => {
    try {
      const { data } = await api.post('/expenses/paginationByLoggedUserTotal', filters);
      setTotalValue(data);
    } catch (error) {
      errorHandler(error);
    }
  }

  const fetchCostCenters = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/costCenters/findByStatus', { params: { status: 'ACTIVE' } });
      data.splice(0, 0, { id: 0, code: '00.0000', clientName: 'Todos' });
      setCostCenters(data);
      setCostCenterId(0);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/tasks/findAll');
      data.splice(0, 0, { id: 0, name: 'Todos' });
      setTasks(data);
      setTaskIds([0]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchItens = async (taskValues) => {
    setLoading(true);
    try {
      const filter = {
        columnJoin: 'budgetaryNature',
        columnSecondJoin: 'task',
        field: 'id',
        value: taskValues,
        restriction: 'IN'
      };

      const { data } = await api.post('/items/findByTaskIds', filter);
      data.splice(0, 0, { id: 0, name: 'Todos' });
      setItens(data);
      setDisableItem(false);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async page => {
    setLoading(true);
    try {
      const expensePaginationDTO = { page: page - 1, sortBy: sortBy, sortDirection: sortDirection, filters: filters };
      const { data } = await api.post('/expenses/paginationByLoggedUser', expensePaginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber + 1,
        pageSize: data.pageable.pageSize,
      };

      let content = [];
      content = data.content;
      // eslint-disable-next-line
      content.map((record) => {
        if (selectedRecords.has(record.id) === true || selectedAll === true) {
          record.checked = true;
        }
      });

      setMeta(meta);
      setTableData(data.content);
      setRecordList(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeCostCenter = (costCenterValue) => {
    setCostCenterId(costCenterValue);
  };

  const handleChangeTask = (value) => {
    let taskValues = null;
    if (value.length === 0 || (value.length > 0 && value[value.length - 1] === 0)) {
      taskValues = [0];
    } else if (value.length === 2 && value[1] !== 0 && value[0] === 0) {
      taskValues = [value[1]];
    } else {
      taskValues = value;
    }
    setTaskIds(taskValues);
    fetchItens(taskValues);
  };

  const handleChangeStatus = (expenseStatusValue) => {
    setExpenseStatus(expenseStatusValue);
  };

  const handleNew = () => {
    history.push('/lancamentos/novo');
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleSearch = values => {
    setLoading(true);
    const filter = [];
    const filterTotalCost = [];
    let sortBy = "releaseDate";
    let sortDirection = "DESC";

    if (values.costCenter !== undefined && values.costCenter !== null && 
      values.costCenter !== 'Todos' && values.costCenter !== 0) {
      filter.push({ columnJoin: 'costCenter', field: 'id', value: values.costCenter, restriction: 'EQUAL' });
      filterTotalCost.push({ field: 'costCenterId', value: values.costCenter, restriction: 'EQUAL' });
    }

    if (values.taskIds !== undefined && values.taskIds !== null &&
      values.taskIds.length > 0 && values.taskIds[0] !== 0) {
        filter.push({ columnJoin: 'item', columnSecondJoin: 'budgetaryNature', columnThirdJoin: 'task', field: 'id', value: values.taskIds, restriction: 'IN' });
        filterTotalCost.push({ field: 'taskIds', value: values.taskIds, restriction: 'IN' });
    }

    if (values.init !== undefined && values.init !== null) {
      const start = format(new Date(values.init), 'dd-MM-yyyy');
      filter.push({field: 'date', value: start, restriction: 'GREATER_THAN_OR_EQUAL_TO' });
      filterTotalCost.push({field: 'dateInit', value: start, restriction: 'GREATER_THAN_OR_EQUAL_TO' });
      sortBy = "date";
      sortDirection = values.sortDirection;
    }

    if (values.end !== undefined && values.end !== null) {
      const final = format(new Date(values.end), 'dd-MM-yyyy');
      filter.push({field: 'date', value: final, restriction: 'LESS_THAN_OR_EQUAL_TO'});
      filterTotalCost.push({field: 'dateEnd', value: final, restriction: 'LESS_THAN_OR_EQUAL_TO'});
      sortBy = "date";
      sortDirection = values.sortDirection;
    }

    if (values.status !== undefined && values.status !== 'Todos' && values.status !== 'ALL') {
      filter.push({ field: 'expenseStatus', value: values.status, restriction: 'EQUAL'});
      filterTotalCost.push({ field: 'expenseStatus', value: values.status, restriction: 'EQUAL'});
    }

    if (values.item !== undefined && values.item !== null && 
      values.item !== 'Todos' && values.item !== 0) {
      filter.push({ columnJoin: 'item', field: 'name', value: values.item, restriction: 'EQUAL' });
      filterTotalCost.push({ field: 'itemId', value: values.item, restriction: 'EQUAL'});
    }

    try {
      setSortBy(sortBy);
      setSortDirection(sortDirection);
      setTotalValue(0);
      setFilters(filter);
      fetchRecords(sortBy, sortDirection, filter);
      fetchTotalCost(filterTotalCost);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/expenses/delete/${id}`);
      await fetchRecords(sortBy, sortDirection, filters);
      message.success(t('messages:expenseSuccessDelete'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleFeedType = record => {
    const feedType = [
      { type: 'BREAKFAST', name: 'Café da manhã' },
      { type: 'LUNCH', name: 'Almoço' },
      { type: 'DINNER', name: 'Janta' },
    ];

    const feed = feedType.filter(item => item.type === record.feedType);

    return feed[0].name;
  };

  const handleExtraCollums = record => {
    switch (record.expenseType) {
      case 'MILEAGE_REIMBURSEMENT':
        return (
          <>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>KM Inicial: {record.initialKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>KM Final: {record.finalKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Placa do veículo: {record.licensePlate}</p>
              </Col>
            </Row>
            {record.observation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  <p>
                    {t('screens:entries.data.notes')}: {record.observation}
                  </p>
                </Col>
              </Row>
            ) : null}
            {record.supervisorObservation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>
            ) : null}
          </>
        );
      case 'FEED':
        return (
          <>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Número de pessoas: {record.personQuantity === 0 ? '1' : record.personQuantity}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Nome das pessoas: {record.personNames ? record.personNames : record.user.name}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Tipo da refeição: {handleFeedType(record)} </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Quantidade de refeições: {record.mealsQuantity} </p>
              </Col>
            </Row>
            {record.observation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  <p>
                    {t('screens:entries.data.notes')}: {record.observation}
                  </p>
                </Col>
              </Row>
            ) : null}
            {record.supervisorObservation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>
            ) : null}
          </>
        );
      case 'FUEL':
        return (
          <>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>KM Final: {record.finalKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Placa do veículo: {record.licensePlate}</p>
              </Col>
            </Row>
            {record.observation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  <p>
                    {t('screens:entries.data.notes')}: {record.observation}
                  </p>
                </Col>
              </Row>
            ) : null}
            {record.supervisorObservation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>
            ) : null}
          </>
        );
      case 'GENERIC':
        return (
          <>
            {record.observation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  <p>
                    {t('screens:entries.data.notes')}: {record.observation}
                  </p>
                </Col>
              </Row>
            ) : null}
            {record.supervisorObservation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>
            ) : null}
          </>
        );
      default:
        return;
    }
  };

  const handleSelect = async (record, e) => {
    const currentRecordId = record.id;
    const checked = e.target.checked;
    record.checked = checked;

    if (checked === true) {
      if (selectedRecords.has(currentRecordId) === false) {
        selectedRecords.set(currentRecordId, record);
      }

      if (selectedRecords.size === meta.totalElements) {
        setSelectedAll(true);
      }
    } else {
      if (selectedRecords.has(currentRecordId) === true) {
        selectedRecords.delete(currentRecordId);
      }
      setSelectedAll(false);
    }
    setSelectedRecordsSize(selectedRecords.size);
  };

  const handleSelectAll = async (e) => {
    let checked = e.target.checked;
    let selectedList = new Map();

    if (checked) {
      try {
        const { data } = await api.post('/expenses/findAllLoggedUser', filters);
        // eslint-disable-next-line
        data.map(record => {
          record.checked = true;
          selectedList.set(record.id, record);
        });
      } catch (error) {
        errorHandler(error);
      }

      setSelectedAll(true);
      setSelectedRecordsSize(meta.totalElements);
      // eslint-disable-next-line
      tableData.map(record => {
        record.checked = checked;
      });
    } else {
      setSelectedAll(false);
      setSelectedRecordsSize(0);
      // eslint-disable-next-line
      tableData.map(record => {
        record.checked = checked;
      });
    }
    setSelectedRecords(selectedList);

    const checkboxLength = document.getElementsByClassName('checkBoxTable').length;
    for (let i = 0; i < checkboxLength; i++) {
      document.getElementsByClassName('checkBoxTable')[i].checked = checked;
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    let selectedEntries = [];
    selectedRecords.forEach((value) => {
      let record = value;
      delete record.checked;
      selectedEntries.push(record);
    });

    try {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/expenses/entries`,
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, },
        responseType: 'blob',
        data: selectedEntries,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'lancamentos.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        if (error.response && error.response.status === 404) {
            message.error(t('messages:downloadItensSelectedError'));
            setLoading(false);
        }
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setSelectedRecords(new Map());
    setSelectedRecordsSize(0);
    setSelectedAll(false);
    fetchRecords('releaseDate', 'DESC', []);
    fetchTotalCost([]);
    setSelectedRecord(null);
    fetchCostCenters();
    fetchTasks();
    fetchItens([0]);
    setSortBy('releaseDate');
    setSortDirection('DESC');
    setExpenseStatus('ALL');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('screens:entries.data.cost-center'),
      dataIndex: 'costCenter.clientName',
      key: 'costCenter.clientName',
    },
    {
      title: t('screens:entries.data.date'),
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div style={{ width: '71px' }}>
          {format(parse(record.date, 'dd-MM-yyyy HH:mm:ss', new Date()), 'dd-MM-yyyy')}
        </div>
      ),
    },
    {
      title: t('screens:entries.data.task'),
      dataIndex: 'item.budgetaryNature.task.name',
      key: 'item.budgetaryNature.task.name',
    },
    {
      title: t('screens:entries.data.item'),
      dataIndex: 'item.name',
      key: 'item.name',
    },
    {
      title: t('screens:entries.data.cost'),
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record) => formatPrice(record.cost),
    },
    {
      title: t('screens:entries.data.status'),
      dataIndex: 'expenseStatus',
      align: 'center',
      key: 'expenseStatus',
      render: status => {
        switch (status) {
          case 'PENDING':
            return (
              <Tag style={{ margin: 0 }} color="orange">
                {t('screens:status.pending')}
              </Tag>
            );
          case 'APPROVED':
            return (
              <Tag style={{ margin: 0 }} color="green">
                {t('screens:status.approved')}
              </Tag>
            );
          case 'APPROVED_RESTRICTION':
            return (
              <Tag style={{ margin: 0 }} color="yellow">
                {t('screens:status.approvedrestriction')}
              </Tag>
            );
          case 'DISAPPROVED':
            return (
              <Tag style={{ margin: 0 }} color="red">
                {t('screens:status.disapproved')}
              </Tag>
            );
          case 'PAID':
            return (
              <Tag style={{ margin: 0 }} color="blue">
                {t('screens:status.paid')}
              </Tag>
            );
          default:
            return;
        }
      },
    },
    {
      title: (
        <label
          className="checkBoxContainer"
          style={{
            marginBottom: '3px',
            marginRight: '5px',
          }}
        >
          <input
            type="checkbox"
            id="checkbox"
            onChange={e => {
              handleSelectAll(e);
            }}
            checked={selectedAll}
          />
          <span className="checkmark"></span>
        </label>
      ),
      key: 'checkbox',
      render: (text, record) => (
        <TableActions>
          <label className="checkBoxContainer">
            <input
              type="checkbox"
              className="checkBoxTable"
              onChange={e => {
                handleSelect(record, e);
              }}
              checked={record.checked && record.checked}
            />
            <span className="checkmark"></span>
          </label>
        </TableActions>
      ),
    },
    {
      title: t('screens:entries.data.actions'),
      key: 'actions',
      align: 'center',
      className: 'table-action',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TableActions>
            <Button
              title={t('messages:edit')}
              onClick={() =>
                record.expenseStatus === 'PAID' || record.expenseStatus === 'APPROVED' ? '' : handleEdit(record.id)
              }
              disabled={record.expenseStatus === 'PAID' || record.expenseStatus === 'APPROVED' ? true : false}
              style={{
                cursor:
                  record.expenseStatus === 'PAID' || record.expenseStatus === 'APPROVED' ? 'not-allowed' : 'pointer',
              }}
            >
              <FaPencilAlt
                style={{
                  color: record.expenseStatus === 'PAID' || record.expenseStatus === 'APPROVED' ? 'gray' : 'green',
                }}
              />
            </Button>
            <Popconfirm
              disabled={record.expenseStatus === 'PAID' || record.expenseStatus === 'APPROVED' ? true : false}
              icon={false}
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button
                title="Excluir"
                disabled={record.expenseStatus === 'PAID' || record.expenseStatus === 'APPROVED' ? true : false}
                style={{
                  margin: 'auto',
                  cursor: record.expenseStatus === 'PAID' || record.expenseStatus === 'APPROVED' ? 'not-allowed' : 'pointer',
                }}
              >
                <FaRegTrashAlt
                  style={{
                    color: record.expenseStatus === 'PAID' || record.expenseStatus === 'APPROVED' ? 'gray' : 'red',
                  }}
                />
              </Button>
            </Popconfirm>
          </TableActions>
        </div>
      ),
    },
  ];

  const status = [
    {
      id: 0,
      code: 'ALL',
      description: t('screens:status.all'),
    },
    {
      id: 1,
      code: 'PENDING',
      description: t('screens:status.pending'),
    },
    {
      id: 2,
      code: 'APPROVED',
      description: t('screens:status.approved'),
    },
    {
      id: 3,
      code: 'PAID',
      description: t('screens:status.paid'),
    },
    {
      id: 4,
      code: 'DISAPPROVED',
      description: t('screens:status.disapproved'),
    },
    {
      id: 5,
      code: 'APPROVED_RESTRICTION',
      description: t('screens:status.approvedrestriction'),
    },
  ];

  const sortDirections = [
    {
      id: 0,
      code: 'ASC',
      description: t('screens:sortDirection.asc'),
    },
    {
      id: 1,
      code: 'DESC',
      description: t('screens:sortDirection.desc'),
    },
  ];

  return (
    <DefaultLayout>
      <Box>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col xs={24} sm={13} md={12} lg={12} xl={12} style={{ float: 'left' }}>
            <PageTitle title={`RDV > ${t('screens:entries.title')}`} style={{ marginRight: 'auto', width: '100%' }} />
          </Col>
          <Col xs={24} sm={11} md={12} lg={12} xl={12} style={{ marginLeft: 'auto' }}>
            <Button color="primary" onClick={handleNew} style={{ marginLeft: 'auto' }}>
              <FaPlus />
              {t('screens:entries.btnNew')}
            </Button>
          </Col>
        </Row>
        <Formik
          initialValues={{ costCenter: costCenterId, taskIds: taskIds, item: 0, status: expenseStatus, sortDirection: 'ASC' }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ errors, submitForm, resetForm, values }) => (
            <Spin spinning={loading}>
              <Form style={{ marginTop: '15px' }}>
                <Row>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <FormControl
                      error={errors.costCenter}
                      field="costCenter"
                      label={t('screens:entries.data.cost-center')}
                    >
                      <Select showSearch name="costCenter" onChange={costCenterId => handleChangeCostCenter(costCenterId)}>
                        {costCenters.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.code} - {item.clientName}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={7}>
                    <FormControl error={errors.taskIds} field="taskIds" label={t('screens:entries.data.task')}>
                      <Select maxTagCount={1} mode="multiple" showSearch name="taskIds" onChange={handleChangeTask}>
                        {tasks.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <FormControl error={errors.item} field="item" label={t('screens:entries.data.item')}>
                      <Select showSearch name="item" disabled={disableItem}>
                        {itens.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={5}>
                    <FormControl error={errors.status} field="status" label={t('screens:entries.data.status')}>
                      <Select name="status" onChange={status => handleChangeStatus(status)}>
                        {status.map(item => {
                          return (
                            <Option key={item.id} value={item.code}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={9} xl={8}>
                    <ul style={{ margin: '0px' }}>
                      <li style={{ display: 'inline-block', paddingRight: '8px' }}>
                        <FormControl label={t('screens:dateSearch.of')} error={errors.initial} field="initial">
                          <DatePicker style={{ width: '125px' }} format="DD/MM/Y" name="init" placeholder={t('messages:select')} />
                        </FormControl>
                      </li>
                      <li style={{ display: 'inline-block', paddingRight: '8px' }}>
                        <FormControl label={t('screens:dateSearch.to')} field="final">
                          <DatePicker style={{ width: '125px' }} format="DD/MM/Y" name="end" placeholder={t('messages:select')} />
                        </FormControl>
                      </li>
                      <li style={{ display: 'inline-block' }}>
                      <FormControl field="sortDirection" label={t('screens:entries.data.order')}>
                      <Select 
                        name="sortDirection" 
                        disabled={(values.init === undefined || values.init === null) && (values.end === undefined || values.end === null)}>
                        {sortDirections.map(item => {
                          return (
                            <Option key={item.id} value={item.code}>
                              <Tooltip placement="topLeft" title={item.description}>
                                <span>{item.code}</span>
                              </Tooltip>
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                      </li>
                    </ul>
                  </Col>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}
                    onClick={() => {
                      setSelectedRecords(new Map());
                      setSelectedRecordsSize(0);
                      setSelectedAll(false);
                    }}>
                    <FaSearch />
                    {t('screens:dateSearch.button')}
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectedRecords(new Map());
                      setSelectedRecordsSize(0);
                      setSelectedAll(false);
                      setCostCenterId(0);
                      setTaskIds([0]);
                      setTotalValue(0);
                      resetForm();
                      setSortBy('releaseDate');
                      setSortDirection('DESC');
                      setFilters([]);
                      fetchRecords('releaseDate', 'DESC', []);
                      fetchTotalCost([]);
                      fetchCostCenters();
                      fetchTasks();
                      fetchItens([0]);
                      setDisableItem(true);
                      setItens([]);
                    }}
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 25 }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <Table
          rowKey="id"
          rowClassName={record =>
            (record.expenseType === 'GENERIC' && record.observation !== null) || record.supervisorObservation !== null
              ? 'GENERIC'
              : record.expenseType !== 'GENERIC'
                ? record.expenseType
                : 'GENERICWITHOUTOBSERVATION ' + record.expenseStatus
          }
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          expandedRowRender={record => handleExtraCollums(record)}
        />
        <TotalValue>Total: {formatPrice(totalValue)}</TotalValue>
        <TableHeader>
        <Button
            style={{ display: selectedRecordsSize < 1 ? 'none' : 'block' }}
            color="default"
            onClick={() => {
              handleDownload();
            }}
          >
            Baixar itens selecionados
          </Button>
        </TableHeader>
        <EntriesEdit id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
        <Pagination
          onChange={value => {
            handleChangePage(value);
          }}
          meta={meta}
        />
      </Box>
    </DefaultLayout>
  );
}
