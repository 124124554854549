import styled from 'styled-components';
import { Pagination } from 'antd';

export const Container = styled(Pagination)`
  position: relative;
  display: flex;
  float: none;
  justify-content: flex-end;
  margin: 16px 0;

  .ant-pagination-total-text {
    position: absolute;
    left: 0;
    font-weight: bold;
    color: ${props => props.theme.color1};
  }
`;
