import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Popconfirm, Spin, message, Col } from 'antd';
import { FaPlus, FaRegTrashAlt, FaPencilAlt, FaSearch } from 'react-icons/fa';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import { Table, TableActions } from '~/components/Table';
import FormControl from '~/components/Form/FormControl';
import api from '~/services/api';
import DefautLayout from '~/pages/_layouts/full';
import CostCenterForm from './form';
import errorHandler from '~/Utils/errorHandler';

export default function CostCenter() {
  const { t } = useTranslation();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordData, setRecordData] = useState([]);
  const [filterRecordData, setFilterRecordData] = useState([]);
  // eslint-disable-next-line
  const [selectItem, setSelectItem] = useState([]);

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/costCenters/delete/${id}`);
      message.success(t('messages:success'));
      fetchRecords();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/costCenters/findAll');
      setRecordData(data);
      handleFilter(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleFilter = async data => {

    setFilterRecordData(data);
  };

  const handleSearch = async values => {
    setLoading(true);
    try {
      let filter = {
        id: 0,
        code: '',
        clientName: '',
        nickname: '',
        status: '',
      };
  
      if (values.code !== 'Todos' && values.code !== null) {
        filter.code = values.code;
      }
      if (values.clientName !== 'Todos' && values.clientName !== null) {
        filter.clientName = values.clientName;
      }
      if (values.nickname !== 'Todos' && values.nickname !== null) {
        filter.nickname = values.nickname;
      }
  
      // Handle the 'Todos' value for the status filter
      if (values.status && values.status.trim() !== '' && values.status !== 'All') {
        filter.status = values.status;
      }
  
      const { data } = await api.get('/costCenters/findByFilter', { params: filter });
  
      // If none of the fields were selected for filtering,
      // set the recordData to the full data to show all records.
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    // Add the 'Todos' entry once during the initial page load
    setSelectItem([{ code: 'Todos', clientName: 'Todos', nickname: 'Todos' }]);
    fetchRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const tableColumns = [
    {
      title: t('screens:cost-center.data.code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('screens:cost-center.data.customer'),
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: t('screens:cost-center.data.nickname'),
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: t('screens:users.data.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      render: status =>
        status && status === 'ACTIVE' ? (
          <Tag color="green">{t('screens:users.data.active')}</Tag>
        ) : (
            <Tag color="red">{t('screens:users.data.inactive')}</Tag>
          ),
    },
    {
      title: t('screens:cost-center.data.actions'),
      key: 'actions',
      width: '10%',
      align: 'center',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
            <FaPencilAlt color={'green'} />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            okText={t('messages:yes')}
            onConfirm={() => handleDelete(record.id)}
            cancelText={t('messages:no')}
          >
            <Button title="Excluir">
              <FaRegTrashAlt color={'red'} />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const status = [
    {
      id: 0,
      status: "All",
      description: 'Todos'
    },
    {
      id: 1,
      status: 'ACTIVE',
      description: t('screens:cost-center.data.active')
    },
    {
      id: 2,
      status: 'INACTIVE',
      description: t('screens:cost-center.data.inactive')
    }
  ]

  return (
    <DefautLayout>
      <Box>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ float: 'left' }}>
            <PageTitle
              title={`RDV > Cadastro > ${t('screens:cost-center.title')}`}
              style={{ marginRight: 'auto', width: '100%' }}
            />
          </Col>
        </Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ marginLeft: 'auto' }}>
          <Button loading={loading} color="primary" 
            onClick={() => {
              setSelectedRecord(null);
              setShowForm(true);
            }}
            style={{ marginLeft: 'auto' }}>
            <FaPlus />
            {t('screens:cost-center.btnNew')}
          </Button>
        </Col>
        <Formik initialValues={{}} enableReinitialize onSubmit={handleSearch}>
          {({ errors, isSubmitting, submitForm, resetForm }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form style={{ marginTop: '15px' }}>
                <Row>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl label={t('screens:cost-center.data.code')} field="code" error={errors.code}>
                      <Select name="code" placeholder={t('messages:all')} showSearch>
                        <Option key={0} value="Todos">Todos</Option>
                        {filterRecordData.map(item => {
                          return (
                            <Option key={item.id} value={item.code}>
                              {item.code}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl
                      label={t('screens:cost-center.data.customer')}
                      field="clientName"
                      error={errors.clientName}
                    >
                      <Select name="clientName" showSearch placeholder={t('messages:all')}>
                        <Option key={0} value="Todos">Todos</Option>
                        {filterRecordData.map(item => {
                          return (
                            <Option key={item.id} value={item.clientName}>
                              {item.clientName}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl
                      label={t('screens:cost-center.data.nickname')}
                      field="nickname"
                      error={errors.nickname}
                    >
                      <Select name="nickname" showSearch placeholder={t('messages:all')}>
                        <Option key={0} value="Todos">Todos</Option>
                
                        {filterRecordData.map(item => {
                          return (
                            <Option key={item.id} value={item.nickname}>
                              {item.nickname}
                            </Option>
                          );
                        })}

                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl label={t('screens:cost-center.data.status')} field="status">
                      <Select name="status" placeholder={t('messages:all')}>
                        {status.map(item => {
                          return (
                            <Option key={item.id} value={item.status}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}>
                    <FaSearch style={{ width: '18px' }} />
                    {t('screens:dateSearch.button')}
                  </Button>
                  <Button
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 25 }}
                    onClick={() => {
                      resetForm();
                      fetchRecords();
                    }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <Table rowKey="id" loading={loading} dataSource={recordData} columns={tableColumns} />
        <CostCenterForm fetchRecords1={fetchRecords} id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
      </Box>
    </DefautLayout>
  );
}
