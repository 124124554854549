import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Switch, Input } from 'formik-antd';
import { Modal, message as AntdMessage, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';

const initialValues = {
  warning: '',
  active: true,
};

export default function WarningForm({ visible, onClose }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const userId = useSelector(state => (state.user.profile ? state.user.profile.id : null));

  const { TextArea } = Input;

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/findById', {
        params: { id: userId },
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { date, message, visible } = values;

      const newNotice = [{ id: values.id, date, message, visible, deleted: values.deleted, user: data }];

      await api.put('/notices/update', newNotice[0]);
      AntdMessage.success(t('messages:warningSuccess'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/notices/findById', {
        params: { id: 1 },
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const schema = Yup.object().shape({
    message: Yup.string().required(),
  });

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={schema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
        <Modal
          title={t('screens:warning.title')}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.message && errors.message}
                  field="message"
                  label={t('screens:warning.warning')}
                  required
                >
                  <>
                    <TextArea name="message" rows={8} maxLength={1000} />
                    <p style={{fontSize: '10px'}}>Limite maximo de 1000 caracteres</p>
                  </>
                </FormControl>

                <FormControl
                  cols={{ xs: 24 }}
                  field="visible"
                  label={t('screens:warning.active')}
                  style={{ margin: 'auto' }}
                >
                  <Switch
                    name="visible"
                    checked={recordData.visible === undefined ? true : recordData.visible}
                    checkedChildren={t('messages:yes')}
                    unCheckedChildren={t('messages:no')}
                    style={{ margin: 'auto' }}
                    onChange={e => {
                      recordData.visible = e;
                    }}
                  ></Switch>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
