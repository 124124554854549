import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Col } from 'antd';
import { Form, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import DefaulLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import FormActions from '~/components/Form/FormActions';
import PageTitle from '~/components/PageTitle';
import FormControl from '~/components/Form/FormControl';
import Card from '~/components/Card';
import { formatPrice } from 'Utils';

import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { parseISO, format } from 'date-fns';

export default function Dashboard() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [overView, setOverView] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/dashboard/findDashboardGraphData');
      // eslint-disable-next-line
      data.map(item => {
        switch (item.monthName) {
          case 'January':
            item.monthName = 'Janeiro';
            break;
          case 'February':
            item.monthName = 'Fevereiro';
            break;
          case 'March':
            item.monthName = 'Março';
            break;
          case 'April':
            item.monthName = 'Abril';
            break;
          case 'May':
            item.monthName = 'Maio';
            break;
          case 'June':
            item.monthName = 'Junho';
            break;
          case 'July':
            item.monthName = 'Julho';
            break;
          case 'August':
            item.monthName = 'Agosto';
            break;
          case 'September':
            item.monthName = 'Setembro';
            break;
          case 'October':
            item.monthName = 'Outubro';
            break;
          case 'November':
            item.monthName = 'Novembro';
            break;
          case 'December':
            item.monthName = 'Dezembro';
            break;
          default:
            break;
        }
      });
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const totalReleasedPerMonth = {
    chart: {
      type: 'column',
      spacingLeft: 0,
      scrollablePlotArea: {
        minWidth: 1024,
        scrollPositionX: 1,
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
    },

    xAxis: {
      className: 'highcharts-color-0',
      categories: recordData.map(item => {
        return `<b style="color: #131313; font-size: 16px; font-weight: normal; font-family: Roboto, sans-serif;">${
          item !== undefined ? item.monthName : ''
        }</b><br><b style="color: #0f4c81; font-size: 14px; font-weight: 600; font-family: Roboto, sans-serif;">${
          item !== undefined ? item.year : ''
        }</br>`;
      }),
    },

    tooltip: {
      headerFormat: '',
      pointFormatter: function() {
        return formatPrice(this.y);
      },
      shape: 'square',
      style: {
        color: '#fff',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 'normal',
        fontSize: '16px',
      },
      hideDelay: 0,
    },

    series: [
      {
        data: recordData.map(item => item.totalReleasedPerMonth),
        pointPadding: 0,
      },
    ],
  };
  const quantityLaunchedItems = {
    chart: {
      type: 'column',
      spacingLeft: 0,
      scrollablePlotArea: {
        minWidth: 1024,
        scrollPositionX: 1,
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
    },

    xAxis: {
      className: 'highcharts-color-0',
      categories: recordData.map(item => {
        return `<b style="color: #131313; font-size: 16px; font-weight: normal; font-family: Roboto, sans-serif;">${
          item !== undefined ? item.monthName : ''
        }  </b><br><b style="color: #0f4c81; font-size: 14px; font-weight: 600; font-family: Roboto, sans-serif;">${
          item !== undefined ? item.year : ''
        }</br>`;
      }),
      crosshair: true,
    },

    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y:.f}',
      shape: 'square',
      style: {
        color: '#fff',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '500',
        fontSize: '16px',
      },
      hideDelay: 0,
    },
    series: [
      {
        data: recordData.map(item => item.quantityLaunchedItems),
        pointPadding: 0,
      },
    ],
  };
  const averageValuePerLaunchedItem = {
    chart: {
      type: 'column',
      spacingLeft: 0,
      scrollablePlotArea: {
        minWidth: 1024,
        scrollPositionX: 1,
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
    },

    xAxis: {
      className: 'highcharts-color-0',
      categories: recordData.map(item => {
        return `<b style="color: #131313; font-size: 16px; font-weight: normal; font-family: Roboto, sans-serif;">${
          item !== undefined ? item.monthName : ''
        }  </b><br><b style="color: #0f4c81; font-size: 14px; font-weight: 600; font-family: Roboto, sans-serif;">${
          item !== undefined ? item.year : ''
        }</br>`;
      }),
      crosshair: true,
    },

    tooltip: {
      headerFormat: '',
      pointFormatter: function() {
        return formatPrice(this.y);
      },
      shape: 'square',
      style: {
        color: '#fff',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '500',
        fontSize: '16px',
      },
      hideDelay: 0,
    },

    series: [
      {
        data: recordData.map(item => item.averageValuePerLaunchedItem),
        pointPadding: 0,
      },
    ],
  };

  const fetchOverView = async value => {
    if (!value) {
      const date = new Date();
      date.setDate(date.getDate() - 60);
      const init = format(date, 'dd-MM-yyyy HH:mm:ss');
      const term = format(new Date(), 'dd-MM-yyyy HH:mm:ss');
      setLoading(true);
      try {
        const { data } = await api.get('/dashboard/findAnalysisOverview', {
          params: {
            dateInit: init,
            dateTerm: term,
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setOverView(data);
      } catch (error) {
        errorHandler(error);
      }
      setLoading(false);
    } else {
      setLoading(true);
      const initial = format(parseISO(value.initial), 'dd-MM-yyyy HH:mm:ss');
      const final = format(parseISO(value.final), 'dd-MM-yyyy HH:mm:ss');
      try {
        const { data } = await api.get('/dashboard/findAnalysisOverview', {
          params: {
            dateInit: initial,
            dateTerm: final,
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setOverView(data);
      } catch (error) {
        errorHandler(error);
      }
      setLoading(false);
    }
  };

  const disabledDate = current => {
    const tooLate = startDate && current.diff(startDate, 'days') > 60;
    const beforeStart = current && current.valueOf() < startDate;
    const tooEarly = endDate && endDate.diff(current, 'days') > 60;
    const afterEnd = current && current.valueOf() > endDate;
    const future = current && current.valueOf() > Date.now();
    return tooEarly || tooLate || beforeStart || afterEnd || future;
  };

  useEffect(() => {
    fetchOverView();
    fetchRecords();
  }, []);

  return (
    <DefaulLayout>
      <Box>
        <PageTitle title="RDV > Dashboard" />
        <Formik initialValues={{}} onSubmit={fetchOverView}>
          {({ errors }) => (
            <Form>
              <Row>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} style={{ marginTop: '8px' }}>
                  <span
                    style={{
                      color: '#0f4c81',
                      fontWeight: '600',
                      fontSize: '18px',
                      lineHeight: '32px',
                    }}
                  >
                    Visão geral de análise
                  </span>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                  <FormControl label={t('screens:dateSearch.of')} field="initial">
                    <DatePicker
                      disabledDate={value => disabledDate(value)}
                      format="DD/MM/Y"
                      name="initial"
                      placeholder={t('messages:select')}
                      onChange={value => {
                        setStartDate(value);
                      }}
                      locale={locale}
                    />
                  </FormControl>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                  <FormControl label={t('screens:dateSearch.to')} field="final">
                    <DatePicker
                      disabledDate={value => disabledDate(value)}
                      format="DD/MM/Y"
                      name="final"
                      placeholder={t('messages:select')}
                      onChange={value => {
                        setEndDate(value);
                      }}
                      locale={locale}
                    />
                  </FormControl>
                </Col>
                <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                  <FormActions>
                    <Button style={{ marginTop: '8px' }} type="submit" color="primary">
                      Filtrar
                    </Button>
                  </FormActions>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Row>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Card description="Quantidade de itens lançados" value={overView.quantityLaunchedItems} loading={loading} />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Card description="Quantidade de RDVs lançadas" value={overView.quantityLaunchedRdv} loading={loading} />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Card
              description="Valor médio por RDV lançado"
              value={overView.averageValuePerLaunchedRdv && formatPrice(overView.averageValuePerLaunchedRdv)}
              loading={loading}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Card
              description="Valor total lançado no mês anterior"
              value={
                overView.totalAmountLaunchedPreviousMonth && formatPrice(overView.totalAmountLaunchedPreviousMonth)
              }
              loading={loading}
            />
          </Col>
        </Row>
      </Box>
      <div style={{ marginTop: '30px' }}>
        <PageTitle title="Gráfico analíticos dos ultimos 12 meses" />
      </div>
      <Box>
        <PageTitle title="Valor total lançado por mês" />
        <HighchartsReact highcharts={Highcharts} options={totalReleasedPerMonth} />
      </Box>
      <Box>
        <PageTitle title="Quantidade de itens lançados" />
        <HighchartsReact highcharts={Highcharts} options={quantityLaunchedItems} />
      </Box>
      <Box>
        <PageTitle title="Valor médio por item lançado" />
        <HighchartsReact highcharts={Highcharts} options={averageValuePerLaunchedItem} />
      </Box>
    </DefaulLayout>
  );
}
