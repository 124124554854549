import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spin, Tooltip } from 'antd';
import { Form, Select } from 'formik-antd';
import { Container, Footer } from './styles';
import Button from 'components/Button';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import { rememberCnpj } from '~/store/modules/auth/actions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import { useTranslation } from 'react-i18next';
import { store } from '~/store';

const initialValues = {cnpj: ''};

export default function ModalCnpj({ visible, onClose }) {
  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cnpjCards, setCnpjCards] = useState([]);
  const [recordData, setRecordData] = useState(initialValues);
  const dispatch = useDispatch();

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/cnpjCards/findAll', {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setCnpjCards(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    const registrationNumber = values.cnpj;

    if (registrationNumber && registrationNumber !== '') {
      try {
        const { data } = await api.get('/cnpjCards/findByRegistrationNumber', { 
          params: { registrationNumber: registrationNumber }
        });

        if (data !== "") {
          dispatch(rememberCnpj(registrationNumber));
          onClose();
        }
      } catch(error) {
        setErrors(errorHandler(error));
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if(visible) {
      fetchRecords();
      const { cnpj } = store.getState().auth;
      setRecordData({cnpj: cnpj});
    }
  }, [visible]);

  const schema = Yup.object().shape({
    cnpj: Yup.string().required(),
  });

  return (
    <Formik 
      initialValues={recordData} 
      enableReinitialize 
      onSubmit={handleSave}
      validationSchema={schema}>
      
      {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
        <Container 
          closable={false}
          title="Selecione o CNPJ de uma empresa"
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          footer={
            <Footer>
              <Button onClick={submitForm} loading={loading || isSubmitting} color="primary">
                {'Continuar'}
              </Button>
            </Footer>
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.cnpj && errors.cnpj}
                  field="cnpj"
                  label={t('screens:cnpjCards.business')}
                  required
                >

                  <Select name="cnpj"
                    defaultValue={recordData.cnpj}
                    style={{ width: 475 }}
                    placeholder="Selecione um cnpj/empresa"
                    onChange={value => {
                      recordData.cnpj = value;
                    }}
                  >
                    {cnpjCards.map(item => {
                      return (
                        <Option id={item.id} key={item.id} value={item.registrationNumber}>
                          <Tooltip placement="topLeft" title={item.businessName}>
                            <span>[ {item.registrationNumber} ] {item.businessName}</span>
                          </Tooltip>
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Container>
      )}
    </Formik>
  );
}
