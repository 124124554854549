import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Modal, message, Spin } from 'antd';

import api from '~/services/api';

import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import errorHandler from '../../Utils/errorHandler';

const initialValues = {
  code: '',
  name: '',
  deleted: false,
};

export default function TaskForm({ visible, onClose, id }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState(initialValues);

  const handleSave = async values => {
    setLoading(true);
    try {
      if (!values.id) {
        const data = {
          code: values.code,
          name: values.name,
          deleted: false,
        };
        await api.post('/tasks/create', data);
         onClose();
         message.success(t('messages:taskSuccess'));
      } else {
        const { id, code, name, deleted } = values;
        const data = [{ id, code, name, deleted }];
        await api.put('/tasks/update', data[0]);
        onClose()
        message.success(t('messages:taskSuccessEdit'));
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRecords = async () => {
    setLoading(true)
    try {
      if (!id) {
        setRecordData(initialValues)

      } else {
        const { data } = await api.get('/tasks/findById', {
          params: { id },
          headers: { 'Content-type': 'multipart/form-data' },
        });

        setRecordData(data)
      }
    } catch (error) {
      errorHandler(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible])

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave}>
      {({ errors, isSubmitting, submitForm, resetForm }) => (
        <Modal
          title={recordData.id ? `${t('screens:task.data.editing')} ${recordData.name}` : t('screens:task.data.newTask')}
          onCancel={onClose}
          afterClose={resetForm}
          loading={loading || isSubmitting}
          visible={visible}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading}>
            <Form>
              <Row>
                <FormControl cols={{ xs: 24 }} field="code" label={t('screens:task.data.code')}>
                  <Input name="code" autoComplete="off" />
                </FormControl>
                <FormControl cols={{ xs: 24 }} field="name" label={t('screens:task.data.name')}>
                  <Input name="name" autoComplete="off" />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
