import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Popconfirm, message, Spin, Col } from 'antd';
import { Form, Select } from 'formik-antd';
import { FaPlus, FaRegTrashAlt, FaPencilAlt, FaSearch, FaMoneyBillWave } from 'react-icons/fa';
import { Formik } from 'formik';

import DefautLayout from '~/pages/_layouts/full';
import UserForm from './form';
import DailyRatesForm from './DailyRates';
import { useSelector } from 'react-redux';
import errorHandler from '~/Utils/errorHandler';

import api from '~/services/api';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { Table, TableActions } from '~/components/Table';
import history from '~/services/history';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';

export default function Users() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDailyInfoForm, setShowDailyInfoForm] = useState(false);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [roles, setRoles] = useState([]);

  const { profile } = useSelector(state => state.user);

  const { Option } = Select;

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/findAll');
      setTableData(data);
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/findAll');
      data.splice(0, 0, { id: 0, name: 'Todos' });
      setUsers(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/roles/findAll');
      data.splice(0, 0, { id: 0, description: 'Todos' });
      setRoles(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = async id => {
    history.push(`/usuarios/${id}`);
  };

  const handleDelete = async id => {
    setLoading(true);

    try {
      await api.delete(`/users/delete/${id}`);
      message.success(t('messages:success'));
      fetchRecords();
    } catch (error) {
      if (error.response.data.errors[0].sqlState === 'foreignKeyViolation') {
        message.warning(
          'Este usuário não pode ser excluído pois está vinculado à um RDV ou lançamento, só é possível desativá-lo'
        );
      } else {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const handleSearch = values => {
    setLoading(true);
    var table = recordData;
    if (values.name === '0') {
      values.name = 'Todos';
    }
    if (values.role === '0') {
      values.role = 'Todos';
    }
    if (values.status === 'Todos') {
      values.status = 'ALL';
    }

    try {
      if (values.name !== undefined && values.name !== null) {
        if (values.name !== 'Todos') {
          table = table.filter(data => {
            return data.name.toString() === values.name;
          });
        }
      }
      if (values.role !== undefined && values.role !== null) {
        if (values.role !== 'Todos') {
          table = table.filter(data => {
            return data.roles[0].id.toString() === values.role;
          });
        }
      }
      if (values.status !== undefined && values.status !== null) {
        if (values.status !== 'ALL') {
          table = table.filter(data => {
            return data.status === values.status;
          });
        }
      }
      setTableData(table);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords();
    fetchRoles();
    fetchUsers();
  }, [showForm]);

  const status = [
    {
      id: 0,
      code: 'ALL',
      description: t('screens:status.all'),
    },
    {
      id: 1,
      code: 'ACTIVE',
      description: t('screens:status.active'),
    },
    {
      id: 2,
      code: 'INACTIVE',
      description: t('screens:status.inactive'),
    },
  ];

  const tableColumns = [
    {
      title: t('screens:users.data.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:users.data.role'),
      dataIndex: 'roles[0].description',
      key: 'roles[0].description',
    },
    {
      title: t('screens:users.data.status'),
      dataIndex: 'status',
      key: 'status',
      render: status =>
        status === 'ACTIVE' ? (
          <Tag color="green">{t('screens:users.data.active')}</Tag>
        ) : (
          <Tag color="red">{t('screens:users.data.inactive')}</Tag>
        ),
    },
    {
      title: t('screens:users.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button onClick={() => handleEdit(record.id)} title={t('messages:edit')}>
            <FaPencilAlt color={'green'} />
          </Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <FaRegTrashAlt color={'red'} />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  return (
    <DefautLayout>
      <Box>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ float: 'left' }}>
            <PageTitle title={`RDV > ${t('screens:users.title')}`} style={{ marginRight: 'auto', width: '100%' }} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ marginLeft: 'auto', display: 'flex' }}>
            {profile.roles[0].name === 'ROLE_ADMIN' && (
              <Button
                loading={loading}
                color="primary"
                onClick={() => setShowDailyInfoForm(true)}
                style={{ marginLeft: 'auto' }}
              >
                <FaMoneyBillWave />
                {t('screens:users.btnDailyInfo')}
              </Button>
            )}
            <Button loading={loading} color="primary" onClick={() => setShowForm(true)} style={{ marginLeft: 10 }}>
              <FaPlus />
              {t('screens:users.btnNew')}
            </Button>
          </Col>
        </Row>
        <Formik
          initialValues={{ name: 'Todos', role: 'Todos', status: 'Todos' }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ errors, submitForm, resetForm }) => (
            <Spin spinning={loading}>
              <Form style={{ marginTop: '15px' }}>
                <Row>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl error={errors.name} field="name" label={t('screens:users.data.name')}>
                      <Select showSearch name="name">
                        {users.map(item => {
                          return (
                            <Option key={item.id} value={item.name}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl error={errors.role} field="role" label={t('screens:users.data.role')}>
                      <Select name="role">
                        {roles.map(item => {
                          return (
                            <Option key={item.id} value={item.id.toString()}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl error={errors.status} field="status" label={t('screens:users.data.status')}>
                      <Select name="status">
                        {status.map(item => {
                          return (
                            <Option key={item.id} value={item.code}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}>
                    <FaSearch style={{ width: '18px' }} />
                    {t('screens:dateSearch.button')}
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      fetchRecords();
                      fetchRoles();
                    }}
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 25 }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />
        <UserForm visible={showForm} onClose={() => setShowForm(false)} />
        <DailyRatesForm visible={showDailyInfoForm} onClose={() => setShowDailyInfoForm(false)} />
      </Box>
    </DefautLayout>
  );
}
