import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Spin, Popconfirm, message } from 'antd';
import { Form, Select } from 'formik-antd';
import { FaPencilAlt, FaPlus, FaSearch, FaRegTrashAlt } from 'react-icons/fa';

import TaskForm from './form';
import DefaultLayout from '~/pages/_layouts/full';
import api from '~/services/api';

import Box from '~/components/Box';
import Row from '~/components/Row';
import PageTitle from '~/components/PageTitle';
import { Table, TableActions } from '~/components/Table';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import errorHandler from '../../Utils/errorHandler';

export default function Task() {
  const { t } = useTranslation();
  const { Option } = Select;

  const [showForm, setShowForm] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [selectTaskCode, setSelectTaskCode] = useState([]);
  const [selectTaskName, setSelectTaskName] = useState([]);
  const [loading, setLoading] = useState(false);

  let selectTaskFilter;

  const fetchRecord = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/tasks/findAll');
      setDataTable(data);
      selectTaskFilter = [...data];

      selectTaskFilter.splice(0, 0, {
        id: 0,
        code: 'Todos',
        name: 'Todos',
        deleted: false,
      });
      setSelectTaskCode(selectTaskFilter);
      setSelectTaskName(selectTaskFilter);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/tasks/delete/${id}`);
      fetchRecord();
      message.success(t('messages:taskSuccessDeleted'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = async id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleSearch = async values => {
    setLoading(true);

    try {
      if (values.name === 'Todos' && values.code === 'Todos') {
        fetchRecord();
      }

      let filter = { id: 0, code: '', name: '', taskId: 0, itemId: 0 };

      if (values.code !== 'Todos' && values.code !== null) {
        filter.code = values.code;
      }

      if (values.name !== 'Todos' && values.name !== null) {
        filter.name = values.name;
      }

      const { data } = await api.get('/tasks/findByFilter', {
        params: filter,
        headers: { 'Content-Type': 'multpart/form-data' },
      });
      setDataTable(data);
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  const handleTaskName = async event => {
    setLoading(true);
    try {
      const { data } = await api.get('/tasks/findAll');
      selectTaskFilter = [...data];

      if (event === 'Todos') {
        selectTaskFilter.splice(0, 0, {
          id: 0,
          code: 'Todos',
          name: 'Todos',
          deleted: false,
        });

        setSelectTaskName(selectTaskFilter);
      } else {
        const task = selectTaskName.filter(item => item.code === event);
        setSelectTaskName(task);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleTaskCode = async event => {
    const { data } = await api.get('/tasks/findAll');
    selectTaskFilter = [...data];

    if (event === 'Todos') {
      selectTaskFilter.splice(0, 0, {
        id: 0,
        code: 'Todos',
        name: 'Todos',
        deleted: false,
      });

      setSelectTaskCode(selectTaskFilter);
    } else {
      const task = selectTaskFilter.filter(item => item.name === event);
      setSelectTaskCode(task);
    }
  };

  useEffect(() => {
    fetchRecord();
    setSelectedRecord(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  const tableColumns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      width: '20%',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
            <FaPencilAlt color={'green'} />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            okText={t('messages:yes')}
            onConfirm={() => handleDelete(record.id)}
            cancelText={t('messages:no')}
          >
            <Button>
              <FaRegTrashAlt color={'red'} />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <Box>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ float: 'left' }}>
            <PageTitle
              title={'RDV > Cadastro > ' + t('screens:task.title')}
              style={{ marginRight: 'auto', width: '100%' }}
            />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{ marginLeft: 'auto' }}>
            <Button
              style={{ marginLeft: 'auto' }}
              color="primary"
              onClick={() => {
                setShowForm(true);
                setSelectedRecord(null);
              }}
            >
              <FaPlus />
              {t('screens:task.newBtn')}
            </Button>
          </Col>
        </Row>
        <Formik initialValues={{ code: 'Todos', name: 'Todos' }} enableReinitialize onSubmit={handleSearch}>
          {({ resetForm, isSubmitting }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <Row>
                  <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    <FormControl field="code" label={t('screens:task.data.code')}>
                      <Select name="code" onChange={event => handleTaskName(event)}>
                        {selectTaskCode.map(task => {
                          return (
                            <Option key={task.id} value={task.code}>
                              {task.code}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <FormControl field="name" label={t('screens:task.data.name')}>
                      <Select name="name" onChange={event => handleTaskCode(event)}>
                        {selectTaskName.map(task => {
                          return (
                            <Option key={task.id} value={task.name}>
                              {task.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}>
                    <FaSearch style={{ width: 18 }} />
                    {t('screens:task.data.search')}
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      fetchRecord();
                    }}
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 25 }}
                  >
                    {t('screens:task.data:clear')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <Table columns={tableColumns} rowKey="id" dataSource={dataTable} loading={loading} />
        <TaskForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
      </Box>
    </DefaultLayout>
  );
}
