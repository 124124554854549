import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';

const initialValues = {
  description: '',
};

export default function OkForm({ visible, onClose, id, selectedEntries, value}) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const ownerId = useSelector(state => (state.user.profile ? state.user.profile.id : null));

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (!values.id) {
        values.user = selectedEntries[0].user;

        const { data } = await api.get('/users/findById', {
          params: { id: ownerId },
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        values.owner = data;
        values.deleted = false;
        values.closedDate = format(new Date(), 'dd-MM-yyyy HH:mm:ss');

        // eslint-disable-next-line
        await selectedEntries.map(item => {
          item.expenseStatus = 'PAID';
          item.approvalFinancial = values.owner;
          delete item.rdv;
          delete item.checked;
        });

        //await selectedEntries.map(async item => await api.put('/expenses/update', item));

        const rdvWithEntries = { rdv: values, expenses: selectedEntries };

        await api.post('/rdvs/createByDTO', rdvWithEntries);
      }

      message.success(t('messages:reviewSuccess'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };
  const fetchRecords = async () => {
    setLoading(true);
    try {
      if (!id) {
        setRecordData(initialValues);
      } else {
        const { data } = await api.get('/rdvs/findById', {
          params: { id },
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setRecordData(data);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const schema = Yup.object().shape({
    description: Yup.string().required(),
  });

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={schema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')} pagamento` : t('screens:financial.paymentDescription')}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.description && errors.description}
                  field="description"
                  label={t('screens:financial.description')}
                  required
                >
                  <Input name="description" />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
