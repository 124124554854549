import React, { useState, useEffect } from 'react';
import { Spin, Col, Upload, Icon, message, List, Empty, Tooltip } from 'antd';
import { Select, Form, DatePicker, Input, Checkbox } from 'formik-antd';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaRegTrashAlt } from 'react-icons/fa';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import StringMask from 'string-mask';

import DefaultLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';

import { Help } from './styles.js';

import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import PageTitle from '~/components/PageTitle';
import FormActions from '~/components/Form/FormActions';
import InputCurrency from '~/components/Form/InputCurrency';
import history from '~/services/history';
import { dynamicKm, dynamicCost } from '~/Utils';

export default function EntriesForm(props) {
  const { t } = useTranslation();
  const noData = <Empty description={t('screens:noListData')} />;
  const expense = {
    type: '',
    expenseType: '',
    date: '',
    releaseDate: '',
    cost: '',
    expenseStatus: 'PENDING',
    deleted: false,
    observation: null,
    user: {},
    costCenter: {},
    item: {},
    approvalSupervisor: null,
    approvalFinancial: null,
    rdv: null,
    cnpjCard: null,
    expenseObservations: [],
    expenseReceipts: [],
  };

  const fields = {
    initial_km: true,
    final_km: true,
    license_plate: true,
    persons: true,
  };

  const { profile } = useSelector(state => state.user);

  const [loading, setLoading] = useState(false);
  const [cnpjCards, setCnpjCards] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [itens, setItens] = useState([]);
  const [showForm, setShowForm] = useState('none');
  const [showPerson, setShowPerson] = useState('none');
  const [uploadList, setUploadList] = useState([]);
  const [disableTask, setDisableTask] = useState(true);
  const [disableItem, setDisableItem] = useState(true);
  const [showFields, setShowFields] = useState(fields);
  // eslint-disable-next-line
  const [showList, setShowList] = useState('none');
  const [finalKM, setFinalKM] = useState(false);
  const cnpj = useSelector(state => state.auth.cnpj);

  const fetchCnpjCards = async () => {
    const { data } = await api.get('/cnpjCards/findAll', { headers: { 'Content-Type': 'multipart/form-data' } });
    setCnpjCards(data);
  };

  const fetchCostCenters = async () => {
    const { data } = await api.get('/costCenters/findByStatus', { params: { status: 'ACTIVE' } });
    setCostCenterOptions(data);
  };

  const fetchTasks = async () => {
    const { data } = await api.get('/tasks/findAll');
    setTasks(data);
  };

  const fetchRecord = async () => {
    setLoading(true);
    try {
      await fetchCnpjCards();
      await fetchCostCenters();
      await fetchTasks();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleTask = () => {
    setDisableTask(false);
  };

  const handleRefundValue = (final_km, initial_km, setValues, values) => {
    if (values.item === 41) {
      if (parseFloat(final_km) > parseFloat(initial_km)) {
        const cost = (final_km - initial_km) * profile.valuePaidMileage;
        setValues({ ...values, initial_km: initial_km, final_km: final_km, cost: cost.toFixed(2) });
      } else {
        setValues({ ...values, initial_km: initial_km, final_km: final_km, cost: '' });
      }
    }
  };

  const handleItem = async value => {
    const { data } = await api.get('/items/findByTaskId', {
      params: { taskId: value },
    });

    setItens(data);
    setDisableItem(false);
  };

  const handleShowForm = value => {
    if (value === 41) {
      setFinalKM(true);
    }

    setShowForm('block');
    const item = itens.filter(item => item.id === value);

    switch (item[0].code) {
      case 'FUEL':
        setShowFields({
          initial_km: 'none',
          final_km: 'block',
          license_plate: 'block',
          persons: 'none',
          amount_meals: 'none',
          refund: 'none',
          feed_type: 'none',
          expense_type: 'FUEL',
          disabled: false,
        });

        break;
      case 'FEED':
        setShowFields({
          initial_km: 'none',
          final_km: 'none',
          license_plate: 'none',
          persons: 'block',
          amount_meals: 'block',
          refund: 'block',
          feed_type: 'block',
          expense_type: 'FEED',
          disabled: false,
        });

        break;
      case 'MILEAGE_REIMBURSEMENT':
        setShowFields({
          initial_km: 'block',
          final_km: 'block',
          license_plate: 'block',
          persons: 'none',
          amount_meals: 'none',
          refund: 'none',
          feed_type: 'none',
          expense_type: 'MILEAGE_REIMBURSEMENT',
          disabled: true,
        });

        break;
      default:
        setShowFields({
          initial_km: 'none',
          final_km: 'none',
          license_plate: 'none',
          persons: 'none',
          amount_meals: 'none',
          refund: 'none',
          feed_type: 'none',
          disabled: false,
        });
        break;
    }
  };

  const handleShowPerson = event => {
    if (parseInt(event) > 1) {
      setShowPerson('block');
    } else {
      setShowPerson('none');
    }
  };

  const handleUpload = event => {
    // eslint-disable-next-line
    event.fileList.map(file => {
      var extension = '';
      var name = '';
      if (file.name.substring(file.name.length - 4) === 'jpeg') {
        extension = file.name.substring(file.name.length - 5);
        name = file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 4);
      } else {
        extension = file.name.substring(file.name.length - 4);
        name = file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 3);
      }
      file.name = name + extension;
    });
    setUploadList(event.fileList);
    setShowList('block');
  };

  const handleSave = async (values, { setErrors }) => {
      if (uploadList.length < 1) {
        message.error('Você deve anexar pelo menos um comprovante!');
      } else {
        try {
          let files = new FormData();
          uploadList.map(file => files.append('files', file.originFileObj, file.name));

          const { data } = await api.post('/uploadMultipleReceipts', files, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          const item = itens.filter(item => item.id === values.item);
          const costCenter = costCenterOptions.filter(costCenter => costCenter.id === values.costCenter);
          const cnpjCard = cnpjCards.filter(cnpjcard => cnpjcard.registrationNumber === values.cnpjCard);

          switch (item[0].code) {
            case 'GENERIC':
              expense.type = 'expense';
              expense.expenseType = item[0].code;
              break;
            case 'FUEL':
              expense.type = 'fuel';
              expense.expenseType = item[0].code;
              expense.finalKm = parseFloat(values.final_km);
              expense.licensePlate = values.license_plate;
              break;
            case 'FEED':
              expense.type = 'feed';
              expense.expenseType = item[0].code;
              expense.personQuantity = values.persons ? parseInt(values.persons) : 1;
              expense.personNames = values.persons_name ? values.persons_name : profile.name;
              expense.refund = values.refund;
              expense.feedType = values.feed_type;
              expense.mealsQuantity = values.amount_meals ? parseInt(values.amount_meals) : 1;
              break;
            case 'MILEAGE_REIMBURSEMENT':
              expense.type = 'mileagereimbursement';
              expense.expenseType = item[0].code;
              expense.initialKm = parseFloat(values.initial_km);
              expense.finalKm = parseFloat(values.final_km);
              expense.licensePlate = values.license_plate;
              break;
            default:
              break;
          }

          expense.date = format(parseISO(values.entry_at), 'dd-MM-yyyy HH:mm:ss');
          expense.releaseDate = format(new Date(), 'dd-MM-yyyy HH:mm:ss');
          expense.cost = values.cost;
          expense.observation = values.notes ? values.notes : null;
          expense.user = profile;
          expense.item = item[0];
          expense.costCenter = costCenter[0];
          expense.cnpjCard = cnpjCard[0];
          expense.expenseReceipts = data;

          await api.post('/expenses/create', expense);

          history.push('/lancamentos');
          message.success(t('messages:expenseSuccess'));
        } catch (error) {
          errorHandler(error);
        }
      }
  };

  const beforeUpload = file => {
    const isValid =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
      setTimeout(function () {
        handleRemoveUploadListItem(file.uid);
      }, 100);
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('O arquivo deve ter menos de 10MB!');
      setTimeout(function () {
        handleRemoveUploadListItem(file.uid);
      }, 100);
    }
    return isValid && isLt2M;
  };

  const handleRemoveUploadListItem = uid => {
    const data = uploadList.filter(item => item.uid !== uid);
    setUploadList(data);
    setShowList('none');
  };

  useEffect(() => {
    fetchRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpj]);

  useEffect(() => { }, [uploadList]);

  const feedType = [
    { type: 'BREAKFAST', name: 'Café da manhã' },
    { type: 'LUNCH', name: 'Almoço' },
    { type: 'DINNER', name: 'Janta' },
  ];

  const DELIMITER = '.';
  const MASK = '000';

  function removeTrailingCharIfFound(str, char) {
    return str
      .split(char)
      .filter(segment => segment !== '')
      .join(char);
  }

  function formatValue(str) {
    const unmaskedValue = str.split(DELIMITER).join();

    const formatted = StringMask.process(unmaskedValue, MASK);

    return removeTrailingCharIfFound(formatted.result, DELIMITER);
  }

  const actualDate = new Date();
  actualDate.setDate(actualDate.getDate() + 1);

  let schema = Yup.object().shape({
    cost: Yup.number().required(),
    entry_at: Yup.date()
      .typeError('Selecionar uma data')
      .required()
      .max(actualDate),
    initial_km: Yup.number().when('item', {
      is: value => value === 41,
      then: Yup.number()
        .typeError('O inserir a quilomentragem inicial do veículo')
        .required(),
    }),
    final_km: Yup.number().when('item', {
      is: value => value === 1 || value === 41 || value === 46,
      then: Yup.number()
        .typeError('O inserir a quilomentragem final do veículo')
        .required(),
    }),
    license_plate: Yup.string().when('item', {
      is: value => value === 1 || value === 41 || value === 46,
      then: Yup.string()
        .typeError('O inserir a placa do veículo')
        .required(),
    }),
    persons_name: Yup.string().when('persons', {
      is: value => parseInt(value) > 1,
      then: Yup.string().required(),
    }),
    feed_type: Yup.string().when('item', {
      is: value => value === 2,
      then: Yup.string().min(5).required(),
    })
  });

  return (
    <DefaultLayout>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{ entry_at: '', license_plate: '', cost: '', feed_type: '', cnpjCard: cnpj }}
        enableReinitialize
        onSubmit={handleSave}
      validationSchema={schema}
      >
        {({ errors, isSubmitting, touched, setValues, values }) => (
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Box>
                <PageTitle title={t('screens:entries.title')} />
                <Row>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="cnpjCard" label={t('screens:entries.data.cnpj')} required>
                      <Select
                        name="cnpjCard"
                        placeholder={t('messages:select')}
                        disabled={true}
                      >
                        {cnpjCards.map(cnpjCard => {
                          return (
                            <Select.Option key={cnpjCard.id} value={cnpjCard.registrationNumber}>
                              <Tooltip placement="topLeft" title={cnpjCard.businessName}>
                                <span>[ {cnpjCard.registrationNumber} ] {cnpjCard.businessName}</span>
                              </Tooltip>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="costCenter" label={t('screens:entries.data.cost-center')} required>
                      <Select
                        name="costCenter"
                        onChange={handleTask}
                        placeholder={t('messages:select')}
                      >
                        {costCenterOptions.map(costCenter => {
                          return (
                            <Select.Option key={costCenter.id} value={costCenter.id}>
                              {costCenter.code} - {costCenter.clientName}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="task" label={t('screens:entries.data.task')} required>
                      <Select
                        name="task"
                        allowClear
                        filterOption={false}
                        onChange={handleItem}
                        placeholder={t('messages:select')}
                        disabled={disableTask}
                      >
                        {tasks.map(task => {
                          return (
                            <Select.Option key={task.id} value={task.id}>
                              {task.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="item" label={t('screens:entries.data.item')} required>
                      <Select
                        name="item"
                        allowClear
                        filterOption={false}
                        placeholder={t('messages:select')}
                        onChange={handleShowForm}
                        disabled={disableItem}
                      >
                        {itens.map(item => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              </Box>
              <Box showForm={showForm}>
                <Row>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormControl
                          field="entry_at"
                          label={t('screens:entries.data.date')}
                          error={touched.entry_at && errors.entry_at}
                          required
                        >
                          <DatePicker format="DD/MM/Y" name="entry_at" placeholder={t('messages:select')} />
                        </FormControl>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={showFields.expense_type === 'FEED' ? 6 : 12}
                        lg={showFields.expense_type === 'FEED' ? 6 : 12}
                        xl={showFields.expense_type === 'FEED' ? 6 : 12}
                      >
                        <FormControl
                          field="cost"
                          error={touched.cost && errors.cost}
                          label={t('screens:entries.data.cost')}
                          required
                        >
                          <InputCurrency
                            name="cost"
                            currency="R$"
                            number={false}
                            disabled={showFields.disabled}
                            style={{ textAlign: 'right' }}
                            onChange={event => {
                              setTimeout(() => {
                                setValues({ ...values, cost: dynamicCost(event.target.value) });
                              }, 100);
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ display: showFields.feed_type }}>
                        <FormControl label="Tipo da refeição" required field="feed_type" error={touched.feed_type && errors.feed_type}>
                          <Select name="feed_type">
                            {feedType.map(item => {
                              return (
                                <Select.Option key={item.type} value={item.type}>
                                  {item.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={showFields.expense_type === 'FUEL' ? 12 : 6}
                        lg={showFields.expense_type === 'FUEL' ? 12 : 6}
                        xl={showFields.expense_type === 'FUEL' ? 12 : 6}
                        style={{ display: showFields.initial_km }}
                      >
                        <FormControl
                          field="initial_km"
                          label={t('screens:entries.data.initial_km')}
                          required
                          error={touched.initial_km && errors.initial_km}
                        >
                          <Input
                            name="initial_km"
                            onChange={event => {
                              setTimeout(() => {
                                setValues({ ...values, initial_km: dynamicKm(event.target.value) });
                                setFinalKM(false);
                                if (values.final_km) {
                                  handleRefundValue(values.final_km, dynamicKm(event.target.value), setValues, values);
                                }
                              }, 100);
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={showFields.expense_type === 'FUEL' ? 12 : 6}
                        lg={showFields.expense_type === 'FUEL' ? 12 : 6}
                        xl={showFields.expense_type === 'FUEL' ? 12 : 6}
                        style={{ display: showFields.final_km }}
                      >
                        <FormControl
                          field="final_km"
                          label={t('screens:entries.data.final_km')}
                          error={errors.final_km}
                          required
                        >
                          <Input
                            name="final_km"
                            disabled={finalKM}
                            onChange={event => {
                              setTimeout(() => {
                                setValues({ ...values, final_km: dynamicKm(event.target.value) });
                                handleRefundValue(dynamicKm(event.target.value), values.initial_km, setValues, values);
                              }, 100);
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ display: showFields.final_km }}>
                        <FormControl
                          field="license_plate"
                          label={t('screens:entries.data.license_plate')}
                          error={touched.license_plate && errors.license_plate}
                          required
                        >
                          <Input name="license_plate" />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ display: showFields.persons }}>
                        <FormControl field="persons" label={t('screens:entries.data.persons')} error={errors.persons}>
                          <Input
                            name="persons"
                            onChange={event => {
                              setValues({
                                ...values,
                                persons: formatValue(event.target.value),
                              });

                              handleShowPerson(event.target.value);
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ display: showFields.persons }}>
                        <FormControl
                          field="amount_meals"
                          label={t('screens:entries.data.amount_meals')}
                          error={errors.persons}
                        >
                          <Input name="amount_meals" />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ display: showFields.refund }}>
                        <FormControl field="refund">
                          <div style={{ paddingTop: 40 }}>
                            <Checkbox value="refund" name="refund" />
                            <span style={{ marginLeft: 10 }}>
                              {'Solicitar reembolso do valor que excede o limite diario?'}
                            </span>
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ display: showPerson }}>
                        <FormControl
                          field="persons_name"
                          required
                          error={(touched.persons_name && errors.persons_name) || errors.persons_name}
                          label={t('screens:entries.data.persons_name')}
                        >
                          <Help>Separar nomes por vírgulas*</Help>
                          <Input.TextArea name="persons_name" />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FormControl field="notes" label={t('screens:entries.data.notes')}>
                          <Input.TextArea name="notes"></Input.TextArea>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <FormControl field="payment_receipt">
                      <Upload
                        name={'file'}
                        multiple={true}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleUpload}
                        fileList={uploadList}
                      >
                        <Button color="default">
                          {'Anexar comprovante'}
                          <Icon type="upload" />
                        </Button>
                      </Upload>
                    </FormControl>

                    <List
                      dataSource={uploadList}
                      loading={false}
                      size="small"
                      locale={{ emptyText: noData }}
                      style={{
                        height: 150,
                        width: '90%',
                        overflow: 'auto',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingBottom: 30,
                      }}
                      renderItem={item => (
                        <List.Item key={item.uid}>
                          <span>{item.name}</span>
                          <FaRegTrashAlt color="danger" onClick={() => handleRemoveUploadListItem(item.uid)} />
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <FormActions>
                    <Button type="submit" color="primary">
                      Adicionar
                    </Button>
                  </FormActions>
                </Row>
              </Box>
            </Form>
          </Spin>
        )}
      </Formik>
    </DefaultLayout>
  );
}
