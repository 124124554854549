import React, { useState } from 'react';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import qs from 'qs';
import api from '~/services/api';

import BaseLayout from '~/pages/_layouts/base';
import { Container, Box } from './styles';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import errorHandler from 'Utils/errorHandler';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('campo obrigatório'),
});

export default function Remember() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleRemember = async values => {
    setLoading(true);
    try {
      var data = qs.stringify({
        grant_type: 'password',
        username: values.email + '/resetPassword',
        password: 'A6Xz4RH75y',
      });

      var client_id = 'client';
      var client_secret = '123';
      var basicAuth = 'Basic ' + btoa(client_id + ':' + client_secret);

      const response = await api.post('/oauth/token', data, { headers: { Authorization: basicAuth } });

      const data2 = {
        to: values.email,
        token: response.data.access_token,
      };

      await api.post('/mail/sendResetPassword', data2, {
        headers: { Authorization: `Bearer ${response.data.access_token}` },
      });

      message.success(t('messages:successNewPassword'));
    } catch (error) {
      const { response } = error;
      if (response.data) {
        if (response.data.error === 'invalid_grant') {
          message.error(t('messages:emailInvalid'));
        } else {
          errorHandler(error);
        }
      }
    }
    setLoading(false);
  };

  return (
    <BaseLayout>
      <Box>
        <Formik initialValues={{}} onSubmit={handleRemember} validationSchema={schema}>
          {({ errors, touched, isSubmitting }) => (
            <Container>
              <Logo title={true} margin="auto" forgetPassword={true} width='100%' height='100%' />
              <FormControl field="email" error={touched.email && errors.email}>
                <Input type="email" name="email" placeholder={t('fields:remember.email.placeholder')} />
              </FormControl>
              <Button size="large" block type="submit" loading={loading} color="primary">
                {t('fields:remember.submit')}
              </Button>
              <Link to="/">Voltar</Link>
            </Container>
          )}
        </Formik>
      </Box>
    </BaseLayout>
  );
}
