import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Modal, Spin, Col, message, Upload, Icon, Select } from 'antd';
import { Form, Input, DatePicker, Checkbox } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import axios from 'axios';
import { useSelector } from 'react-redux';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { dynamicCost } from '~/Utils';

import InputCurrency from '~/components/Form/InputCurrency';
import { ModalFooter } from '~/components/Modal';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Row from '~/components/Row';

import { Card } from './styles';

export default function EntriesEdit({ visible, id, onClose }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [deletedReceipts, setDeletedReceipts] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [fileToModal, setFileToModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const token = useSelector(state => state.auth.token);
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState(null);

  const fetchRecord = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/expenses/findById', {
        params: { id },
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      data.date = parse(data.date, 'dd-MM-yyyy HH:mm:ss', new Date());
      data.approvalDate = parse(data.approvalDate, 'dd-MM-yyyy HH:mm:ss', new Date());
      setRecordData(data);
      setReceipts(data.expenseReceipts);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDownloadAll = async () => {
    setLoading(true);
    var zip = new JSZip();
    var photoZip = zip.folder('comprovantes');
    var numberOfReceipts = receipts.length;
    var contador = 0;
    var count = 0;
    var countFileError = 0;

    // eslint-disable-next-line
    receipts.map(file => {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${file.name}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }).then(response => {
        count++;
        photoZip.file(file.originalName, response.data);
        contador++;
        if (contador >= numberOfReceipts) {
          photoZip.generateAsync({ type: 'blob' }).then(function (blob) {
            saveAs(blob, 'comprovantes.zip');
          });
          setLoading(false);
        }
      }).catch(error => {
        count++;
        if (error.response && error.response.status === 404) {
          countFileError++;
          if (numberOfReceipts === count) {
            let msg = countFileError > 1 ? t('messages:downloadReceiptsError') + " " + countFileError.toString() + " " + t('messages:receiptsNotFoundError')
            : t('messages:downloadReceiptError') + " " + countFileError.toString() + " " + t('messages:receiptNotFoundError');
            message.error(msg);
            setLoading(false);
          }
        }
      });
    });
  };

  const handleSave = async (values, { setErrors }) => {
    if (deletedReceipts.length >= 1) {
      try {
        await api.delete('/expenseReceipts/deleteAll', { data: deletedReceipts });
        setDeletedReceipts([]);
      } catch { }
    }
    if (values.expenseReceipts.length !== 0) {
      setLoading(true);
      const expense = {
        type: values.type,
        id: values.id,
        expenseType: values.expenseType,
        date: format(new Date(values.date), 'dd-MM-yyyy HH:mm:ss'),
        approvalDate: null,
        releaseDate: values.releaseDate,
        cost: values.cost,
        expenseStatus: 'PENDING',
        deleted: values.deleted,
        observation: values.observation,
        user: values.user,
        costCenter: values.costCenter,
        cnpjCard: values.cnpjCard,
        item: values.item,
        approvalSupervisor: null,
        approvalFinancial: null,
        rdv: null,
        expenseObservations: values.expenseObservations,
        expenseReceipts: recordData.expenseReceipts,
      };

      try {
        switch (values.expenseType) {
          case 'FUEL':
            expense.finalKm = parseFloat(values.finalKm);
            expense.licensePlate = values.licensePlate;
            break;
          case 'FEED':
            expense.personQuantity = values.personQuantity;
            expense.personNames = values.personNames;
            expense.refund = values.refund;
            expense.feedType = values.feedType;
            expense.mealsQuantity = parseInt(values.mealsQuantity);
            break;
          case 'MILEAGE_REIMBURSEMENT':
            expense.initialKm = parseFloat(values.initialKm);
            expense.finalKm = parseFloat(values.finalKm);
            expense.licensePlate = values.licensePlate;
            break;
          default:
            break;
        }
        await api.put('/expenses/update', expense);
        message.success(t('messages:expenseEditSuccess'));
        onClose();
      } catch (error) {
        setErrors(errorHandler(error));
      }
      setRecordData([]);
      setDeletedReceipts([]);
      setReceipts([]);
      setFileModal(false);
      setLoading(false);
    } else {
      message.error('Inserir comprovante');
    }
  };

  const feedType = [
    { type: 'BREAKFAST', name: 'Café da manhã' },
    { type: 'LUNCH', name: 'Almoço' },
    { type: 'DINNER', name: 'Janta' },
  ];

  const handleFields = (recordData, errors) => {
    switch (recordData.expenseType) {
      case 'FUEL':
        return (
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormControl error={errors.finalKm} field="finalKm" label={t('screens:entries.data.final_km')} required>
                <Input name="finalKm" />
              </FormControl>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormControl
                error={errors.licensePlate}
                field="licensePlate"
                label={t('screens:entries.data.license_plate')}
                required
              >
                <Input name="licensePlate" />
              </FormControl>
            </Col>
          </Row>
        );
      case 'MILEAGE_REIMBURSEMENT':
        return (
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <FormControl
                error={errors.initialKm}
                field="initialKm"
                label={t('screens:entries.data.initial_km')}
                required
              >
                <Input name="initialKm" />
              </FormControl>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <FormControl error={errors.finalKm} field="finalKm" label={t('screens:entries.data.final_km')} required>
                <Input name="finalKm" />
              </FormControl>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <FormControl
                error={errors.licensePlate}
                field="licensePlate"
                label={t('screens:entries.data.license_plate')}
                required
              >
                <Input name="licensePlate" />
              </FormControl>
            </Col>
          </Row>
        );
      case 'FEED':
        return (
          <>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormControl label="Tipo da refeição" field="feed_type">
                  <Select
                    name="feed_type"
                    defaultValue={recordData.feedType}
                    onChange={value => {
                      recordData.feedType = value;
                    }}
                  >
                    {feedType.map(item => {
                      return (
                        <Select.Option key={item.type} value={item.type}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormControl
                  error={errors.mealsQuantity}
                  field="mealsQuantity"
                  label={t('screens:entries.data.amount_meals')}
                  required
                >
                  <Input name="mealsQuantity"/>
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormControl
                  error={errors.personQuantity}
                  field="personQuantity"
                  label={t('screens:entries.data.persons')}
                  required
                >
                  <Input name="personQuantity" />
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormControl
                  error={errors.persons_name}
                  field="personNames"
                  label={t('screens:entries.data.persons_name')}
                  required
                >
                  <Input.TextArea name="personNames" />
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormControl field="refund">
                  <div style={{ paddingTop: 0 }}>
                    <Checkbox value="refund" name="refund" />
                    <span style={{ marginLeft: 10 }}>{'Solicitar reembolso do valor que excede o limite diário?'}</span>
                  </div>
                </FormControl>
              </Col>
            </Row>
          </>
        );
      default:
        return;
    }
  };

  const handleDownload = async file => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${file.name}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.originalName);
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      if (error.response && error.response.status === 404) {
          message.error(t('messages:downloadReceiptError') + " O " + t('messages:receiptNotFoundError'));
      }
    });
  };

  const handleDelete = async record => {
    setLoading(true);
    try {
      if (recordData.expenseReceipts.length <= 1) {
        message.error(t('messages:receiptRequiredError'));
      } else {
        document.getElementById('image' + record.id).style.background = '#000';
        document.getElementById('image' + record.id).style.opacity = '0.1';
        document.getElementById('download' + record.id).style.display = 'none';
        document.getElementById('delete' + record.id).style.display = 'none';
        const receipts = deletedReceipts;
        receipts.push(record);
        setDeletedReceipts(receipts);
        const expenses = recordData.expenseReceipts.filter(item => item.id !== record.id);
        const data = recordData;
        data.expenseReceipts = expenses;
        setRecordData(data);
      }
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  const handleUpload = async event => {
    setLoading(true);
    try {
      let file = new FormData();
      file.append('files', event);
      const { data } = await api.post('/uploadMultipleReceipts', file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const expenses = recordData;
      expenses.expenseReceipts.push(data[0]);
      setRecordData(expenses);
      message.success(t('messages:receiptAttachedSuccess'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const sizeOfThings = () => {
    var windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', function () {
    sizeOfThings();
  });

  const handleCancel = async () => {
    // eslint-disable-next-line
    deletedReceipts.map(record => {
      document.getElementById('image' + record.id).style.background = 'none';
      document.getElementById('image' + record.id).style.opacity = '1';
      document.getElementById('download' + record.id).style.display = 'block';
      document.getElementById('delete' + record.id).style.display = 'block';
    });
    const { data } = await api.get('/expenses/findById', {
      params: { id },
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    data.date = parse(data.date, 'dd-MM-yyyy HH:mm:ss', new Date());
    data.approvalDate = parse(data.approvalDate, 'dd-MM-yyyy HH:mm:ss', new Date());
    await api.put('/expenses/update', data);
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecord();
      sizeOfThings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const beforeUpload = file => {
    const isValid =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('O arquivo deve ter menos de 10MB!');
    }
    return isValid && isLt2M;
  };

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave}>
      {({ errors, isSubmitting, submitForm, resetForm, values, setValues }) => (
        <Modal
          title={t('messages:edit')}
          onCancel={onClose}
          visible={visible}
          loading={loading || isSubmitting}
          afterClose={resetForm}
          footer={
            <ModalFooter
              cancelColor="default"
              onOk={submitForm}
              onCancel={() => {
                handleCancel();
                onClose();
              }}
              loading={loading || isSubmitting}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormControl error={errors.date} field="date" label={t('screens:entries.data.date')} required>
                    <DatePicker format="DD/MM/YYYY" name="date" placeholder="selecione" locale={locale} />
                  </FormControl>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormControl error={errors.cost} field="cost" label={t('screens:entries.data.cost')} required>
                    <InputCurrency
                      name="cost"
                      currency="R$"
                      number={false}
                      style={{ textAlign: 'right' }}
                      onChange={event => {
                        setTimeout(() => {
                          setValues({ ...values, cost: dynamicCost(event.target.value) });
                        }, 100);
                      }}
                    />
                  </FormControl>
                </Col>
              </Row>
              {handleFields(recordData, errors)}
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormControl error={errors.observation} field="observation" label={t('screens:entries.data.notes')}>
                    <Input.TextArea name="observation" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormControl error={errors.supervisorObservation}
                    field="supervisorObservation" label={t('screens:entries.data.supervisor_notes')}
                  >
                    <Input.TextArea name="supervisorObservation" disabled={true} />
                  </FormControl>
                </Col>
              </Row>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: window.innerWidth < 500 ? '1fr 1fr' : '1fr 1fr 1fr',
                  justifyItems: 'center',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  paddingTop: '20px',
                }}
              >
                {receipts.map(file => (
                  <Card>
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {file.contentType === 'application/pdf' ? (
                          <img src="\assets\images\file-pdf.svg" style={{ marginTop: '28px' }} alt="file-pdf" />
                        ) : (
                          <button
                            id={'image' + file.id}
                            type={'button'}
                            style={{ marginTop: '25px' }}
                            onClick={() => {
                              setFileToModal(file);
                              setFileModal(true);
                            }}
                          >
                            <img src={`${process.env.REACT_APP_BACKEND_UPLOAD_URL}/${file.name}`} alt={file.name}/>
                          </button>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ marginTop: '10px' }}>
                          <button
                            type={'button'}
                            id={'download' + file.id}
                            onClick={() => {
                              handleDownload(file);
                            }}
                            style={{
                              border: 0,
                              background: '#d6d6d6',
                              width: window.innerWidth < 500 ? '111px' : '117px',
                              height: '20px',
                            }}
                          >
                            <p style={{ color: '#6b6b6b' }}>Baixar</p>
                          </button>
                          <button
                            id={'delete' + file.id}
                            type={'button'}
                            onClick={() => {
                              handleDelete(file);
                            }}
                            style={{
                              border: 0,
                              background: '#e3401e',
                              width: window.innerWidth < 500 ? '111px' : '117px',
                              paddingTop: '2px',
                              height: '20px',
                              borderBottomLeftRadius: '5px',
                              borderBottomRightRadius: '5px',
                            }}
                          >
                            <p style={{ color: '#fff' }}>Excluir</p>
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Modal
                      onCancel={() => {
                        setFileModal(false);
                      }}
                      title={'Visualização'}
                      visible={fileModal}
                      footer={null}
                    >
                      <img
                        style={{
                          maxWidth: '460px',
                          maxHeight: '460px',
                          display: 'flex',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                        src={fileToModal ? `${process.env.REACT_APP_BACKEND_UPLOAD_URL}/${fileToModal.name}` : ''}
                        alt="fileToModal"
                      />
                    </Modal>
                  </Card>
                ))}
              </div>
              <Row>
                <Upload
                  name={'file'}
                  multiple={false}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  action={handleUpload}
                >
                  <Button style={{ marginTop: 20, marginLeft: 8 }}>
                    {'Anexar comprovante'}
                    <Icon type="upload" />
                  </Button>
                </Upload>
                <Button
                  style={{ marginTop: 20, marginLeft: 'auto' }}
                  onClick={() => {
                    handleDownloadAll();
                  }}
                >
                  {'Baixar todos os comprovantes'}
                  <Icon type="download" />
                </Button>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
