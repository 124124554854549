import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 75%;
  height: 110px;
  margin-bottom: 30px;
  img {
    width: 72px;
    height: 72px;
  }
`;
