import styled from 'styled-components';
import { Form } from 'formik-antd';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .form-control {
    margin-bottom: 0px;

    .error-label {
      display: flex;
      justify-content: start;
      width: 100%;
      color: #f55;
    }
  }

  input {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #000;
    margin: 0px;

    &::placeholder {
      color: #a1a1a1;
    }
  }

  span {
    color: #a1a1a1;
    font-size: 15px;
    margin-right: auto;
  }

  a {
    color: #a1a1a1;
    margin-bottom: 15px;
    font-size: 15px;
    margin-left: auto;

    &:hover {
      opacity: 1;
      color: #0093dd;
    }
  }

  button {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  img {
    margin: auto;
  }
`;

export const Box = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  padding: 0 60px 20px;
  margin: auto;
`;
