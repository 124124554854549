import React from 'react';

export default function StagingMessage() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        background: '#0093dd',
      }}
    >
      <h1 style={{ color: '#fff', marginTop: '20px', fontSize: '3em' }}>AMBIENTE DE HOMOLOGAÇÃO</h1>
    </div>
  );
}
