import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Modal, Spin, Col, message, Select, Radio } from 'antd';
import { Form, Input, DatePicker, Checkbox } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import * as Yup from 'yup';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { dynamicCost } from '~/Utils';
import InputCurrency from '~/components/Form/InputCurrency';
import { ModalFooter } from '~/components/Modal';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';

export default function EntriesEdit({visible, id, onClose}){
  const { t } =  useTranslation();
  const { profile } = useSelector(state => state.user);
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState('APPROVED_RESTRICTION');

  const fetchRecord = async () =>{
    setLoading(true);
      try{
        const {data} = await api.get('/expenses/findById',{
          params: {id},
          headers: {'Content-Type': 'multipart/form-data'},
        });
        data.date = parse(data.date, 'dd-MM-yyyy HH:mm:ss', new Date());
        data.approvalDate = data.approvalDate !== null ? parse(data.approvalDate, 'dd-MM-yyyy HH:mm:ss', new Date()) : null;
        setRecordData(data)

      }catch(error){
        errorHandler(error)
      }
    setLoading(false);
  }

  const handleSave = async (values) =>{
    const data = {...recordData};

    if(recordData.cost !== values.cost){
      data.cost = values.cost
    }

    if(values.supervisorObservation){
      data.approvalSupervisor = profile;
      data.supervisorObservation = values.supervisorObservation;
      data.expenseStatus = radioValue;
    }

    data.date = format(new Date(values.date), 'dd-MM-yyyy HH:mm:ss')
    data.approvalDate = values.approvalDate !== null ? format(new Date(values.approvalDate), 'dd-MM-yyyy HH:mm:ss') : null;

    setLoading(true)
      try {
        await api.put('/expenses/update', data)
        message.success(t('messages:expenseEditSuccess'));
        onClose("save")
      } catch(error) {
        errorHandler(error);
      }
    setLoading(false)
  }

  const feedType = [
    { type: 'BREAKFAST', name: 'Café da manhã' },
    { type: 'LUNCH', name: 'Almoço' },
    { type: 'DINNER', name: 'Janta' },
  ];

  const handleFields = (recordData, errors) => {
    switch (recordData.expenseType) {
      case 'FUEL':
        return (
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormControl error={errors.finalKm} field="finalKm" label={t('screens:entries.data.final_km')} required>
                <Input name="finalKm" disabled={true}/>
              </FormControl>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormControl
                error={errors.licensePlate}
                field="licensePlate"
                label={t('screens:entries.data.license_plate')}
                required
              >
                <Input name="licensePlate" disabled={true}/>
              </FormControl>
            </Col>
          </Row>
        );
      case 'MILEAGE_REIMBURSEMENT':
        return (
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <FormControl
                error={errors.initialKm}
                field="initialKm"
                label={t('screens:entries.data.initial_km')}
                required
              >
                <Input name="initialKm" disabled={true}/>
              </FormControl>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <FormControl error={errors.finalKm} field="finalKm" label={t('screens:entries.data.final_km')} required>
                <Input name="finalKm" disabled={true}/>
              </FormControl>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <FormControl
                error={errors.licensePlate}
                field="licensePlate"
                label={t('screens:entries.data.license_plate')}
                required
              >
                <Input name="licensePlate" disabled={true}/>
              </FormControl>
            </Col>
          </Row>
        );
      case 'FEED':
        return (
          <>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormControl label="Tipo da refeição" field="feed_type">
                  <Select
                    name="feed_type"
                    defaultValue={recordData.feedType}
                    onChange={value => {
                      recordData.feedType = value;
                    }}
                    disabled={true}
                  >
                    {feedType.map(item => {
                      return (
                        <Select.Option key={item.type} value={item.type}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormControl
                  field="mealsQuantity"
                  label={t('screens:entries.data.amount_meals')}
                  required
                >
                  <Input name="mealsQuantity" disabled={true}/>
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormControl
                  error={errors.personQuantity}
                  field="personQuantity"
                  label={t('screens:entries.data.persons')}
                  required
                >
                  <Input name="personQuantity" disabled={true}/>
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormControl
                  error={errors.persons_name}
                  field="personNames"
                  label={t('screens:entries.data.persons_name')}
                  required
                >
                  <Input.TextArea name="personNames" disabled={true}/>
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormControl field="refund">
                  <div style={{ paddingTop: 0 }}>
                    <Checkbox value="refund" name="refund" disabled={true}/>
                    <span style={{ marginLeft: 10 }}>{'Solicitar reembolso do valor que excede o limite diário?'}</span>
                  </div>
                </FormControl>
              </Col>
            </Row>
          </>
        );
      default:
        return;
    }
  };

  const onChangeRadio = (e) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    if (visible) {
      fetchRecord();
    }
    setRadioValue('APPROVED_RESTRICTION');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  let schema = Yup.object().shape(
    {
      cost: Yup.number().required(),
      supervisorObservation: Yup.string().when('cost',{
        is: value => value !== recordData.cost,
        then: Yup.string().typeError('Inserir o motivo do valor ter sido alterado').required(),
        otherwise: Yup.string().nullable()
      })
    }
  )

  return(
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={schema}
    >
      {({errors, isSubmitting, submitForm, resetForm, values, setValues})=>(
        <Modal
          title={t('messages:edit')}
          onCancel={onClose}
          visible={visible}
          loading={loading || isSubmitting}
          afterClose={resetForm}
          footer={
            <ModalFooter
              cancelColor="default"
              onOk={submitForm}
              onCancel={() => {
                onClose("cancel");
              }}
              loading={loading || isSubmitting}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormControl error={errors.date} field="date" label={t('screens:entries.data.date')}>
                    <DatePicker format="DD/MM/YYYY" name="date" placeholder="selecione" locale={locale} disabled={true}/>
                  </FormControl>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormControl error={errors.cost} field="cost" label={t('screens:entries.data.cost')}>
                    <InputCurrency
                      name="cost"
                      currency="R$"
                      number={false}
                      style={{textAlign: 'right'}}
                      onChange={event => {
                        setTimeout(() => {
                          setValues({ ...values, cost: dynamicCost(event.target.value) });
                        }, 100);
                      }}
                    />
                  </FormControl>
                </Col>
              </Row>
              {handleFields(recordData, errors)}
              <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormControl error={errors.observation} field="observation" label={t('screens:entries.data.notes')}>
                    <Input.TextArea name="observation" disabled={true}/>
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormControl error={errors.supervisorObservation} field="supervisorObservation" label={t('screens:entries.data.supervisor_notes')} >
                  <Input.TextArea name="supervisorObservation" />
                </FormControl>
              </Col>
            </Row>
            {values.supervisorObservation !== undefined && values.supervisorObservation !== null && values.supervisorObservation !== '' ? 
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Radio.Group onChange={onChangeRadio} value={radioValue}>
                    <Radio value={'APPROVED_RESTRICTION'}>{t('screens:entries.data.approveWithRestriction')}</Radio>
                    <Radio value={'DISAPPROVED'}>{t('screens:entries.data.reprove')}</Radio>
                  </Radio.Group>
                </Col>
              </Row> : null
            }
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  )
}