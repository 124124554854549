import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Switch, Input } from 'formik-antd';
import { Modal, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import { CodeField } from './CodeField';

const initialValues = {
  code: '',
  clientName: '',
  nickname: '',
  deleted: false,
  status: true,
};

export default function CostCenterForm({ visible, onClose, id, fetchRecords1}) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      const deleted = false;

      if (values.status === true) {
        values.status = 'ACTIVE';
      } else {
        values.status = 'INACTIVE';
      }

      if (!values.id) {
        const data = {
          code: values.code,
          clientName: values.clientName,
          nickname: values.nickname,
          status: values.status,
          deleted: deleted,
        };
        await api.post('/costCenters/create', data);
      } else {
        const { id, code, clientName, nickname, status } = values;
        const data = [{ id, code, clientName, nickname, status, deleted }];
        await api.put('/costCenters/update', data[0]);
      }

      message.success(t('messages:costCenterSuccess'));
      onClose();
      fetchRecords1();
    } catch (error) {
      const { response } = error;
      if (response.data.errors) {
        if (response.data.errors[0].sqlState === 'duplicateCost_center') {
          message.error(t('messages:duplicatedCost_center'));
        } else {
          errorHandler(error);
        }
      } else {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const fetchRecords = async () => {
    setLoading(true);
    try {
      if (!id) {
        setRecordData(initialValues);
      } else {
        const { data } = await api.get('/costCenters/findById', {
          params: { id },
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (data.status === 'ACTIVE') {
          data.status = true;
        } else {
          data.status = false;
        }
        setRecordData(data);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const schema = Yup.object().shape({
    code: Yup.string().min(7, 'Deve ter pelo menos 7 caracteres. Exemplo: 01.0001 ou 04.0004.02')
      .typeError('O código deve ser numérico')
      .required(),
    clientName: Yup.string().required(),
    nickname: Yup.string().required(),
  });

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={schema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.nickname}` : t('screens:cost-center.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.code && errors.code}
                  field="code"
                  label={t('screens:cost-center.data.code')}
                  required
                >
                  <CodeField name="code" />
                </FormControl>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.clientName && errors.clientName}
                  field="clientName"
                  label={t('screens:cost-center.data.customer')}
                  required
                >
                  <Input name="clientName" />
                </FormControl>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.nickname && errors.nickname}
                  field="nickname"
                  label={t('screens:cost-center.data.nickname')}
                  required
                >
                  <Input name="nickname" />
                </FormControl>

                <FormControl
                  cols={{ xs: 24 }}
                  field="status"
                  label={t('screens:cost-center.data.active')}
                  style={{ margin: 'auto' }}
                >
                  <Switch
                    name="status"
                    checked={recordData.status === undefined ? true : recordData.status}
                    checkedChildren={t('messages:yes')}
                    unCheckedChildren={t('messages:no')}
                    style={{ margin: 'auto' }}
                    onChange={e => {
                      recordData.status = e;
                    }}
                  ></Switch>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
