import React, { useState } from 'react';
import { Menu as AntMenu } from 'antd';
import { GoGear } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBars } from 'react-icons/fa';
import { MenuGlobalStyle, Nav } from './styles';
import { signOutRequest } from '~/store/modules/auth/actions';
import Warning from '~/pages/Warning';
import Button from '~/components/Button';
import ModalCnpj from '~/components/ModalCnpj';

export default function Menu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showWarningForm, setWarningForm] = useState(false);
  const [showCnpjForm, setCnpjForm] = useState(false);

  const roleList = useSelector(state => (state.user.profile ? state.user.profile.roles : null));
  const userName = useSelector(state => (state.user.profile ? state.user.profile.name : null));
  const userId = useSelector(state => (state.user.profile ? state.user.profile.id : null));

  const checkAuth = roles => {
    var i = 0;
    for (i = 0; i < roles.length; i++) {
      let auth = false;
      if (roleList && !roleList[0].name.includes('ROLE_ADMIN')) {
        if (roles[i] && roles[i] !== 'DEFAULT') {
          auth = roleList && roleList[0].name.includes(roles[i]);
        } else if (roles[i] === 'DEFAULT') {
          auth = true;
        } else {
          auth = false;
        }
      } else {
        auth = true;
      }
      return auth;
    }
  };

  function handleSignOut() {
    dispatch(signOutRequest());
  }

  const MenuItem = ({ role, children, ...props }) => {
    return checkAuth(role) ? <AntMenu.Item {...props}>{children}</AntMenu.Item> : null;
  };

  const SubMenu = ({ role, children, ...props }) => {
    return checkAuth(role) && <AntMenu.SubMenu {...props}>{children}</AntMenu.SubMenu>;
  };

  const renderSubMenuTitle = label => {
    return (
      <>
        <span>{label}</span>
        {/* <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" /> */}
      </>
    );
  };

  const renderMenu = item => {
    const { type } = item;
    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    } else if (type === 'sub') {
      return (
        <SubMenu key={item.key} role={item.role} title={renderSubMenuTitle(item.label)}>
          {item.children.map(subItem => renderMenu(subItem))}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem
          key={item.key}
          role={item.role}
          className={item.key === 'profile' ? 'menu-profile' : item.key === 'logout' ? 'menu-logout' : null}
        >
          {//TODO: Converter o Link com click para Button
          item.click ? (
            <Button onClick={item.click}>
              {item.label}
              {item.icon && item.icon}
            </Button>
          ) : (
            <Link to={item.url}>
              {item.label}
              {item.icon && item.icon}
            </Link>
          )}
        </MenuItem>
      );
    }
  };

  const rdvMenuItems = [
    {
      type: 'sub',
      key: 'rdv',
      role: ['DEFAULT'],
      label: <FaBars />,
      children: [
        {
          type: 'item',
          key: 'profile',
          role: ['DEFAULT'],
          label: `${userName}`,
          icon: <GoGear style={{ marginBottom: '3px', marginLeft: '5px' }} />,
          url: `/usuarios/${userId}`,
        },
        {
          type: 'item',
          key: 'approvals',
          role: ['ROLE_SUPERVISOR', 'ROLE_ENGINEERING'],
          label: t('menus:approvals'),
          url: '/aprovacoes',
        },
        {
          type: 'item',
          key: 'warnings',
          role: ['ROLE_ADMIN'],
          label: t('menus:warnings'),
          click: () => setWarningForm(true),
        },
        {
          type: 'sub',
          key: 'register',
          role: ['ROLE_ADMIN'],
          label: 'Cadastros',
          children: [
            {
              type: 'item',
              key: 'costCenters',
              role: ['ROLE_ADMIN'],
              label: t('menus:costCenters'),
              url: '/centros-de-custo',
            },
            {
              type: 'item',
              key: 'task',
              role: ['ROLE_ADMIN'],
              label: t('screens:task.title'),
              url: '/tarefas',
            },
            {
              type: 'item',
              key: 'natureBudget',
              role: 'ROLE_ADMIN',
              label: t('screens:budgetaryNature.title'),
              url: '/natureza-orcamentaria',
            },
            {
              type: 'item',
              key: 'item',
              role: ['ROLE_ADMIN'],
              label: t('screens:item.title'),
              url: '/items',
            },
          ],
        },
        {
          type: 'item',
          key: 'financial',
          role: ['ROLE_ADMIN'],
          label: t('menus:financial'),
          url: '/financeiro',
        },
        {
          type: 'item',
          key: 'releases',
          role: ['DEFAULT'],
          label: t('menus:releases'),
          url: '/lancamentos',
        },
        {
          type: 'sub',
          key: 'reviews',
          role: ['ROLE_ENGINEERING'],
          label: t('menus:reviews'),
          children: [
            {
              type: 'item',
              key: 'paymentReview',
              role: ['ROLE_ENGINEERING'],
              label: t('menus:paymentReview'),
              url: '/relatorio-pagamentos',
            },
            {
              type: 'item',
              key: 'releaseReview',
              role: ['ROLE_ENGINEERING'],
              label: t('menus:releaseReview'),
              url: '/relatorio-lancamentos',
            },
          ],
        },
        {
          type: 'item',
          key: 'users',
          role: ['ROLE_ADMIN'],
          label: t('menus:users'),
          url: '/usuarios',
        },
        {
          type: 'item',
          key: 'cnpj',
          role: ['DEFAULT'],
          label: t('menus:cnpj'),
          click: () => setCnpjForm(true),
        },
        {
          type: 'divider',
          key: 'divider',
        },
        {
          type: 'item',
          key: 'logout',
          role: ['DEFAULT'],
          label: t('menus:user_logout'),
          click: handleSignOut,
        },
      ],
    },
  ];

  return (
    <>
      <MenuGlobalStyle />
      <Nav>
        <AntMenu mode="horizontal" defaultSelectedKeys={['cadastros']} style={{ marginLeft: 'auto' }}>
          {rdvMenuItems.map(item => renderMenu(item))}
        </AntMenu>
      </Nav>
      <ModalCnpj visible={showCnpjForm} onClose={() => setCnpjForm(false)} />
      <Warning visible={showWarningForm} onClose={() => setWarningForm(false)} />
    </>
  );
}
