import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Checkbox, Col } from 'antd';
import * as Yup from 'yup';

import { rememberCredentials, signInRequest } from '~/store/modules/auth/actions';

import BaseLayout from '~/pages/_layouts/base';
import { Container, Box } from './styles';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import Row from '~/components/Row';

const schema = Yup.object().shape({
  username: Yup.string()
    .typeError('Insira seu e-mail')
    .email('Insira um e-mail válido')
    .required('Insira seu e-mail'),
  password: Yup.string('Insira sua senha')
    .typeError('Insira sua senha')
    .required('Insira sua senha'),
});

export default function Login() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  var username = useSelector(state => state.auth.rememberUsername);
  var password = useSelector(state => state.auth.rememberPassword);
  var checked = useSelector(state => state.auth.checked);

  function handleRemember({ target }) {
    var checked = target.checked;
    checked ? (username = document.getElementsByName('username')['0'].value) : (username = '');
    checked ? (password = document.getElementsByName('password')['0'].value) : (password = '');
    dispatch(rememberCredentials(username, password, checked));
  }

  function handleSubmit({ username, password }) {
    dispatch(signInRequest(username, password));
    setTimeout(function() {
      setLoading(false);
    }, 1000);
  }

  return (
    <BaseLayout>
      <Box>
        <Formik
          initialValues={{ username, password }}
          onSubmit={values => {
            handleSubmit(values);
            setLoading(true);
          }}
          validationSchema={schema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Container>
              <Logo title={false} width="100%" margin="auto" height="100%" />
              <FormControl field="username" error={touched.username && errors.username}>
                <Input type="email" name="username" placeholder={t('fields:login.username.placeholder')} />
              </FormControl>
              <FormControl field="password" error={touched.password && errors.password}>
                <Input type="password" name="password" placeholder={t('fields:login.password.placeholder')} />
              </FormControl>
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Checkbox checked={checked} onChange={handleRemember}>
                    Lembrar Login
                  </Checkbox>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Link to="/password/link">Esqueci minha senha</Link>
                </Col>
              </Row>
              <Button size="large" block type="submit" loading={loading} color="primary">
                {t('fields:login.submit')}
              </Button>
            </Container>
          )}
        </Formik>
      </Box>
    </BaseLayout>
  );
}
