import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Form, Switch, Input, Select } from 'formik-antd';
import { Modal, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';

const initialValues = {
  name: '',
  email: '',
  password: '',
  role_id: '',
  status: true,
  approvalIds: []
};

export default function UserForm({ visible, onClose }) {
  const roleList = useSelector(state => state && state.user && state.user.profile && state.user.profile.roles);
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userApprovals, setUserApprovals] = useState([]);
  const [approvalIds, setApprovalIds] = useState([]);

  const { Option } = Select;

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/roles/findAll');
      setRoles(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchUserApprovals = async (roleId) => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/findAllApprovals', {
        params: { roleId: roleId },
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setUserApprovals(data);
      setApprovalIds([]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeUserApprovals = (value) => {
    setApprovalIds(value);
  };

  const handleChangeRole = (value) => {
    setApprovalIds([]);
    fetchUserApprovals(value);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      const user = {
        name: values.name,
        email: values.email,
        password: values.password,
        status: values.status ? 'ACTIVE' : 'INACTIVE',
        roles: roles.filter(item => item.id.toString() === values.role_id.toString()),
        deleted: false,
        dailyValueBreakfast: 10,
        dailyValueLunch: 20,
        dailyValueDinner: 20,
        valuePaidMileage: 0.9,
        document: null,
        approvalIds: approvalIds,
      };

      await api.post('/users/create', user);

      message.success(t('messages:userSuccess'));
      onClose();
    } catch (error) {
      const { response } = error;
      if (response.data.errors) {
        if (response.data.errors[0].sqlState === 'duplicateUsers') {
          message.error(t('messages:duplicatedEmail'));
        } else {
          errorHandler(error);
        }
      } else {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const fetchRecords = async () => {
    setLoading(true);
    try {
      setRecordData(initialValues);
      fetchRoles();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    password: Yup.string().required(),
    email: Yup.string()
      .email()
      .required(),
    role_id: Yup.number().required(),
    status: Yup.bool().required(),
  });

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={userSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
        <Modal
          title={t('screens:users.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.name && errors.name}
                  field="name"
                  label={t('screens:users.data.name')}
                  required
                >
                  <Input name="name" autocomplete="off" />
                </FormControl>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.email && errors.email}
                  field="email"
                  label={t('screens:users.data.email')}
                  required
                >
                  <Input type="email" name="email" autocomplete="off" />
                </FormControl>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.password && errors.password}
                  field="password"
                  label={t('screens:profile.data.password')}
                  required
                >
                  <Input type="password" name="password" autocomplete="off" />
                </FormControl>
                <FormControl
                  cols={{ xs: 16 }}
                  error={touched.role_id && errors.role_id}
                  field="role_id"
                  label={t('screens:users.data.role')}
                  required
                >
                  <Select 
                    showSearch
                    name="role_id"
                    disabled={roles.length <= 0}
                    onChange={
                      roleId => {
                        handleChangeRole(roleId);
                        setFieldValue('approvalIds', []);
                      }
                    }
                  >
                    {roles.map(item => {
                      return (
                        <Option key={item.id} value={item.id.toString()}>
                          {item.description}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* <FormControl cols={{ xs: 4 }} label={t('screens:users.data.add')}>
                  <Button loading={loading} color="primary" onClick={() => setShowRoleForm(true)}>
                    <FaPlus />
                  </Button>
                </FormControl> */}
                <FormControl
                  cols={{ xs: 8 }}
                  field="status"
                  label={t('screens:users.data.active')}
                  style={{ margin: 'auto' }}
                >
                  <Switch
                    name="status"
                    checkedChildren={t('messages:yes')}
                    unCheckedChildren={t('messages:no')}
                    style={{ margin: 'auto' }}
                  />
                </FormControl>
                {roleList[0].name === 'ROLE_ADMIN' && values.role_id.toString() !== '1' 
                  && values.role_id.toString() !== '4' ? 
                  <FormControl
                      cols={{ xs: 24 }}
                      label={t('screens:users.data.userApprovals')}
                      field="approvalIds"
                    >
                      <Select 
                        mode="multiple"
                        showSearch
                        name="approvalIds" 
                        onChange={handleChangeUserApprovals}
                        disabled={values.role_id === ''} 
                      >
                        {userApprovals.map(user => {
                          return (
                            <Option key={user.id} value={user.id}>{user.name}</Option>
                          );
                        })}
                      </Select>
                  </FormControl> : null
                }
              </Row>
            </Form>
          </Spin>
          {/* <RoleForm visible={showRoleForm} onClose={() => setShowRoleForm(false)} /> */}
        </Modal>
      )}
    </Formik>
  );
}
