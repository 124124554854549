import React from 'react';
import StringMask from 'string-mask';
import { Field } from 'formik';

const DELIMITER = '.';
const INTERN_MASK = '00.0000.00';
const MASK = '00.0000';

function removeTrailingCharIfFound(str, char) {
  return str
    .split(char)
    .filter(segment => segment !== '')
    .join(char);
}

function formatValue(str) {
  const unmaskedValue = str.split(DELIMITER).join('');
  const formatted = StringMask.process(unmaskedValue, str.substring(0, 2) === '01'? MASK : INTERN_MASK);
  return removeTrailingCharIfFound(formatted.result, DELIMITER);
}

export function CodeField(props) {
  return (
    <Field name={props.name}>
      {fieldProps => (
        <input
          class={'ant-input'}
          {...fieldProps.field}
          onChange={event => {
            fieldProps.field.onChange(event.target.name)(formatValue(event.target.value));
          }}
        />
      )}
    </Field>
  );
}
