import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  // background: rgba(24, 144, 255, 0.1);

  border: 1px solid #e9e9e9;
  border-radius: 5px;

  min-height: 200px;
  width: 100%;
  margin-top: 40px;
`;

export const Value = styled.header`
  display: flex;
  justify-content: center;

  color: #1F1A17 ;
  font-weight: 700;
  font-size: 2rem;
  margin-top: 15px;
  padding: 15px 20px 0px;
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  color: #0F4C81;

  padding: 20px 0 20px;
`;

