import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import * as Yup from 'yup';
import BaseLayout from '~/pages/_layouts/base';
import { Container, Box } from './styles';
import FormControl from '~/components/Form/FormControl';
import api from '~/services/api';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import history from '~/services/history';

const schema = Yup.object().shape({
  password: Yup.string().required(),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
});

export default function Reset(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { token } = props.match.params;
  const [recordData, setRecordData] = useState([]);

  const handleSubmit = async values => {
    setLoading(true);
    try {
      recordData.password = values.password_confirmation;

      await api.put('/users/update', recordData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      history.push('/');
      message.success(t('messages:resetPasswordSuccess'));
    } catch (error) {
      message.error(t('messages:passwordError'));
    }
    setLoading(false);
  };

  const fetchRecords = async () => {
    try {
      const { data } = await api.get('/users/find', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRecordData(data);
    } catch (error) {
      message.error(t('messages:invalidToken'));
    }
  };

  useEffect(() => {
    fetchRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout>
      <Box>
        <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={schema}>
          {({ errors, touched, isSubmitting }) => (
            <Container>
              <Logo title={false} margin="auto" width='100%' height='100%'/>
              <FormControl field="password" error={touched.password && errors.password}>
                <Input type="password" name="password" placeholder={t('fields:reset.password.placeholder')} />
              </FormControl>
              <FormControl
                field="password_confirmation"
                error={touched.password_confirmation && errors.password_confirmation}
              >
                <Input
                  type="password"
                  name="password_confirmation"
                  placeholder={t('fields:reset.passwordConfirmation.placeholder')}
                />
              </FormControl>
              <Button size="large" block type="submit" loading={loading} color="primary">
                {t('fields:reset.submit')}
              </Button>
              <Link to="/">{t('screens:resetPassword.back')}</Link>
            </Container>
          )}
        </Formik>
      </Box>
    </BaseLayout>
  );
}
