import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

export default function Pagination({ children, meta, ...props }) {
  const { t } = useTranslation();
  const { first, last, numberOfElements, totalElements, pageNumber, pageSize } = meta;
  const from = first === true ? 1 : ((pageNumber - 1) * pageSize) + 1;
  const to = first === true ? numberOfElements : last === true ? totalElements : pageNumber * pageSize;

  return (
    <>
      {totalElements > 0 && (
        <Container
          {...props}
          showTotal={
            () =>
              `${t('screens:pagination.showing')} ${from} ${t('screens:pagination.to')} ${to} ${t(
                'screens:pagination.of'
              )} ${totalElements} ${t('screens:pagination.records')}`
          }
          total={totalElements}
          defaultPageSize={pageSize}
          current={pageNumber}
        >
          {children}
        </Container>
      )}
    </>
  );
}

Pagination.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

Pagination.defaultProps = {
  children: null,
  from: 0,
    to: 0,
  meta: {
    first: false,
    last: false,
    numberOfElements: 0,
    totalElements: 0,
    pageNumber: 1,
    pageSize: 10,
  },
};
