import React, { useState, useEffect } from 'react';

import { Formik } from 'formik';
import { Modal, Spin, Tag, message, Col, Table, Switch } from 'antd';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import PageTitle from '~/components/PageTitle';
import { ModalFooter } from '~/components/Modal';

import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';

import InputCurrency from '~/components/Form/InputCurrency';

export default function DailyRates({ visible, onClose }) {
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [enableDinner, setEnableDinner] = useState(false);
  const [enablePaidMileage, setEnablePaidMileage] = useState(false);
  const [enableBreakfast, setEnableBreakfast] = useState(false);
  const [enableLunch, setEnableLunch] = useState(false);

  const { t } = useTranslation();
  const { profile } = useSelector(state => state.user);

  const handleSave = async values => {
    setLoading(true);
    try {
      await api.post('/users/updateDailyRates', values);
      message.success('Salvo com sucesso.');
      onClose();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/findAll');
      setTableData(data);
      setSelectedRowKeys([]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecordData();
    }
  }, [visible]);

  const tableColumns = [
    {
      title: t('screens:users.data.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:users.data.role'),
      dataIndex: 'roles[0].description',
      key: 'roles[0].description',
    },
    {
      title: t('screens:users.data.status'),
      dataIndex: 'status',
      key: 'status',
      render: status =>
        status === 'ACTIVE' ? (
          <Tag color="green">{t('screens:users.data.active')}</Tag>
        ) : (
          <Tag color="red">{t('screens:users.data.inactive')}</Tag>
        ),
    },
  ];

  const schema = Yup.object().shape({
    dailyValueDinner: Yup.number(),
    valuePaidMileage: Yup.number(),
    dailyValueBreakfast: Yup.number(),
    dailyValueLunch: Yup.number(),
    userIds: Yup.array().required('É obrigatório selecionar ao menos um usuário.'),
  });

  return (
    <Formik initialValues={{}} onSubmit={handleSave} validationSchema={schema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setValues }) => (
        <Modal
          onCancel={onClose}
          afterClose={resetForm}
          width="800px"
          style={{ top: 20 }}
          visible={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col>
                  <PageTitle style={{ color: '#0f4c81' }} title={t('screens:profile.data.daily')} />
                </Col>
              </Row>
              <Row>
                <FormControl
                  label={t('screens:users.dailyInfo.breakfast')}
                  cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}
                >
                  <Switch
                    checked={enableBreakfast}
                    onChange={checked => {
                      setEnableBreakfast(checked);
                      if (!checked && values.dailyValueBreakfast) {
                        delete values.dailyValueBreakfast;
                        setValues(values);
                      }
                    }}
                    size="small"
                  />
                </FormControl>
                <FormControl label={t('screens:users.dailyInfo.lunch')} cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}>
                  <Switch
                    checked={enableLunch}
                    onChange={checked => {
                      setEnableLunch(checked);
                      if (!checked && values.dailyValueLunch) {
                        delete values.dailyValueLunch;
                        setValues(values);
                      }
                    }}
                    size="small"
                  />
                </FormControl>
                <FormControl label={t('screens:users.dailyInfo.dinner')} cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}>
                  <Switch
                    checked={enableDinner}
                    onChange={checked => {
                      setEnableDinner(checked);
                      if (!checked && values.dailyValueDinner) {
                        delete values.dailyValueDinner;
                        setValues(values);
                      }
                    }}
                    size="small"
                  />
                </FormControl>
                <FormControl label={t('screens:users.dailyInfo.km')} cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}>
                  <Switch
                    checked={enablePaidMileage}
                    onChange={checked => {
                      setEnablePaidMileage(checked);
                      if (!checked && values.valuePaidMileage) {
                        delete values.valuePaidMileage;
                        setValues(values);
                      }
                    }}
                    size="small"
                  />
                </FormControl>
              </Row>
              <Row>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}
                  label={t('screens:profile.data.breakfast')}
                  required
                  error={(touched.dailyValueBreakfast && errors.dailyValueBreakfast) || errors.dailyValueBreakfast}
                  field="dailyValueBreakfast"
                >
                  <InputCurrency
                    name="dailyValueBreakfast"
                    precision={2}
                    decimalSeparator=","
                    currency="R$"
                    disabled={
                      (profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN') ||
                      !enableBreakfast
                    }
                  />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 24, md: 6, lg: 6, xl: 6 }}
                  label={t('screens:profile.data.lunch')}
                  required
                  error={(touched.dailyValueLunch && errors.dailyValueLunch) || errors.dailyValueLunch}
                  field="dailyValueLunch"
                >
                  <InputCurrency
                    name="dailyValueLunch"
                    precision={2}
                    decimalSeparator=","
                    currency="R$"
                    disabled={
                      (profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN') ||
                      !enableLunch
                    }
                  />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 24, md: 6, lg: 6, xl: 6 }}
                  label={t('screens:profile.data.dinner')}
                  required
                  error={(touched.dailyValueDinner && errors.dailyValueDinner) || errors.dailyValueDinner}
                  field="dailyValueDinner"
                >
                  <InputCurrency
                    name="dailyValueDinner"
                    precision={2}
                    decimalSeparator=","
                    currency="R$"
                    disabled={
                      (profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN') ||
                      !enableDinner
                    }
                  />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 24, md: 6, lg: 6, xl: 6 }}
                  label={t('screens:profile.data.km')}
                  required
                  error={(touched.valuePaidMileage && errors.valuePaidMileage) || errors.valuePaidMileage}
                  field="valuePaidMileage"
                >
                  <Input
                    disabled={
                      (profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN') ||
                      !enablePaidMileage
                    }
                    name="valuePaidMileage"
                  />
                </FormControl>
              </Row>
              <Row>
                <Col>
                  <PageTitle style={{ color: '#0f4c81' }} title={t('screens:users.title')} />
                </Col>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                  required
                  error={(touched.userIds && errors.userIds) || errors.userIds}
                  field="userIds"
                >
                  <Table
                    loading={loading}
                    rowKey="id"
                    rowSelection={{
                      selectedRowKeys,
                      onChange: keys => {
                        setSelectedRowKeys(keys);
                        setValues({ ...values, userIds: keys });
                      },
                      hideDefaultSelections: true,
                      selections: [
                        {
                          key: 'all-data',
                          text: 'Selecionar todos',
                          onSelect: () => {
                            const data = tableData.map(item => item.id);
                            setSelectedRowKeys(data);
                            setValues({ ...values, userIds: data });
                          },
                        },
                        selectedRowKeys.length > 0 && {
                          key: 'clear-data',
                          text: 'Limpar seleção',
                          onSelect: () => {
                            setSelectedRowKeys([]);
                            setValues({ ...values, userIds: [] });
                          },
                        },
                      ],
                    }}
                    size="middle"
                    columns={tableColumns}
                    dataSource={tableData}
                  />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
