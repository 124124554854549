import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled(Modal)``;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
