import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';

export default function RefuseForm({ visible, onClose, recordToRefuse }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => (state.user.profile ? state.user.profile : null));

  const handleSave = async values => {
    setLoading(true);
    delete recordToRefuse.checked;
    const expenseObservations = {
      date: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
      observation: values.expenseObservations,
      deleted: false,
      user: user,
      expense: recordToRefuse,
    };
    try {
      await api.post('/expenseObservations/create', expenseObservations);
      const { data } = await api.get('/expenses/findById', {
        params: { id: recordToRefuse.id },
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      data.expenseStatus = 'DISAPPROVED';
      await api.put('/expenses/update', data);
      message.success(t('messages:success'));
      onClose(recordToRefuse.id);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {}, [visible]);

  const schema = Yup.object().shape({
    expenseObservations: Yup.string().required(),
  });

  return (
    <Formik initialValues={{}} enableReinitialize onSubmit={handleSave} validationSchema={schema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
        <Modal
          title={t('screens:financial.justifyMessage')}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.expenseObservations && errors.expenseObservations}
                  field="expenseObservations"
                  label={t('screens:financial.expenseObservations')}
                  required
                >
                  <Input name="expenseObservations" />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
