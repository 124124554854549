import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '~/components/Logo';
import { Container } from './styles';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Container>
      {t('messages:desenvolvimento')} <Logo height={18} width={26} w2z={true} /> | V2.2.9
    </Container>
  );
}
