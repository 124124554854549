import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Modal, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import Button from '~/components/Button';
import ImageThumb from '~/components/ImageThumb';

export default function FileList({ visible, onClose, receipts }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState(null);
  const token = useSelector(state => state.auth.token);

  const handleDownloadAll = async () => {
    setLoading(true);
    var zip = new JSZip();
    var photoZip = zip.folder('comprovantes');
    var numberOfReceipts = receipts.length;
    var contador = 0;
    var count = 0;
    var countFileError = 0;

    // eslint-disable-next-line
    receipts.map(file => {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${file.name}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }).then(response => {
        count++;
        photoZip.file(file.originalName, response.data);
        contador++;
        if (contador >= numberOfReceipts) {
          photoZip.generateAsync({ type: 'blob' }).then(function(blob) {
            saveAs(blob, 'comprovantes.zip');
          });
          setLoading(false);
        }
      }).catch(error => {
        count++;
        if (error.response && error.response.status === 404) {
          countFileError++;
          if (numberOfReceipts === count) {
            let msg = countFileError > 1 ? t('messages:downloadReceiptsError') + " " + countFileError.toString() + " " + t('messages:receiptsNotFoundError')
            : t('messages:downloadReceiptError') + " " + countFileError.toString() + " " + t('messages:receiptNotFoundError');
            message.error(msg);
            setLoading(false);
          }
        }
      });
    });
  };

  const sizeOfThings = () => {
    var windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', function() {
    sizeOfThings();
  });

  useEffect(() => {
    sizeOfThings();
  }, [visible]);

  return (
    <Formik initialValues={{}} enableReinitialize>
      {({ errors, isSubmitting, submitForm, resetForm }) => (
        <Modal
          title={t('screens:fileList.title')}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          footer={[
            <Button style={{ marginLeft: 'auto' }} loading={loading} color={'primary'} onClick={onClose}>
              {'Fechar'}
            </Button>,
          ]}
        >
          <Spin spinning={loading || isSubmitting}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: window.innerWidth < 500 ? '1fr 1fr' : '1fr 1fr 1fr',
                justifyItems: 'center',
                border: '1px solid #ddd',
                borderRadius: '5px',
                paddingTop: '20px',
              }}
            >
              {receipts.map(file => (
                <ImageThumb file={file} />
              ))}
            </div>
            <Button
              style={{ marginLeft: 'auto', marginTop: '10px' }}
              onClick={() => {
                handleDownloadAll();
              }}
            >
              Baixar todos
            </Button>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
