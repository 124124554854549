import styled from 'styled-components';

export const Help = styled.p`
  font-size: 9pt;
  color: #6d6d6d;
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 75%;
  height: 130px;
  margin-bottom: 30px;
  img {
    width: 72px;
    height: 72px;
  }
`;

export const TotalValue = styled.h1`
  margin-left: auto;
  margin-top: 10px;
  font-size: 18pt;
`;
