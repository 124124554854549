import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPrint, FaPencilAlt, FaSearch } from 'react-icons/fa';
import { Spin, Col } from 'antd';
import { Form, Select, DatePicker } from 'formik-antd';
import { Formik } from 'formik';
import { isAfter, isBefore, parseISO, parse, format } from 'date-fns';
import * as Yup from 'yup';
import { formatPrice } from 'Utils';
import ReviewTypeForm from './reviewTypeForm.js';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import DefautLayout from '~/pages/_layouts/full';
import EditForm from '~/pages/Financial/form.js';
import Row from '~/components/Row';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import FormControl from '~/components/Form/FormControl';
import { Table, TableActions } from '~/components/Table';

export default function Users() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showReviewTypeForm, setShowReviewTypeForm] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordDownload, setRecordDownload] = useState(null);

  const { Option } = Select;

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/rdvs/findAll');
      setRecordData(data);
      setTableData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchCollaborators = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/findAll');
      data.splice(0, 0, {
        id: 0,
        name: 'Todos',
      });
      setCollaborators(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDownload = async record => {
    setRecordDownload(record);
    setShowReviewTypeForm(true);
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleSearch = async values => {
    setLoading(true);
    try {
      let table = recordData;

      if (values.collaborator === '0') {
        setTableData(table);
      } else {
        if (values.collaborator !== undefined && values.collaborator !== null) {
          table = table.filter(data => {
            return data.user.id.toString() === values.collaborator;
          });
        }
        if (values.init !== undefined && values.init !== null) {
          table = table.filter(data => {
            return isAfter(parse(data.closedDate, 'dd-MM-yyyy HH:mm:ss', new Date()), parseISO(values.init));
          });
        }
        if (values.end !== undefined && values.end !== null) {
          table = table.filter(data => {
            return isBefore(parse(data.closedDate, 'dd-MM-yyyy HH:mm:ss', new Date()), parseISO(values.end));
          });
        }
        if (values.cost !== undefined && values.cost !== null) {
          table = table.filter(data => {
            return data.value === values.cost;
          });
        }
        setTableData(table);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords();
    fetchCollaborators();
  }, [showForm]);

  const tableColumns = [
    {
      title: t('screens:review.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('screens:review.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:review.collaborator'),
      dataIndex: 'user.name',
      key: 'user.name',
    },
    {
      title: t('screens:review.closing_date'),
      dataIndex: 'closedDate',
      key: 'closedDate',
      render: (text, record) => format(parse(record.closedDate, 'dd-MM-yyyy HH:mm:ss', new Date()), 'dd-MM-yyyy'),
    },
    {
      title: t('screens:review.value'),
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => formatPrice(record.value),
    },
    {
      title: t('screens:users.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      render: (text, record) => (
        <TableActions>
          <Button title={t('messages:generate')} onClick={() => handleDownload(record)}>
            <FaPrint color={'black'} />
          </Button>
          <Button title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
            <FaPencilAlt color={'green'} />
          </Button>
        </TableActions>
      ),
    },
  ];

  const schema = Yup.object().shape({
    cost: Yup.number().nullable(),
  });

  return (
    <DefautLayout>
      <Box>
        <PageTitle title={`RDV > ${t('screens:review.title')}`} />
        <Formik initialValues={{}} enableReinitialize onSubmit={handleSearch} validationSchema={schema}>
          {({ errors, isSubmitting, submitForm, resetForm }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <Row>
                  <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                    <FormControl
                      error={errors.collaborator}
                      field="collaborator"
                      label={t('screens:review.collaborator')}
                      required
                    >
                      <Select
                        showSearch
                        name="collaborator"
                        disabled={collaborators.length <= 0}
                        placeholder={t('messages:all')}
                      >
                        {collaborators.map(item => {
                          return (
                            <Option key={item.id} value={item.id.toString()}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                    <FormControl error={errors.init} field="initial" label={t('screens:review.from')}>
                      <DatePicker format="DD/MM/Y" name="init" placeholder={t('messages:select')} />
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                    <FormControl error={errors.end} field="end" label={t('screens:review.at')}>
                      <DatePicker format="DD/MM/Y" name="end" placeholder={t('messages:select')} />
                    </FormControl>
                  </Col>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}>
                    <FaSearch style={{ width: '18px' }} />
                    {t('screens:review.search')}
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      fetchRecords();
                    }}
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 25 }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />
        <EditForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
        <ReviewTypeForm record={recordDownload} visible={showReviewTypeForm} onClose={() => {setShowReviewTypeForm(false)}} />
      </Box>
    </DefautLayout>
  );
}
