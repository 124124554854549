import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Modal, message, Spin } from 'antd';
import StringMask from 'string-mask'

import api from '~/services/api';
import errorHandler from '../../Utils/errorHandler';

import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

export default function BudgetaryNatureForm({ visible, onClose, id }) {
  const { t } = useTranslation();
  const { Option } = Select;

  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState([]);


  const fetchRecords = async () => {
    setLoading(true);

    try {
      if (!id) {
        setRecordData([]);
      } else {
        const { data } = await api.get('/budgetaryNatures/findById', {
          params: { id },
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setRecordData(data);
        setTask(data.task);
      }
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/tasks/findAll');
      setTasks(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async values => {
    setLoading(true);

    const task = tasks.filter(item => item.id === values.task);

    const data = {
      id: null,
      code: values.code,
      name: values.name,
      deleted: false,
      task: task[0],
    };
    try {
      if (!id) {
        await api.post('/budgetaryNatures/create', data);
        onClose();
        message.success(t('messages:budgetaryNatureSuccess'));
      } else {
        data.id = id;

        await api.put('/budgetaryNatures/update', data);
        onClose();
        message.success(t('messages:budgetaryNatureSuccessEdit'));
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const DELIMITER = '..'
  const MASK = '00..0000'

  function removeTrailingCharIfFound(str, char) {
    return (
      str
        .split(char)
        .filter(segment => segment !== '')
        .join(char)
    )
  }

  function formatValue(str) {
    const unmaskedValue = str.split(DELIMITER).join('')
    const formatted = StringMask.process(unmaskedValue, MASK)
    return removeTrailingCharIfFound(formatted.result, DELIMITER)
  }

  useEffect(() => {
    if (visible) {
      fetchRecords();
      fetchTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Formik
      initialValues={id ? { code: recordData.code, name: recordData.name, task: task.id } : {}}
      enableReinitialize
      onSubmit={handleSave}
    >
      {({ isSubmitting, submitForm, resetForm, setValues, values }) => (
        <Modal
          title={
            recordData.id
              ? `${t('screens:budgetaryNature.data.editing')} ${recordData.name}`
              : t('screens:budgetaryNature.data.newBudgetaryNature')
          }
          onCancel={onClose}
          afterClose={resetForm}
          loading={loading || isSubmitting}
          visible={visible}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} cancelColor="default" onCancel={onClose} />
          }
        >
          <Spin spinning={loading}>
            <Form>
              <Row>
                <FormControl field="code" cols={{ xs: 24 }} label={t('screens:budgetaryNature.data.code')}>
                  <Input
                    name="code"
                    autoComplete="off"
                    onChange={event => setValues({...values, code: formatValue(event.target.value)})}
                  />
                </FormControl>
                <FormControl field="name" cols={{ xs: 24 }} label={t('screens:budgetaryNature.data.name')}>
                  <Input name="name" autoComplete="off" />
                </FormControl>
                <FormControl field="task" cols={{ xs: 24 }} label={t('screens:budgetaryNature.data.task')}>
                  <Select name="task" placeholder="Selecione">
                    {tasks.map(item => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
