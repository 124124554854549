import React from 'react';
import { Spin } from 'antd';

import { Container, Description, Value } from './styles';

export default function Card(props) {
  return (
    <Container>
      <Spin spinning={props.loading}>
        <Value>{props.loading === true ? '' : props.value}</Value>
        <Description>{props.loading === true ? '' : props.description}</Description>
      </Spin>
    </Container>
  );
}
