import { includes, some } from 'lodash';

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const filterString = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

export function normalizeString(str) {
  const strAccents = str.split('');
  const strAccentsOut = [];

  const strAccentsLen = strAccents.length;

  for (let y = 0; y < strAccentsLen; y++) {
    if (!some(['ä', 'ü', 'ö'], el => includes(strAccents[y], el))) {
      strAccentsOut[y] = strAccents[y].normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } else {
      strAccentsOut[y] = strAccents[y];
    }
  }

  const newString = strAccentsOut.join('').replace('ß', 'ss');
  return newString;
}

export function dynamicKm(v) {
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/(\d)(\d{1})$/, '.$1$2'); //Coloca . antes dos 2 ultimos numeros
  return v;
}

export function dynamicCost(v) {
  v = v.replace(/\D/g, '');
  v = (v / 100).toFixed(2) + '';
  v = v.replace(',', '.');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  return v;
}
