import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import Login from '~/pages/Login';
import Remember from '~/pages/Password/Remember.js';
import Reset from '~/pages/Password/Reset.js';

import Entries from '~/pages/Entries';
import EntriesForm from '~/pages/Entries/form';
import EntriesEdit from '~/pages/Entries/formModal';
import Users from '~/pages/Users';
import Profile from '~/pages/Profile';
import CostCenter from '~/pages/CostCenter';
import Financial from '~/pages/Financial';
import Review from '~/pages/Review';
import EntireReview from '~/pages/EntireReview';
import Supervisor from '~/pages/Supervisor';
import Dashboard from '~/pages/Dashboard';
import Task from '~/pages/Task'
import NatureBudget from '~/pages/BudgetaryNature';
import Items from '~/pages/Items'

import e404 from '~/pages/Errors/e404.js';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/password/link" component={Remember} />
      <Route exact path="/password/reset/:token" component={Reset} />

      <Route exact path="/dashboard" component={Dashboard} isPrivate />

      <Route exact path="/lancamentos" component={Entries} isPrivate />
      <Route exact path="/lancamentos/novo" component={EntriesForm} isPrivate />
      <Route exact path="/lancamentos/editar" component={EntriesEdit} isPrivate />

      <Route exact path="/usuarios" component={Users} isPrivate />
      <Route exact path="/usuarios/:id" component={Profile} isPrivate />

      <Route exact path="/centros-de-custo" component={CostCenter} isPrivate />

      <Route exact path="/financeiro" component={Financial} isPrivate />

      <Route exact path="/relatorio-pagamentos" component={Review} isPrivate />
      <Route exact path="/relatorio-lancamentos" component={EntireReview} isPrivate />

      <Route exact path="/aprovacoes" component={Supervisor} isPrivate />

      <Route exact path='/tarefas' component={Task} isPrivate />
      <Route exact path='/natureza-orcamentaria' component={NatureBudget} isPrivate />
      <Route exact path='/items' component={Items} isPrivate/>

      <Route exact path="/404" component={e404} isFree />
      <Redirect to="/404" />
    </Switch>
  );
}
