import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaSearch, FaPaperclip, FaTimes } from 'react-icons/fa';
import { Popconfirm, message, Spin, Col, Tooltip } from 'antd';
import { Form, Select, DatePicker } from 'formik-antd';
import { Formik } from 'formik';
import { AiOutlineClose } from 'react-icons/ai';
import { parse, format } from 'date-fns';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { formatPrice } from 'Utils';
import * as Yup from 'yup';
import FileList from '~/pages/FileList';
import { TotalValue } from './styles.js';
import OkForm from './form.js';
import RefuseForm from './refuseForm.js';
import DefautLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Row from '~/components/Row';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import FormControl from '~/components/Form/FormControl';
import { Table, TableActions, TableHeader } from '~/components/Table';

export default function Users() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showFileList, setShowFileList] = useState(false);
  const [showRefuseForm, setShowRefuseForm] = useState(false);
  const [cnpjCards, setCnpjCards] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [recordToRefuse, setRecordToRefuse] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [items, setItems] = useState([]);
  const [tasks, setTasks] = useState([]);
  // eslint-disable-next-line
  const [filters, setFilters] = useState([]);
  const [showItems, setShowItems] = useState(true);
  const [costCenters, setCostCenters] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [lastValue, setLastValue] = useState(totalValue);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [showTable, setShowTable] = useState('none');
  const [fields, setFields] = useState(true);
  const token = useSelector(state => state.auth.token);

  const { Option } = Select;

  const fetchCollaborators = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/expenses/findCollaboratorFinancial');
      setCollaborators(data);
      if (data.length <= 0) {
        const config = {
          content: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Não foi encontrado nenhum lançamento que precise de pagamento!</span>
              <button
                style={{ background: '#fff', border: 0, marginLeft: '10px', marginTop: '2px' }}
                onClick={() => {
                  message.destroy();
                }}
              >
                <AiOutlineClose color="red" />
              </button>
            </div>
          ),
          duration: 0,
          icon: false,
        };
        message.open(config);
        setCnpjCards([{ id: 0, registrationNumber: '00.000.000/0000-00', businessName: 'Todos' }]);
        setCostCenters([]);
        setTasks([]);
        setRecordData([]);
        setTableData([]);
        setShowTable('none');
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRecords = async (filters) => {
    var zero = 0;
    setTotalValue(zero);
    setLastValue(zero);
    setLoading(true);
    
    try {
      const { data } = await api.post('/expenses/findAllForFinancial', filters);
      if (data.expenses.length > 0) {
        let cnpjCards = [{ id: 0, registrationNumber: '00.000.000/0000-00', businessName: 'Todos' }];
        cnpjCards.push(...data.cnpjCards);
        
        setCnpjCards(cnpjCards);
        setCostCenters(data.costCenters);
        setTasks(data.tasks);
        setItems([]);
        setRecordData(data.expenses);
        setTableData(data.expenses);
        setShowItems(true);
      } else {
        setCnpjCards([{ id: 0, registrationNumber: '00.000.000/0000-00', businessName: 'Todos' }]);
        setCostCenters([]);
        setTasks([]);
        setItems([]);
        setRecordData([]);
        setTableData([]);
        setShowTable('none');
        setShowItems(true);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  }

  const handleItems = async value => {
    const { data } = await api.get('items/findByTaskId', {
      params: { taskId: value },
    });
    setItems(data);
    setShowItems(false);
  };

  const handleSearch = async values => {
    setLoading(true);
    var zero = 0;
    setTotalValue(zero);
    setLastValue(zero);
    setLoading(true);
    
    const filter = [
      {
        field: 'expenseStatus',
        value: ['APPROVED', 'APPROVED_RESTRICTION'],
        restriction: 'IN',
      },
      {
        columnJoin: 'user',
        field: 'id',
        value: values.collaborator,
        restriction: 'EQUAL',
      }
    ];

    if (values.cnpjCard !== undefined && values.cnpjCard !== null &&
      values.cnpjCard !== '' && values.cnpjCard !== '00.000.000/0000-00') {
      filter.push({
        columnJoin: 'cnpjCard',
        field: 'registrationNumber',
        value: values.cnpjCard,
        restriction: 'EQUAL',
      });
    }

    if (values.costCenter !== undefined && values.costCenter !== null && values.costCenter !== '') {
      filter.push({
        columnJoin: 'costCenter',
        field: 'id',
        value: values.costCenter,
        restriction: 'EQUAL',
      });
    }

    if (values.init !== undefined && values.init !== null) {
      const start = format(new Date(values.init), 'dd-MM-yyyy');
      filter.push({
        field: 'releaseDate',
        value: start,
        restriction: 'GREATER_THAN_OR_EQUAL_TO',
      });
    }

    if (values.end !== undefined && values.end !== null) {
      const final = format(new Date(values.end), 'dd-MM-yyyy');
      filter.push({
        field: 'releaseDate',
        value: final,
        restriction: 'LESS_THAN_OR_EQUAL_TO',
      });
    }

    if (values.task !== undefined && values.task !== null && values.task !== '') {
      filter.push({
        columnJoin: 'item',
        columnSecondJoin: 'budgetaryNature',
        columnThirdJoin: 'task',
        field: 'id',
        value: values.task,
        restriction: 'EQUAL',
      });
    }

    if (values.item !== undefined && values.item !== null && values.item !== '') {
      filter.push({
        columnJoin: 'item',
        field: 'id',
        value: values.item,
        restriction: 'EQUAL',
      });
    }
    setFilters(filter);

    try {
      const { data } = await api.post('/expenses/findAllForFinancial', filter);
      setRecordData(data.expenses);
      setTableData(data.expenses);
      setShowTable('block');
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleExpenses = async e => {
    const filterList = [
      {
        field: 'expenseStatus',
        value: ['APPROVED', 'APPROVED_RESTRICTION'],
        restriction: 'IN',
      },
      {
        columnJoin: 'user',
        field: 'id',
        value: e,
        restriction: 'EQUAL',
      }
    ];
    setFilters(filterList);
    fetchRecords(filterList);
  };

  const handleFields = () => {
    setFields(false);
  };

  const handlePay = async () => {
    setSelectedEntries(
      recordData.filter(item => {
        return item.checked === true;
      })
    );
    setShowForm(true);
  };

  const handleCheck = async () => {
    setSelectedEntries(
      recordData.filter(item => {
        return item.checked === true;
      })
    );
  };

  const handleSelect = async (record, e) => {
    record.checked = e.target.checked;
    var total = totalValue;
    if (e.target.checked) {
      total = total + record.cost;
      setTotalValue(total);
    } else {
      total = total - record.cost;
      setTotalValue(total);
    }
    handleCheck();
  };

  const handleSelectAll = e => {
    setLastValue(totalValue);
    let total = totalValue;
    total = 0;
    setTotalValue(total);
    if (e.target.checked) {
      // eslint-disable-next-line
      tableData.map(record => {
        record.checked = e.target.checked;
        total = total + record.cost;
      });

      setTotalValue(total);
    } else {
      // eslint-disable-next-line
      tableData.map(record => {
        record.checked = e.target.checked;
      });
      setTotalValue(lastValue);
    }
    const checkboxLength = document.getElementsByClassName('checkBoxTable').length;
    for (let i = 0; i < checkboxLength; i++) {
      document.getElementsByClassName('checkBoxTable')[i].checked = e.target.checked;
    }
    handleCheck();
  };

  const checkIfReload = async () => {
    // eslint-disable-next-line
    const id = tableData.map(item => {
      if (item.user.id) {
        return item.user.id;
      }
    });
    await handleExpenses(id[0]);
  };

  const handleFeedType = (record) => {

    const feedType = [
      { type: 'BREAKFAST', name: 'Café da manhã' },
      { type: 'LUNCH', name: 'Almoço' },
      { type: 'DINNER', name: 'Janta' },
    ];

    const feed = feedType.filter(item => item.type === record.feedType)

    return feed[0].name
  }

  const handleExtraCollums = record => {
    switch (record.expenseType) {
      case 'MILEAGE_REIMBURSEMENT':
        return (
          <>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>KM Inicial: {record.initialKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>KM Final: {record.finalKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Placa do veículo: {record.licensePlate}</p>
              </Col>
            </Row>
            {record.observation ?
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  <p>{t("screens:entries.data.notes")}: {record.observation}</p>
                </Col>
              </Row> : null
            }
             {record.supervisorObservation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>

            ) : null}
          </>
        );
      case 'FEED':
        return (
          <>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Número de pessoas: {record.personQuantity === 0 ? '1' : record.personQuantity}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Nome das pessoas: {record.personNames ? record.personNames : record.user.name}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Tipo da Refeição : {handleFeedType(record)}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Quantidade de refeições: {record.mealsQuantity} </p>
              </Col>
            </Row>
            {record.observation ?
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  <p>{t("screens:entries.data.notes")}: {record.observation}</p>
                </Col>
              </Row> : null
            }
            {record.supervisorObservation ? (
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row>

            ) : null}
          </>
        );
      case 'FUEL':
        return (
          <>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>KM Final: {record.finalKm}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                <p>Placa do veículo: {record.licensePlate}</p>
              </Col>
            </Row>
            {record.observation ?
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  <p>{t("screens:entries.data.notes")}: {record.observation}</p>
                </Col>
              </Row> : null
            }
            {record.supervisorObservation ?
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row> : null
            }
          </>
        );
      case 'GENERIC':
        return (
            <>
            {record.observation ?
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  <p>{t("screens:entries.data.notes")}: {record.observation}</p>
                </Col>
              </Row> : null
            }
            {record.supervisorObservation ?
              <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <p>
                    {t('screens:entries.data.supervisor_notes')}: {record.supervisorObservation}
                  </p>
                </Col>
              </Row> : null
            }
            </>
        );
      default:
        return;
    }
  };

  const handleDownloadAll = async () => {
    setLoading(true);
    var zip = new JSZip();
    var photoZip = zip.folder('comprovantes');
    var numberOfReceipts = 0;
    var contador = 0;
    var count = 0;
    var countFileError = 0;
    // eslint-disable-next-line
    selectedEntries.map(entire => {
      numberOfReceipts = numberOfReceipts + entire.expenseReceipts.length;
    });
    selectedEntries.map(async entire => {
      await entire.expenseReceipts.map(async receipt => {
        await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${receipt.name}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        }).then(response => {
          count++;
          photoZip.folder('comprovantes').file('[' + entire.id + ']' + receipt.originalName, response.data);
          contador++;
          if (numberOfReceipts === contador) {
            photoZip.generateAsync({ type: 'blob' }).then(function(blob) {
              saveAs(blob, 'comprovantes.zip');
            });
            setLoading(false);
          }
        }).catch(error => {
          count++;
          if (error.response && error.response.status === 404) {
            countFileError++;
            if (numberOfReceipts === count) {
              let msg = countFileError > 1 ? t('messages:downloadReceiptsError') + " " + countFileError.toString() + " " + t('messages:receiptsNotFoundError')
              : t('messages:downloadReceiptError') + " " + countFileError.toString() + " " + t('messages:receiptNotFoundError');
              message.error(msg);
              setLoading(false);
            }
          }
        });
      });
    });
  };

  useEffect(() => {
    fetchCollaborators();
    const filterList = [
      {
        field: 'expenseStatus',
        value: ['APPROVED', 'APPROVED_RESTRICTION'],
        restriction: 'IN',
      },
    ];
    setFilters(filterList);
  }, [showForm]);

  const tableColumns = [
    {
      title: t('screens:financial.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('screens:financial.expense_date'),
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div style={{ width: '71px' }}>
          {format(parse(record.date, 'dd-MM-yyyy HH:mm:ss', new Date()), 'dd-MM-yy')}
        </div>
      ),
    },
    {
      title: t('screens:financial.release_date'),
      dataIndex: 'releaseDate',
      key: 'releaseDate',
      render: (text, record) => (
        <div style={{ width: '71px' }}>
          {format(parse(record.releaseDate, 'dd-MM-yyyy HH:mm:ss', new Date()), 'dd-MM-yy')}
        </div>
      ),
    },
    {
      title: t('screens:financial.approval_date'),
      dataIndex: 'approvalDate',
      key: 'approvalDate',
      render: (text, record) => (
        <div style={{ width: '95px' }}>
          {record.approvalDate !== null ? format(parse(record.approvalDate, 'dd-MM-yyyy HH:mm:ss', new Date()), 'dd-MM-yy HH:mm') : ''}
        </div>
      ),
    },
    {
      title: t('screens:financial.task'),
      dataIndex: 'item.budgetaryNature.task.name',
      key: 'item.budgetaryNature.task.name',
    },
    {
      title: t('screens:financial.item'),
      dataIndex: 'item.name',
      key: 'item.name',
    },
    {
      title: t('screens:financial.value'),
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record) => formatPrice(record.cost),
    },
    {
      title: t('screens:financial.receipt'),
      key: 'receipt',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Button
            title={t('messages:list')}
            style={{ margin: 'auto' }}
            onClick={() => {
              setReceipts(record.expenseReceipts);
              setShowFileList(true);
            }}
          >
            <FaPaperclip />
          </Button>
        </TableActions>
      ),
    },
    {
      title: (
        <label
          className="checkBoxContainer"
          style={{
            marginBottom: '3px',
            marginRight: '5px',
          }}
        >
          <input
            type="checkbox"
            id="checkbox"
            onChange={e => {
              handleSelectAll(e);
            }}
          />
          <span className="checkmark"/>
        </label>
      ),
      key: 'checkbox',
      render: (text, record) => (
        <TableActions>
          <label className="checkBoxContainer">
            <input
              type="checkbox"
              className="checkBoxTable"
              onChange={e => {
                handleSelect(record, e);
              }}
              checked={record.checked && record.checked}
            />
            <span className="checkmark"/>
          </label>
        </TableActions>
      ),
    },
    {
      title: t('screens:users.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <TableActions>
          <Popconfirm
            icon={false}
            title={t('messages:reprove')}
            okText={t('messages:yes')}
            onConfirm={() => {
              setRecordToRefuse(record);
              setShowRefuseForm(true);
            }}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:reprove')}>
              <FaTimes style={{ color: 'red', fontSize: '18px' }} />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const style = {
    formControl: {
      paddingBottom: '0px'
    },
    error: {
      paddingBottom: '18px'
    }
  }

  return (
    <DefautLayout>
      <Box>
        <PageTitle title={`RDV > ${t('screens:financial.title')}`} />
        <Formik 
          initialValues={{cnpjCard: '00.000.000/0000-00' }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            collaborator: Yup.string().required(),
          })}
          onSubmit={handleSearch}
        >
          {({ setFieldValue, errors, submitForm, resetForm }) => (
            <Spin spinning={loading}>
              <Form>
                <Row>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl error={errors.collaborator} field="collaborator" label={t('screens:financial.collaborator')} required>
                      <Select
                        showSearch
                        name="collaborator"
                        disabled={collaborators.length <= 0}
                        onChange={e => {
                          handleExpenses(e);
                          handleFields();
                          setFieldValue('cnpjCard', '00.000.000/0000-00');
                          setFieldValue('costCenter', '');
                          setFieldValue('task', null);
                          setFieldValue('item', null);
                        }}
                        placeholder={t('messages:select')}
                      >
                        {collaborators.map(item => {
                          return (
                            <Option key={item.id} value={item.id.toString()}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} style={errors.collaborator ? style.error : style.formControl}>
                    <FormControl error={errors.cnpjCard} field="cnpjCard" label={t('screens:entries.data.cnpj')}>
                      <Select showSearch name="cnpjCard" disabled={fields} placeholder={t('messages:select')}>
                        {cnpjCards.map(cnpjCard => {
                          return (
                            <Option key={cnpjCard.id} value={cnpjCard.registrationNumber}>
                              <Tooltip placement="topLeft" title={cnpjCard.businessName}>
                                <span>[ {cnpjCard.registrationNumber} ] {cnpjCard.businessName}</span>
                              </Tooltip>
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} style={errors.collaborator ? style.error : style.formControl}>
                    <FormControl error={errors.costCenter} field="costCenter" label={t('screens:financial.costCenter')}>
                      <Select showSearch name="costCenter" disabled={fields} placeholder={t('messages:select')}>
                        {costCenters.map(item => {
                          return (
                            <Option key={item.id} value={item.id.toString()}>
                              {item.code} - {item.clientName}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} style={errors.collaborator ? style.error : style.formControl}>
                    <FormControl error={errors.init} field="initial" label={t('screens:financial.from')}>
                      <DatePicker format="DD/MM/Y" name="init" placeholder={t('messages:select')} disabled={fields} />
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} style={errors.collaborator ? style.error : style.formControl}>
                    <FormControl error={errors.end} field="end" label={t('screens:financial.at')}>
                      <DatePicker format="DD/MM/Y" name="end" placeholder={t('messages:select')} disabled={fields} />
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="task" label={t('screens:financial.task')}>
                      <Select showSearch name="task" disabled={fields} placeholder={t('messages:select')} onChange={handleItems}>
                        {tasks.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <FormControl field="item" label={t('screens:financial.item')}>
                      <Select showSearch name="item" disabled={showItems} placeholder={t('messages:select')}>
                        {items.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>

                  <Button type="submit" color="primary" style={{ marginLeft: 'auto', marginTop: 25 }}>
                    <FaSearch style={{ width: '18px' }} />
                    {t('screens:dateSearch.button')}
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      setShowTable('none');
                      setFields(true);
                    }}
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10, marginTop: 25 }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
      </Box>
      <Box showForm={showTable}>
        <Table
          rowKey="id"
          rowClassName={record => ((record.expenseType === 'GENERIC' && record.observation !== null) || record.supervisorObservation !== null) ? 'GENERIC' : record.expenseType !== 'GENERIC' ? record.expenseType : 'GENERICWITHOUTOBSERVATION ' + record.expenseStatus}
          expandedRowRender={record => handleExtraCollums(record)}
          loading={loading}
          dataSource={tableData}
          columns={tableColumns}
        />
        <OkForm
          value={totalValue}
          selectedEntries={selectedEntries}
          visible={showForm}
          onClose={() => {
            setShowForm(false);
            checkIfReload();
          }}
        />
        <RefuseForm
          recordToRefuse={recordToRefuse}
          visible={showRefuseForm}
          onClose={id => {
            setShowRefuseForm(false);
            const data = tableData.filter(item => item.id !== id);
            setTableData(data);
          }}
        />
        <FileList
          receipts={receipts}
          visible={showFileList}
          onClose={() => {
            setShowFileList(false);
            setReceipts([]);
          }}
        />
        <TotalValue>Total: {formatPrice(totalValue)}</TotalValue>
        <TableHeader>
          <Button
            style={{ display: selectedEntries < 1 ? 'none' : 'block', marginRight: '10px' }}
            color={'primary'}
            onClick={() => {
              handleDownloadAll();
            }}
          >
            Baixar comprovantes selecionados
          </Button>
          <Button
            color="success"
            onClick={() => {
              handlePay();
            }}
          >
            Concluir Pagamento
          </Button>
        </TableHeader>
      </Box>
    </DefautLayout>
  );
}
