import React, { useState } from 'react';
import { Col, Modal, message } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Row from 'components/Row';
import { Card } from './styles';

export default function ImageThumb(file) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const token = useSelector(state => state.auth.token);

  const handleDownload = async () => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${file.file.name}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.file.originalName);
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      if (error.response && error.response.status === 404) {
          message.error(t('messages:downloadFileError') + " O " + t('messages:fileNotFoundError'));
      }
    });
  };

  return (
    <Card>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {file.file.contentType === 'application/pdf' ? (
            <img src="\assets\images\file-pdf.svg" style={{ marginTop: '28px' }} alt="file-pdf" />
          ) : (
            <button
              style={{ marginTop: '25px' }}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={`${process.env.REACT_APP_BACKEND_UPLOAD_URL}/${file.file.name}`} alt={file.file.name} />
            </button>
          )}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <button
            onClick={() => {
              handleDownload();
            }}
            style={{
              border: 0,
              background: '#d6d6d6',
              width: window.innerWidth < 500 ? '111px' : '117px',
              paddingTop: '2px',
              marginTop: '10px',
              height: '20px',
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
            }}
          >
            <p style={{ color: '#6b6b6b' }}>Baixar</p>
          </button>
        </Col>
      </Row>
      <Modal
        onCancel={() => {
          setShowModal(false);
        }}
        title={'Visualização'}
        visible={showModal}
        footer={null}
      >
        <img
          style={{ maxWidth: '460px', maxHeight: '460px', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
          src={`${process.env.REACT_APP_BACKEND_UPLOAD_URL}/${file.file.name}`}
          alt={file.file.name}
        />
      </Modal>
    </Card>
  );
}
