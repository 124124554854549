import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #0093DD;
  background: linear-gradient(90deg, #0093DD 0%, #0093DC 50%, #004A6E 100%);
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
`;
