import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

import { Container, Text } from './styles';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';

const initialValues = {
  warning: '',
};

export default function Warning({ visible, onClose, user }) {
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(visible);
  const [recordData, setRecordData] = useState(initialValues);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/notices/findById', {
        params: { id: 1 },
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const {
        id,
        name,
        email,
        password,
        document,
        dailyValueFeed,
        valuePaidMileage,
        status,
        deleted,
        bankInformation,
      } = user;
      const noticeViewed = true;
      const data = [
        {
          id,
          name,
          password,
          email,
          document,
          dailyValueFeed,
          valuePaidMileage,
          status,
          deleted,
          bankInformation,
          noticeViewed,
        },
      ];

      await api.put('/users/update', data[0]);
      setVisibleModal(false);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords();
  }, [visibleModal]);

  return (
    <Container
      visible={visibleModal}
      title="Aviso!"
      onCancel={() => {
        setVisibleModal(false);
      }}
      footer={
        <ModalFooter
          okText={'Visualizado'}
          cancelText={'Lembrar mais tarde'}
          onOk={handleOk}
          onCancel={() => {
            setVisibleModal(false);
          }}
          loading={loading}
        />
      }
    >
      <Spin spinning={loading}>
        <Text>{recordData.message}</Text>
      </Spin>
    </Container>
  );
}
