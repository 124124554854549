import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title } from './styles';

export default function Logo({ margin, height, width, w2z, title, forgetPassword }) {
  const logo = "/assets/images/logo.png"
  return (
    <>
      <Container className="logo" margin={margin}>
        {w2z ? (
          <img
            height={height}
            width={width}
            src="https://www.w2z.com.br/wp-content/themes/w2z/assets/img/logo-image.png"
            alt="W2Z Soluções em TI"
          />
        ) : (
            <img height={height} width={width} src={logo} alt="TanksBR" />
          )}
      </Container>
      {title === true ? forgetPassword === true ? <Title>Esqueci minha senha</Title> : <Title>Sistema RDV</Title> : ''}
    </>
  );
}

Logo.propTypes = {
  margin: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  w2z: PropTypes.bool,
  title: PropTypes.bool,
  forgetPassword: PropTypes.bool,
};

Logo.defaultProps = {
  margin: '0',
  height: 232,
  width: 232,
  w2z: false,
  title: false,
  forgetPassword: false,
};
