import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Radio } from 'antd';
import axios from 'axios';
import Row from '~/components/Row';
import errorHandler from 'Utils/errorHandler';

export default function ReviewTypeForm({ record, visible, onClose }) {
  const { t } = useTranslation();
  const token = useSelector(state => state.auth.token);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const reviewTypeUrl = value === 2 ? 'rdvCnpj': 'rdv';
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/${reviewTypeUrl}/${record.id}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${record.description}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setValue(1);
  }, [visible]);

  return (
        <Modal
          title={t('screens:review.titleReviewType')}
          visible={visible}
          loading={loading}
          onCancel={onClose}
          onOk={handleDownload}
          okText={t('messages:generate')}
        >
        <Row>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>{t('screens:review.byCostCenter')}</Radio>
            <Radio value={2}>{t('screens:review.byCnpj')}</Radio>
          </Radio.Group>
        </Row>
    </Modal>
  );
}